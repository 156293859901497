import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Button from "./buttons/Button";
import React, { useState } from "react";

export default function PasswordField({
  focus,
  handleInfoChange,
  keyDown,
  keyPress,
  placeholder,
  value,
  className,
  ...data
}: {
  focus?: any;
  handleInfoChange: any;
  keyDown?: any;
  keyPress?: any;
  placeholder: string;
  value: string;
  className?: string;
  data?: { "data-testid": string };
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <input
        name="password"
        placeholder={placeholder}
        className={`p-2 mt-2 w-full ${className}`}
        minLength={8}
        onChange={handleInfoChange}
        onFocus={focus}
        onKeyDown={keyDown}
        onKeyPress={keyPress}
        required
        type={showPassword ? "text" : "password"}
        value={value}
        data-testid={data && (data as any)["data-testid"]}
      />
      <Button
        additionalStyling="border-transparent bg-transparent align-middle -ml-12 md:absolute md:pt-4 pt-1"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword && (
          <AiFillEyeInvisible className="cursor-pointer w-5 h-5" />
        )}
        {!showPassword && <AiFillEye className="cursor-pointer w-5 h-5" />}
      </Button>
    </div>
  );
}
