import { AiOutlineMessage } from "react-icons/ai";
import Button from "./buttons/Button";
import React from "react";
import { ButtonStyles } from "./buttons/buttonTypes";

// a text link that opens the zendesk window
const ContactSupport = ({
  additionalStyling,
  style = "white",
}: {
  additionalStyling?: string;
  style?: ButtonStyles;
}) => (
  <Button
    additionalStyling={additionalStyling}
    link="javascript: $zopim.livechat.window.show();"
    style={style}
  >
    Contact Support{" "}
    {style !== "text" && <AiOutlineMessage className="-mb-1 ml-1" />}
  </Button>
);

export default ContactSupport;
