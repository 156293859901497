import Button from "../../components/buttons/Button";
import { JoyRideConfig } from "../../components/joyrideSteps";
import PaymentInfo from "./settingsComponents/PaymentInfo";
import PersonalInfo from "./settingsComponents/PersonalInfo";
import React, { useState } from "react";
import ToggleContent from "../../components/ToggleableDropdown";
import { UserInfo } from "./userSettingsTypes";
import VideoSettings from "./settingsComponents/VideoSettings.js";
import { XmsSupabase } from "../../xmsSupabase";
import LocalUserSettings from "../../helpers/LocalUserSettings";

export default function UserSettings() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [userInfo, setUserInfo] = useState<UserInfo>({});

  const replayTutorial = () => {
    JoyRideConfig.reset();
    window.location.href = "/learning-library";
  };

  const resetLocalStorage = () => {
    LocalUserSettings.resetAll();
    XmsSupabase.resetCache();
    setSuccessMessage("Local storage successfully reset.");
  };

  return (
    <>
      <article className="lg:flex lg:flex-col gap-8">
        <PersonalInfo
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          setUserInfo={setUserInfo}
          userInfo={userInfo}
        />
        <PaymentInfo userInfo={userInfo} />
        {/* temp hiding from users until page design overhaul */}
        <VideoSettings />
      </article>
      <ToggleContent
        toggle={
          <div className="underline cursor-pointer hover:text-black/70 mt-8">
            Advanced
          </div>
        }
        content={
          <div className="mt-2 gap-y-2 lg:w-1/2 flex flex-col bottom-12">
            <Button onClick={replayTutorial} style="primary">
              Replay Tutorial
            </Button>
            <Button onClick={resetLocalStorage} style="primary">
              Reset Local Storage
            </Button>
          </div>
        }
      />
    </>
  );
}
