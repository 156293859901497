import React, { useEffect, useState } from "react";
import ModalTab from "./ModalTab";

const useTabs = (tabs: Array<{ tabName: string; content: any }>) => {
  const [currentTab, setCurrentTab] = useState(tabs[0].tabName);

  const containerStyle = "flex flex-row gap-x-2 mobile-bottom-border";

  // refresh display when data is loaded and course?.name changes from undefined
  useEffect(() => {
    setCurrentTab(tabs[0].tabName);
  }, [tabs[0].tabName]);

  // by default, assume a single content tab
  let tabsList = <ModalTab title={tabs[0].tabName} isCurrentTab={true} />;

  // if there are resources, display them
  const showResources =
    tabs[1]?.tabName === "Resources" && tabs[1].content.props.resources.length;
  if (showResources) {
    tabsList = (
      <>
        {tabs.map((tab: any, i: number) => (
          <ModalTab
            key={`tab${i}_${tab.tabName}`}
            title={tab.tabName}
            setCurrentTab={setCurrentTab}
            isCurrentTab={currentTab === tab.tabName}
          />
        ))}
      </>
    );
  }

  const content = tabs.find((tab: any) => tab.tabName === currentTab)?.content;

  return [
    <div
      key="tabsList"
      className={
        showResources
          ? containerStyle
          : "flex justify-center md:justify-start mobile-bottom-border"
      }
    >
      {tabsList}
    </div>,
    content,
  ];
};

export default useTabs;
