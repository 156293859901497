const getProgress = ({
  entity,
  progresses,
}: {
  entity: any; // Lesson | LessonPlan
  progresses: any[]; // UserLearningProgress[]
}) => {
  const matchingProgress =
    progresses &&
    progresses.find((progress: any) => progress.learningTypeId === entity?.id);
  if (
    matchingProgress &&
    matchingProgress.resourceProgress &&
    matchingProgress.resourceProgress.length > 0
  ) {
    const resourceProgress = matchingProgress?.resourceProgress.find(
      (resourceProgress: any) => {
        return entity.primaryResources.find(
          (resource: any) => resource.id === resourceProgress.resourceConsumerId
        );
      }
    );
    matchingProgress.isStarted =
      !resourceProgress?.isComplete &&
      resourceProgress?.playbackOffsetInSeconds > 0;
  }

  return matchingProgress;
};

export default getProgress;
