import React from "react";

const CMSImage = ({
  image,
  className,
  imgClassName,
}: {
  image: any;
  className: string;
  imgClassName?: string;
}) => {
  let resource;

  if (!image) {
    console.log("No image provided to CMSImage");
    return null;
  }

  if (typeof image.value === "string") {
    resource = image.values && image.values[0];
  } else if (image.value?.s3_host && image.value?.s3_path) {
    resource = image.value;
  } else {
    // image may just be a string, often used in computing required data for tests, template pages, etc.
    return (
      <picture className={className}>
        <img
          alt={""}
          src={image.value || image}
          className={`w-full ${imgClassName}`}
        />
      </picture>
    );
  }

  const s3FallbackUrl = `https://${resource?.s3_host}.s3.amazonaws.com/${resource?.s3_path}`;

  return (
    <picture className={className}>
      <source srcSet={image.value} />
      <source srcSet={s3FallbackUrl} />
      <img
        alt={resource?.name || ""}
        src={s3FallbackUrl}
        className={`w-full ${imgClassName}`}
      />
    </picture>
  );
};

export default CMSImage;
