import { definitions } from "../../types/supabase";
import { brandConfig } from "../helpers/brandConfig";

const isVideoJSEnabled = (resource?: any) => {
  return (
    (brandConfig.brandAbbreviation === "nma" ||
      localStorage.getItem("enableVideoJs") == "true") &&
    resource?.type === "vimeo_resource" &&
    resource?.metadata &&
    (resource?.metadata as any)?.migrated
  );
};

export default isVideoJSEnabled;
