import { AiOutlineArrowUp } from "react-icons/ai";
import Button from "./Button";
import React, { useEffect, useState } from "react";

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    const showScroll = () =>
      window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);

    window.addEventListener("scroll", showScroll);

    return () => {
      window.removeEventListener("scroll", showScroll);
    };
  }, []);

  if (!showButton) return null;

  return (
    <Button
      additionalStyling="fixed bottom-14 right-3 m-2 px-2.5 py-1.5 z-50
     rounded-3xl text-white bg-brand-tertiary hover:bg-brand-quaternary"
      onClick={() => window.scrollTo({ top: 0 })}
    >
      <AiOutlineArrowUp />
    </Button>
  );
};

export default BackToTopButton;
