import { definitions } from "../types/supabase";
import { XmsUser } from "./apiTypes";
import { brandConfig } from "./helpers/brandConfig";
import byOrder from "./helpers/byOrder";
import { getEnvVarOrRaise } from "./helpers/envVars";
import formatTimestamp from "./helpers/formatTimestamp";
import {
  ImageResource,
  Lesson,
  LessonPlan,
  Product,
  ResourceConsumerWithResource,
  ThumbnailResource,
} from "./pages/learningLibrary/learningLibraryTypes";
import { XmsSupabase } from "./xmsSupabase";

const supabaseUrl = getEnvVarOrRaise("SUPABASE_URL");
const supabaseKey = getEnvVarOrRaise("SUPABASE_KEY");
export const apiUrl = getEnvVarOrRaise("API_URL");
const environment = getEnvVarOrRaise("ENV");

const xmsSupabase = new XmsSupabase(supabaseUrl, supabaseKey);
const supabase = xmsSupabase.supabase;
export { supabase };

const isThumbnail = (resourceConsumer: ResourceConsumerWithResource) =>
  resourceConsumer.display_type === "thumbnail" &&
  ["image_resource", "video_resource", "vimeo_resource"].includes(
    resourceConsumer.resources.type
  );
const isNotThumbnail = (resourceConsumer: ResourceConsumerWithResource) =>
  !isThumbnail(resourceConsumer);

// typeguard
const asThumbnails = (
  resource: definitions["resources"]
): resource is ThumbnailResource => {
  return (
    resource.type === "image_resource" || resource.type === "vimeo_resource"
  );
};

const isImage = (resourceConsumer: ResourceConsumerWithResource) =>
  resourceConsumer.resources.type === "image_resource";
const isVideo = (resourceConsumer: ResourceConsumerWithResource) =>
  resourceConsumer.resources.type === "video_resource";
// typeguard
const asImage = (
  resource: definitions["resources"]
): resource is ImageResource => {
  return resource.type === "image_resource";
};

const isPrimaryResource = (
  resourceConsumer: definitions["resource_consumers"]
) => resourceConsumer.display_type === "primary";
const isSecondaryResource = (
  resourceConsumer: definitions["resource_consumers"]
) => resourceConsumer.display_type === "secondary";

const belongsToLessonPlan =
  (lessonPlan: definitions["lesson_plans"]) =>
  (resourceConsumer: definitions["resource_consumers"]) =>
    resourceConsumer.consumer_type === "lesson_plan" &&
    resourceConsumer.consumer_id == lessonPlan.id;
const belongsToLesson =
  (lesson: definitions["lessons"]) =>
  (resourceConsumer: definitions["resource_consumers"]) =>
    resourceConsumer.consumer_type === "lesson" &&
    resourceConsumer.consumer_id === lesson.id;
const belongsToProduct =
  (product: definitions["products"]) =>
  (resourceConsumer: definitions["resource_consumers"]) =>
    resourceConsumer.consumer_type === "product" &&
    resourceConsumer.consumer_id === product.id;

export const getUrlForResource = (resource: definitions["resources"]) => {
  const urlBase = {
    local: "https://stg.hobbyresources.com",
    stg: "https://stg.hobbyresources.com",
    prd: "https://www.hobbyresources.com",
  }[environment || "local"];

  if (resource.type == "image_resource") {
    const path = resource.s3_path?.split("/").slice(0, -1).join("/");
    return `${urlBase}/${path}/output/webp/image.webp`;
  }

  if (resource?.url) {
    return resource.url;
  } else {
    return `${urlBase}/${encodeURIComponent(
      decodeURIComponent(resource?.s3_path || "")
    )}`;
  }
};

export const resourceIsDownloadable = (resource: definitions["resources"]) => {
  return resource && ["image_resource", "pdf_resource"].includes(resource.type);
};

const normalizeResourceConsumerToResource = (
  resourceConsumer: any
): ResourceConsumerWithResource => {
  const resource = resourceConsumer.resources; // resourceConsumer will only ever have 1 resource associated with it, but supabase returns it on the plural key because it doesn't know it has a single resource

  return {
    ...resource,
    ...resourceConsumer,
    downloadable: resourceIsDownloadable(resource),
    url: getUrlForResource(resource),
  };
};

const filterResources = (
  originalObject: any,
  resourceConsumers: Array<ResourceConsumerWithResource>,
  belongsToObject: (object: any) => boolean
) => {
  const thumbnails =
    resourceConsumers
      .filter(belongsToObject)
      .filter(isThumbnail)
      .map(normalizeResourceConsumerToResource)
      .filter(asThumbnails) || [];
  const thumbnailImages =
    resourceConsumers
      .filter(belongsToObject)
      .filter(isThumbnail)
      .filter(isImage)
      .map(normalizeResourceConsumerToResource)
      .filter(asImage) || [];

  const resources = {
    thumbnails,
    thumbnail: thumbnails[0],
    thumbnailImages,
    thumbnailImage: thumbnailImages[0],
    primaryResources: resourceConsumers
      .filter(belongsToObject)
      .filter(isPrimaryResource)
      .map(normalizeResourceConsumerToResource)
      .sort(byOrder),
    secondaryResources: resourceConsumers
      .filter(belongsToObject)
      .filter(isSecondaryResource)
      .map(normalizeResourceConsumerToResource)
      .sort(byOrder),
  };

  return {
    ...originalObject,
    ...resources,
  };
};

const normalizeLesson = (
  lesson: any,
  lessonPlan: definitions["lesson_plans"] | undefined,
  resourceConsumers: Array<ResourceConsumerWithResource>
): Lesson => {
  let normalizedLesson = { ...lesson };

  const belongsToThisLesson = belongsToLesson(lesson);
  normalizedLesson = filterResources(
    normalizedLesson,
    resourceConsumers,
    belongsToThisLesson
  );

  if (lessonPlan) {
    normalizedLesson.lessonPlanName = lessonPlan.name;
  }

  return normalizedLesson;
};

const normalizeLessonPlan = (
  lessonPlan: any,
  resourceConsumers: Array<ResourceConsumerWithResource>
): LessonPlan => {
  let normalizedLessonPlan: LessonPlan = { ...lessonPlan };

  const belongsToThisLessonPlan = belongsToLessonPlan(lessonPlan);
  normalizedLessonPlan = filterResources(
    normalizedLessonPlan,
    resourceConsumers,
    belongsToThisLessonPlan
  );

  return normalizedLessonPlan;
};

let currentUser: XmsUser | null;

export const api = {
  logout: () => {
    currentUser = null;
    return supabase.auth.signOut();
  },

  login: (email: string, password: string) => {
    return supabase.auth.signInWithPassword({ email, password });
  },

  loginViaPiaf: async (email: string, password: string) => {
    const response = await fetch(`${apiUrl}/user/loginViaPiaf/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    return response;
  },

  signUp: (email: string, password: string) => {
    return supabase.auth.signUp({ email, password });
  },

  getUser: async () => {
    if (!currentUser) {
      const { data, error } = await supabase.auth.getUser();

      if (data.user) {
        currentUser = { ...data.user };

        if (currentUser?.id) {
          const userRole = api.getData(
            await supabase
              .from("user_roles")
              .select("*")
              .eq("user_id", currentUser.id)
          );
          if (userRole.length > 0) {
            currentUser.xmsRole = userRole[0].role;
          }
        }
      }
    }

    return currentUser;
  },

  getData: (response: any) => {
    if (response.error) {
      throw response.error;
    }
    return response.data;
  },

  ensureOne: (response: any) => {
    const data = api.getData(response);
    if (data.length === 0) {
      throw new Error("No data found");
    }

    return data[0];
  },

  getUserProfile: async () => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) {
      return;
    }

    try {
      // don't use ensureOne() since it gives an error if there isn't any profiles
      let profile = await xmsSupabase
        .from("profiles")
        .skipCache()
        .select("*")
        .eq("user_id", data.user.id)
        .getData();

      if (!profile[0]) {
        // user's profile should have been created in the xms-api's findOrCreateSupabaseUser
        // due to security policies it cannot be created here and must be created manually
        // this is a warning and not an error because an error would interrupt video loading
        console.warn("User does not have a profile");
        return null;
      }

      profile = profile[0];
      profile.creditsCount = 0;

      return profile;
    } catch (error: any) {
      throw new Error(error);
    }
  },

  getBrandConfigs: async () => {
    const configs = await supabase
      .from("brand_configurations")
      .select(`*, brands:brands(*)`)
      .not("modal_success_redirect_url", "eq", null);
    return configs?.data;
  },

  userHasAccessToLesson: (lesson: Lesson) => {
    return (
      (lesson.primaryResources && lesson.primaryResources.length > 0) ||
      (lesson.secondaryResources && lesson.secondaryResources.length > 0)
    );
  },

  getBrandResources: async (brandId: string) => {
    return await api.getResources("brand", brandId);
  },

  getLessonProgress: async (lessonId: string) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) {
      return;
    }

    try {
      return await xmsSupabase
        .from("user_lesson_progress")
        .skipCache()
        .select("*")
        .eq("user_id", data.user.id)
        .eq("lesson_id", lessonId)
        .ensureOne();
    } catch (e) {
      return { lesson_resources_metadata: {} };
    }
  },

  getDisplayOffers: async () => {
    const response = await fetch(`${apiUrl}/offers/getDisplayOffers/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return await response.json();
    }

    return [];
  },

  getOffer: async (offerId: string) => {
    return await xmsSupabase
      .from("offers")
      .skipCache()
      .select("*")
      .eq("id", offerId)
      .ensureOne();
  },

  getCreditCount: async () => {
    const user = await api.getUser();
    if (!user) return;

    const { brandId } = brandConfig;
    if (!brandId) return;

    const response = await fetch(`${apiUrl}/user/getCreditCount/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        brandId,
        userId: user.id,
      }),
    });

    return response;
  },

  redeemCredit: async (
    clientSideId: string,
    cookies: any,
    offerId: string,
    transactionURL: any,
    userAgent: any
  ) => {
    const user = await api.getUser();
    if (!user) return;

    const { brandId } = brandConfig;
    if (!brandId) return;

    const response = await fetch(`${apiUrl}/user/redeem/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        brandId,
        clientSideId,
        cookies,
        offerId,
        transactionURL,
        userAgent,
        userId: user.id,
      }),
    });

    return response;
  },

  getUserLearningProgressByProduct: async (productId: string) => {
    const user = await api.getUser();
    if (!user) return;

    const queryParams = new URLSearchParams({
      userId: user.id,
      productId: productId,
    });

    const response = await fetch(
      `${apiUrl}/user/getLearningProgress/byProductId/?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) return await response.json();

    return {
      productProgress: null,
      lessonPlanProgresses: [],
      lessonProgresses: [],
    };
  },

  recordUserProgressOnCourse: async (courseId: string, metadata?: any) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return;

    await fetch(`${apiUrl}/user/trackLearningProgress/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        isCompleted: metadata?.isCompleted,
        learningType: "product",
        productId: courseId,
        userId: data.user.id,
      }),
    });
  },

  recordUserProgressOnClass: async (classId: string, metadata?: any) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return;

    await fetch(`${apiUrl}/user/trackLearningProgress/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        isCompleted: metadata?.isCompleted,
        learningType: "lesson_plan",
        lessonPlanId: classId,
        userId: data.user.id,
      }),
    });
  },

  recordUserProgressOnLesson: async (lessonId: string, metadata?: any) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return;

    await fetch(`${apiUrl}/user/trackLearningProgress/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...(metadata?.finished && { isResourceCompleted: metadata?.finished }),
        ...(metadata?.viewed && { isViewed: metadata?.viewed }),
        isCompleted: metadata?.isCompleted,
        learningType: "lesson",
        lessonId,
        playbackOffsetInSeconds: metadata?.playbackOffsetInSeconds,
        resourceConsumerId: metadata?.resourceConsumerId,
        userId: data.user.id,
      }),
    });
  },

  findLatestInProgressLesson: async () => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return;

    const { brandId } = brandConfig;
    if (!brandId) return;

    const queryParams = new URLSearchParams({
      brandId,
      userId: data.user.id,
    });

    const response = await fetch(
      `${apiUrl}/user/getLearningProgress/byBrandId/?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const { latestLessonProgress, lesson } = await response.json();
      const purchasedProducts = await api.getPurchasedProducts();
      const userHasAccess = purchasedProducts.some(
        (product: any) => product.product_id === lesson?.lessonPlan?.product?.id
      );

      if (userHasAccess) {
        const lessonResources = await api.getResources("lesson", lesson.id);
        const lessonCoverImage = lessonResources
          .filter(isThumbnail)
          .find(isImage);
        const lessonPlanResources = await api.getResources(
          "lesson_plan",
          lesson.lessonPlan.id
        );
        const lessonPlanCoverImage = lessonPlanResources
          .filter(isThumbnail)
          .find(isImage);
        const productResources = await api.getResources(
          "product",
          lesson.lessonPlan.product.id
        );
        const productCoverImage = productResources
          .filter(isThumbnail)
          .find(isImage);
        const thumbnails = [
          lessonCoverImage,
          lessonPlanCoverImage,
          productCoverImage,
        ];
        return { ...lesson, thumbnails };
      } else {
        return undefined;
      }
    }

    return undefined;
  },

  getResources: async (
    consumer_type: "lesson" | "product" | "lesson_plan" | "brand",
    consumer_id: string | Array<string>
  ): Promise<Array<ResourceConsumerWithResource>> => {
    if (typeof consumer_id === "string") {
      const response = await xmsSupabase
        .from("resource_consumers")
        .select("*, resources:resources (*)")
        .eq("consumer_type", consumer_type)
        .eq("consumer_id", consumer_id)
        .is("archived_at", "null")
        .getData();

      return response.map(normalizeResourceConsumerToResource);
    } else {
      const response = await xmsSupabase
        .from("resource_consumers")
        .select("*, resources:resources (*)")
        .eq("consumer_type", consumer_type)
        .in("consumer_id", consumer_id)
        .is("archived_at", "null")
        .getData();

      return response.map(normalizeResourceConsumerToResource);
    }
  },

  // This is to get the resources that are consumed by a resource, like a video that consumes points_of_interest, etc.
  // I don't love this pattern long term, but I'm not sure what a better solution is without re-writing a lot of the LX.
  getResourcesConsumedByResource: async (
    resourceId: string
  ): Promise<Array<ResourceConsumerWithResource>> => {
    const response = await xmsSupabase
      .from("resource_consumers")
      .select("*, resources:resources (*)")
      .eq("consumer_id", resourceId)
      .is("archived_at", "null")
      .getData();

    return response;
  },

  findLesson: async (id: string): Promise<Lesson> => {
    const lesson = await xmsSupabase
      .from("lessons")
      .select(
        `
          *,
          lesson_plans:lesson_plans!Lessons_lesson_plans_id_fkey (
            name
          )
        `
      )
      .eq("id", id)
      .ensureOne();

    const resources = await api.getResources("lesson", id);
    return normalizeLesson(lesson, lesson.lesson_plans, resources);
  },

  getLessonPlanCount: async (productId: string): Promise<number> => {
    const lessonPlans = await xmsSupabase
      .from("lesson_plans")
      .skipCache()
      .select("id")
      .eq("product_id", productId)
      .is("archived_at", "null")
      .getData();
    return lessonPlans.length;
  },

  // LessonPlan.where({ productId: productId }).includes('lessons');
  getLessonPlanAndResources: async (
    productId: string,
    from = 0,
    to = 100
  ): Promise<Array<LessonPlan>> => {
    const lessonPlans = await xmsSupabase
      .from("lesson_plans")
      .skipCache()
      .eq("product_id", productId)
      .is("archived_at", "null")
      .range(from, to)
      .order("order")
      .getData();

    for (const lessonPlan of lessonPlans) {
      const lessons = await xmsSupabase
        .from("lessons")
        .select("*")
        .eq("lesson_plan_id", lessonPlan.id)
        .is("archived_at", "null")
        .getData();
      lessonPlan.lessons = lessons;
    }

    const lessonPlanResources = await api.getResources(
      "lesson_plan",
      lessonPlans.map((lessonPlan: any) => lessonPlan.id)
    );
    const lessonIds = lessonPlans
      .map((lessonPlan: any) =>
        lessonPlan.lessons.map((lesson: any) => lesson.id)
      )
      .flat();
    const lessonResources = await api.getResources("lesson", lessonIds);

    const normalizedLessonPlans: any = [];
    for (let lessonPlan of lessonPlans) {
      const filteredLessonPlanResources = lessonPlanResources.filter(
        (resource: any) => resource.consumer_id === lessonPlan.id
      );
      lessonPlan = normalizeLessonPlan(lessonPlan, filteredLessonPlanResources);

      const filteredLessonResources = lessonResources.filter((resource: any) =>
        lessonPlan.lessons.map((l: any) => l.id).includes(resource.consumer_id)
      );

      lessonPlan.lessons = lessonPlan.lessons
        .map((lesson: any) =>
          normalizeLesson(lesson, lessonPlan, filteredLessonResources)
        )
        .sort(byOrder);

      normalizedLessonPlans.push(lessonPlan);
    }

    return normalizedLessonPlans;
  },

  findLessonPlan: async (lessonPlanId: string): Promise<LessonPlan> => {
    const lessonPlan = await xmsSupabase
      .from("lesson_plans")
      .select("*")
      .is("archived_at", "null")
      .eq("id", lessonPlanId)
      .ensureOne();

    const lessons = await xmsSupabase
      .from("lessons")
      .select("*")
      .eq("lesson_plan_id", lessonPlanId)
      .is("archived_at", "null")
      .getData();

    lessonPlan.lessons = lessons;

    const lessonPlanResources = await api.getResources(
      "lesson_plan",
      lessonPlanId
    );
    lessonPlan.thumbnail = lessonPlanResources.find(isThumbnail);
    lessonPlan.thumbnailImage = lessonPlanResources
      .filter(isImage)
      .find(isThumbnail);
    const lessonResources = await api.getResources(
      "lesson",
      lessonPlan.lessons.map((l: any) => l.id)
    );

    lessonPlan.lessons = lessonPlan.lessons
      .map((lesson: any) =>
        normalizeLesson(lesson, lessonPlan, lessonResources)
      )
      .filter((lesson: any) => lesson.archived_at === null)
      .sort(byOrder);

    return normalizeLessonPlan(lessonPlan, lessonPlanResources);
  },

  findProduct: async (productId: string): Promise<Product> => {
    const product = await xmsSupabase
      .from("products")
      .select("*")
      .eq("id", productId)
      .ensureOne();

    const productResources = await api.getResources("product", product.id);

    product.thumbnail = productResources.find(isThumbnail);
    product.thumbnailImage = productResources.filter(isImage).find(isThumbnail);

    return product;
  },

  getPurchasedProducts: async () => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return null;

    const purchases = api.getData(
      await supabase
        .from("user_products")
        .select(
          `
        *,
        products ( * )
      `
        )
        .eq("user_id", data.user.id)
    );

    return purchases.filter((purchase: any) => {
      return purchase.products.product_type == "digital";
    });
  },

  getPurchasedOffers: async () => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return null;

    const purchases = api.getData(
      await supabase
        .from("orders")
        .select(
          `
        *,
        order_line_items (
          *,
          offers (
            *
          )
        )
      `
        )
        .eq("user_id", data.user.id)
    );

    return purchases.map((purchase: any) => {
      return purchase.order_line_items[0]?.offers;
    });
  },

  getProducts: async (): Promise<Array<Product>> => {
    const { brandId } = brandConfig;
    const products = await xmsSupabase
      .from("products")
      .select("*")
      .eq("brand_id", brandId)
      .eq("product_type", "digital")
      .is("archived_at", "null")
      .getData();

    const productResources = await api.getResources(
      "product",
      products.map((p: any) => p.id)
    );

    const orderedProducts = products.sort(
      (
        a: { name: string; order: number },
        b: { name: string; order: number }
      ) => {
        return a.order - b.order || a.name.localeCompare(b.name);
      }
    );

    const normalizedProducts = orderedProducts.map((product: any) => {
      const belongsToThisProduct = belongsToProduct(product);
      product.thumbnail = productResources
        .filter(belongsToThisProduct)
        .find(isThumbnail);
      product.thumbnailImage = productResources
        .filter(belongsToThisProduct)
        .filter(isImage)
        .find(isThumbnail);
      return product;
    });

    return normalizedProducts;
  },

  getProduct: async (productId: string): Promise<Product> => {
    const { brandId } = brandConfig;
    const product = await xmsSupabase
      .from("products")
      .select("*")
      .eq("brand_id", brandId)
      .eq("id", productId)
      .ensureOne();

    const productResources = await api.getResources("product", product.id);

    const belongsToThisProduct = belongsToProduct(product);
    product.thumbnail = productResources
      .filter(belongsToThisProduct)
      .find(isThumbnail);
    product.thumbnailImage = productResources
      .filter(belongsToThisProduct)
      .filter(isImage)
      .find(isThumbnail);
    product.thumbnailVideo = productResources
      .filter(belongsToThisProduct)
      .filter(isVideo)
      .find(isThumbnail);
    return product;
  },

  getLessonPlansForProduct: async (
    productId: string | number
  ): Promise<Array<LessonPlan>> => {
    const lessonPlans = await xmsSupabase
      .from("lesson_plans")
      .eq("product_id", productId)
      .is("archived_at", "null")
      .order("order")
      .getData();

    for (const lessonPlan of lessonPlans) {
      const lessons = await xmsSupabase
        .from("lessons")
        .select("*")
        .eq("lesson_plan_id", lessonPlan.id)
        .is("archived_at", "null")
        .getData();
      lessonPlan.lessons = lessons;
    }

    const lessonPlanResources = await api.getResources(
      "lesson_plan",
      lessonPlans.map((lp: any) => lp.id)
    );
    const normalizedLessonPlans = lessonPlans.map((lessonPlan: any) => {
      return normalizeLessonPlan(lessonPlan, lessonPlanResources);
    });

    return normalizedLessonPlans;
  },

  getNextLessonPlan: async (
    lessonPlan: LessonPlan
  ): Promise<LessonPlan | undefined> => {
    const lessonPlans = await xmsSupabase
      .from("lesson_plans")
      .select("id, name, order")
      .eq("product_id", lessonPlan.product_id)
      .is("archived_at", "null")
      .order("order")
      .getData();

    const lessonPlanResources = await api.getResources(
      "lesson_plan",
      lessonPlans.map((lp: any) => lp.id)
    );
    const normalizedLessonPlans = lessonPlans.map((lessonPlan: any) => {
      return normalizeLessonPlan(lessonPlan, lessonPlanResources);
    });

    const prevLessonPlanIndex = normalizedLessonPlans.findIndex(
      (p: any) => p.order === lessonPlan?.order
    );
    const nextLessonPlan = normalizedLessonPlans[prevLessonPlanIndex + 1];

    if (!nextLessonPlan) return undefined;

    const lessons = await xmsSupabase
      .from("lessons")
      .select("*")
      .eq("lesson_plan_id", nextLessonPlan.id)
      .is("archived_at", "null")
      .order("order")
      .getData();

    nextLessonPlan.lessons = lessons;

    return nextLessonPlan;
  },

  getProductResourcesForProduct: async (productId: string) => {
    const consumerResources = await api.getResources("product", productId);
    return consumerResources
      .filter(isNotThumbnail)
      .map(normalizeResourceConsumerToResource)
      .sort(byOrder);
  },

  getNotes: async () => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return;

    const { brandId } = brandConfig;
    if (!brandId) return;

    const bookmarks = await supabase
      .from("user_bookmarks")
      .select("*, products!inner(brand_id)")
      .eq("user_id", data.user.id)
      .eq("products.brand_id", brandId)
      .order("created_at", { ascending: false });
    return bookmarks.data;
  },

  getNotesForResource: async (resourceId: string) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return;

    const { data: bookmarks } = await supabase
      .from("user_bookmarks")
      .select("*")
      .eq("user_id", data.user.id)
      .eq("resource_id", resourceId);

    return bookmarks?.sort((a: any, b: any) =>
      (a?.timestamp || 0) > (b?.timestamp || 0) ? 1 : -1
    );
  },

  createNote: async (
    resourceId: string,
    timestamp: number,
    courseId?: string,
    lessonPlanId?: string,
    lessonId?: string,
    customName?: string
  ) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return;

    let name = customName;
    if (!name) {
      const course = await xmsSupabase
        .from("products")
        .select("name")
        .eq("id", courseId)
        .ensureOne();
      const lessonPlan = await xmsSupabase
        .from("lesson_plans")
        .select("name")
        .eq("id", lessonPlanId)
        .ensureOne();
      const lesson = await xmsSupabase
        .from("lessons")
        .select("name")
        .eq("id", lessonId)
        .ensureOne();
      name = `${course?.name} > ${lessonPlan?.name} > ${lesson?.name}`;
    }

    try {
      const list = await xmsSupabase
        .from("user_bookmarks")
        .skipCache()
        .select("*")
        .eq("user_id", data.user.id)
        .eq("resource_id", resourceId)
        .getData();
      // temp -- adding .eq("timestamp", timestamp) to the above query didn't work?
      const bookmark = list?.find(
        (l: any) => Math.floor(l.timestamp) === Math.floor(timestamp)
      );

      if (timestamp > 1 && bookmark) {
        // allow many bookmarks if video hasn't started or if resource is not a video
        return "Note already exists at this timestamp";
      } else {
        await supabase.from("user_bookmarks").insert({
          user_id: data.user.id,
          resource_id: resourceId,
          name,
          timestamp,
          product_id: courseId,
          lesson_plan_id: lessonPlanId,
          lesson_id: lessonId,
        });

        return timestamp > 1
          ? `Note saved at ${formatTimestamp(timestamp)}`
          : "Note saved";
      }
    } catch (e) {
      console.error(e);
    }
  },

  updateNote: async (id: string, name: string, notes: string) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) return;

    try {
      const bookmark = await xmsSupabase
        .from("user_bookmarks")
        .skipCache()
        .select("id")
        .eq("id", id)
        .ensureOne();

      if (bookmark) {
        await supabase
          .from("user_bookmarks")
          .update({ name, notes })
          .match({ id: bookmark.id });
        return { message: "Note updated" };
      }
    } catch (error: any) {
      throw new Error(error);
    }
  },

  deleteNote: async (id: string) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) {
      return { error: "No user found" };
    }

    try {
      const bookmark = await xmsSupabase
        .from("user_bookmarks")
        .skipCache()
        .select("id")
        .eq("id", id)
        .ensureOne();

      if (bookmark) {
        await supabase
          .from("user_bookmarks")
          .delete()
          .match({ id: bookmark.id });
        return { message: "Note deleted" };
      }
    } catch (error: any) {
      throw new Error(error);
    }
  },

  sendForgotPasswordEmail: async (email: string) => {
    const response = await fetch(`${apiUrl}/email/forgotPassword/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.toLowerCase().trim(),
        brandId: brandConfig.brandId,
      }),
    });
    return response;
  },

  sendUpdateProfileEmail: async (email: string) => {
    const response = await fetch(`${apiUrl}/email/updateProfile/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, brandId: brandConfig.brandId }),
    });
    return response;
  },

  updateProfileName: async (name: string) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) {
      return { error: "No user found" };
    }

    try {
      // don't use ensureOne() since it gives an error if there isn't any profiles
      let profile = await xmsSupabase
        .from("profiles")
        .skipCache()
        .select("id")
        .eq("user_id", data.user.id)
        .getData();

      if (!profile[0]) {
        // user's profile should have been created in the xms-api's findOrCreateSupabaseUser
        // due to security policies it cannot be created here and must be created manually
        console.warn("User does not have a profile");
        return { error: "User does not have a profile" };
      }

      profile = profile[0];
      const updatedProfile = await supabase
        .from("profiles")
        .update({ name })
        .match({ id: profile.id });
      if (updatedProfile?.error) return { error: updatedProfile.error.message };
      return { message: "Profile updated" };
    } catch (error: any) {
      throw new Error(error);
    }
  },

  updateProfileVideoPreferences: async (video_preferences: JSON) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) {
      return { error: "No user found" };
    }

    try {
      const profile = await xmsSupabase
        .from("profiles")
        .skipCache()
        .select("id")
        .eq("user_id", data.user.id)
        .ensureOne();

      if (profile) {
        await supabase
          .from("profiles")
          .update({ video_preferences })
          .match({ id: profile.id });
        return { message: "Profile updated" };
      }
    } catch (error: any) {
      throw new Error(error);
    }
  },

  updatePassword: async (token: string, password: string) => {
    const response = await fetch(`${apiUrl}/user/updatePassword/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, password }),
    });
    return response;
  },

  getDefaultOfferId: async () => {
    const { brandId } = brandConfig;

    const brandConfigData = await xmsSupabase
      .from("brand_configurations")
      .skipCache()
      .select("*")
      .eq("brand_id", brandId)
      .ensureOne();

    return brandConfigData.intro_course_offer_id;
  },

  isUserRegistered: async (email: string) => {
    const response = await fetch(`${apiUrl}/user/emailAlreadyRegistered/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    return {
      emailAlreadyRegistered: data.emailAlreadyRegistered,
      existingUserId: data.existingUserId,
    };
  },

  registerForIntroductoryOffers: async (
    clientSideId: string,
    cookies: any,
    email: string,
    password: string,
    transactionURL: any,
    userAgent: any,
    name?: string,
    offerIds?: string,
    existingUserId?: string
  ) => {
    const { brandId } = brandConfig;
    const offerIdArray = offerIds?.split(",");

    const response = await fetch(
      `${apiUrl}/user/registerForIntroductoryOffers/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          brandId,
          email,
          ...(name && { name }),
          offerIds: offerIdArray,
          password,
          cookies,
          userAgent,
          transactionURL,
          clientSideId,
          existingUserId,
        }),
      }
    );

    return response;
  },

  setAnonymousUserEmail: async (userId: string, email: string) => {
    const response = await fetch(`${apiUrl}/user/setAnonymousUserEmail/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, email }),
    });
    return response.json();
  },

  // User Settings Payment Info API methods
  attachStripePaymentMethod: async (paymentMethodId: string) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) {
      return { response: { error: "No user found" } };
    }
    const response = await fetch(`${apiUrl}/user/stripe/attachPaymentMethod/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        paymentMethodId,
        userId: data.user.id,
        brandAbbreviation: brandConfig.brandAbbreviation,
      }),
    });
    return response;
  },

  getStripePaymentMethods: async () => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) {
      return { response: { error: "No user found" } };
    }
    const response = await fetch(`${apiUrl}/user/stripe/getPaymentMethods/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: data.user.id,
        brandAbbreviation: brandConfig.brandAbbreviation,
      }),
    });
    return response;
  },

  removeStripePaymentMethod: async (paymentMethodId: string) => {
    const response = await fetch(`${apiUrl}/user/stripe/removePaymentMethod/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        paymentMethodId,
        brandAbbreviation: brandConfig.brandAbbreviation,
      }),
    });
    return response;
  },

  updateDefaultStripePaymentMethod: async (paymentMethodId: string) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) {
      return { response: { error: "No user found" } };
    }
    const response = await fetch(
      `${apiUrl}/user/stripe/updateDefaultPaymentMethod/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodId,
          userId: data.user.id,
          brandAbbreviation: brandConfig.brandAbbreviation,
        }),
      }
    );
    return response;
  },

  search: async (query: string) => {
    const { data, error } = await supabase.auth.getUser();
    if (!data.user) {
      return { success: false, error: "No user found" };
    }

    const response = await fetch(`${apiUrl}/user/accessible-content/search/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: data.user.id,
        brandAbbreviation: brandConfig.brandAbbreviation,
        searchQuery: query,
      }),
    });
    return { success: true, data: (await response.json()).data };
  },
};
