import {
  AiOutlineFileImage,
  AiOutlineFilePdf,
  AiOutlineLink,
  AiOutlineVideoCamera,
  AiOutlineYoutube,
} from "react-icons/ai";
import { definitions } from "../../types/supabase";
import { FaVimeo } from "react-icons/fa";
import React from "react";

const ResourceTypeIcon = ({
  type,
  className = "-mb-0.5",
}: {
  type: definitions["resources"]["type"];
  className?: string;
}) => {
  switch (type) {
    case "vimeo_resource": {
      return <FaVimeo className={className} />;
    }
    case "youtube_resource": {
      return <AiOutlineYoutube className={className} />;
    }
    case "video_resource": {
      return <AiOutlineVideoCamera className={className} />;
    }
    case "pdf_resource": {
      return <AiOutlineFilePdf className={className} />;
    }
    case "image_resource": {
      return <AiOutlineFileImage className={className} />;
    }
    default: {
      return <AiOutlineLink className={className} />;
    }
  }
};

export default ResourceTypeIcon;
