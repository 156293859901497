import { VideoJsPlayer } from "video.js";
import { brandConfig } from "../../helpers/brandConfig";

export const addPlayerWatermark = (
  player: VideoJsPlayer & { watermark: (opts: any) => void }
) => {
  // Temp / Blue tape: we need alt bkg logos (transparent bkg, white bkg, etc) per brand and not just navbarLogo and favicon
  //   once that's done we can remove the white bkg in the index.css .vjs-watermark-content
  player.watermark({
    image: brandConfig.favicon(),
    position: "top-right", // location to place the watermark (top-left, top-right, bottom-left, bottom-right)
    width: "16px",
    height: "16px",
  });
};
