import CMSImage from "./CMSImage";
import OfferCardType from "./OfferCardType";
import React, { useEffect, useState } from "react";

const OfferCard = ({
  containerClassName = "relative overflow-hidden h-40 text-white",
  offer,
  onClick,
}: OfferCardType) => {
  const [cardImage, setCardImage] = useState();

  useEffect(() => {
    if (offer?.card_image?.url) {
      setCardImage(offer.card_image.url);
    } else if (offer?.card_image?.s3_host && offer?.card_image?.s3_path) {
      const imageValue: any = { value: { ...offer.card_image } };
      setCardImage(imageValue);
    }
    // We can add more fallbacks here if desired
    // e.g. grab the first primary product's image
    // brand image, brand color, whatever
  }, []);

  const defaultStyle = `
    h-full w-full px-6 z-10 pl-0 bg-brand-primary
    flex flex-wrap absolute content-end transition
    opacity-0 hover:bg-opacity-50 hover:opacity-100`;

  const hoverText = () =>
    offer.metadata?.description ||
    offer.metadata?.primary_products[0]?.description ||
    "";

  return (
    <section
      className={`cursor-pointer ${containerClassName} ${
        offer?.card_image ? "" : "bg-brand-primary"
      }`}
      onClick={onClick}
    >
      <h3 className="absolute z-20 p-4">
        <div className="bg-brand-primary bg-opacity-75 -ml-4 p-2 pl-4 rounded rounded-tl-none rounded-bl-none">
          {offer.metadata?.title}
        </div>
      </h3>
      <div className={defaultStyle}>
        {hoverText() && (
          <p
            className={`absolute top-20 h-full p-1 pl-4 mr-6 rounded rounded-tl-none rounded-bl-none bg-gray-800 bg-opacity-75 max-h-32 font-semibold overflow-hidden leading-5`}
            dangerouslySetInnerHTML={{ __html: hoverText() }}
          ></p>
        )}
      </div>

      {offer?.card_image && (
        <CMSImage
          image={cardImage}
          imgClassName="object-cover h-full"
          className="absolute object-cover h-full w-full"
        />
      )}
    </section>
  );
};

export default OfferCard;
