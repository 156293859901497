import React from "react";
import { ResourceConsumerWithResource } from "../../learningLibrary/learningLibraryTypes";
import ResourceTypeIcon from "../../../components/ResourceTypeIcon";

const ResourceTab = ({
  resources,
}: {
  resources: Array<ResourceConsumerWithResource>;
}) => {
  return (
    <div className="h-full overflow-auto md:h-fit md:overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-4 w-full">
        {resources.map((resource) => (
          <div
            key={resource.id}
            className="bg-white p-2 md:p-4 rounded md:shadow-md md:mb-4 cursor-pointer hover:shadow-lg 
              mobile-bottom-border-light"
          >
            <>
              <ResourceTypeIcon type={resource.type} /> {resource.name}
              <br />
              <div>{resource.description}</div>
            </>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourceTab;
