import { api } from "../../../api";
import Cookies from "js-cookie";
import Button from "../../../components/buttons/Button";
import { definitions } from "../../../../types/supabase";
import { Modal } from "../../../components/modal/modal";
import { Product } from "../../learningLibrary/learningLibraryTypes";
import { ProfileContext } from "../../../App";
import React, { useContext, useEffect, useState } from "react";
import Snackbar from "../../../components/snackbar/snackbar";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PreviewHeader from "./PreviewHeader";

const CreditCheckoutModal = ({
  courseId,
  offer,
  open,
  setOpen,
}: {
  courseId: string;
  open: boolean;
  offer: definitions["offers"];
  setOpen: any;
}) => {
  const navigate = useNavigate();
  const [course, setCourse] = useState<Product>();
  const [creditCount, setCreditCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showDescription, setShowDescription] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const creditCost = offer?.price_in_credits || 0;
  const { setProfile } = useContext(ProfileContext);

  useEffect(() => {
    (async () => {
      const product = await api.getProduct(courseId);
      setCourse(product);

      const response: any = await api.getCreditCount();
      if (response.ok) {
        const { count } = await response.json();
        setCreditCount(count || 0);
      } else if (response.error) {
        console.log(response.error);
      }
    })();
  }, []);

  const redeemCredit = async () => {
    const clientSideId = uuidv4();
    const cookies = Cookies.get();
    const transactionURL = window.location.href;
    const userAgent = navigator.userAgent;
    const response: any = await api.redeemCredit(
      clientSideId,
      cookies,
      offer.id,
      transactionURL,
      userAgent
    );

    // reload profile in context so correct count displays in nav dropdown
    const profile = await api.getUserProfile();
    setProfile(profile);

    if (response.ok) {
      setShowSuccess(true);
    } else {
      setErrorMessage(
        "There was an issue redeeming your credit. Please contact support for assistance."
      );
    }
  };

  if (!offer) return <div />;

  if (showSuccess) {
    return (
      <Modal
        setOpen={setOpen}
        frameClassName={`justify-center items-center ${
          open ? "block" : "hidden"
        }`}
        contentClassName="p-6 md:px-0 lg:py-12 h-full sm:h-1/2 md:w-3/4 lg:w-2/3 xl:w-1/2 justify-between items-center"
      >
        <PreviewHeader title="Back to Discover" hideInfo={true} />
        <span
          className="flex flex-col justify-between overflow-y-scroll gray-scrollbar"
          style={{ height: "calc(100vh - 5.5rem)" }}
        >
          <div className="flex flex-col items-center gap-y-6">
            <span className="text-center font-semibold text-3xl md:text-4xl xl:text-5xl">
              Success!
            </span>
            <span className="text-center md:w-2/3">
              Your credit has been successfully redeemed. You can start enjoying
              your course immediately. Happy learning!
            </span>
            <div className="flex flex-col items-center md:flex-row h-24 gap-x-4">
              <img
                className="flex flex-col w-24 h-24"
                alt="product thumbnail"
                src={course?.thumbnail?.url}
              />
              <div className="flex flex-col justify-between">
                <span className="font-semibold text-2xl text-center md:text-left pb-4 md:pb-0">
                  {offer?.name}
                </span>
                <Button
                  additionalStyling="py-2"
                  onClick={() =>
                    navigate(`/learning-library/course/${courseId}`)
                  }
                  style="primary"
                >
                  Start Learning
                </Button>
              </div>
            </div>
          </div>
          <span
            className="hidden md:flex ml-8 underline cursor-pointer"
            onClick={() => navigate(`/discover`)}
          >
            Go Back
          </span>
        </span>
      </Modal>
    );
  }

  return (
    <>
      <Snackbar
        message={errorMessage}
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
      />
      <Modal
        setOpen={setOpen}
        frameClassName={`justify-center items-center ${
          open ? "block" : "hidden"
        }`}
        contentClassName="pl-4 pt-4 md:px-0 lg:py-12 h-full sm:h-1/2 md:w-3/4 lg:w-2/3 xl:w-1/2"
      >
        <span className="flex md:hidden">
          <PreviewHeader
            title="Checkout"
            hideInfo={true}
            navigateBackFn={() => setOpen(false)}
          />
        </span>
        <span
          className="flex flex-col justify-between overflow-y-scroll gray-scrollbar"
          style={{ height: "calc(100vh - 9rem)" }}
        >
          <div className="flex flex-col gap-y-2 md:flex-row items-center md:items-start md:justify-around">
            <div className="flex flex-col w-full md:w-2/5">
              <div className="flex flex-row gap-x-4">
                <img
                  className="flex flex-col w-24 h-24 rounded-lg border-solid"
                  alt="product thumbnail"
                  src={course?.thumbnail?.url}
                />
                <div className="flex flex-col">
                  <h3 className="">{offer?.name}</h3>
                  <p className="mb-0">
                    Price: {creditCost} credit{creditCost === 1 ? "" : "s"}
                  </p>
                  <span
                    className="underline cursor-pointer text-sm"
                    onClick={() => setShowDescription(!showDescription)}
                  >
                    {showDescription ? "Hide Description" : "Show Description"}
                  </span>
                  {showDescription && (
                    <span className="text-sm text-left border rounded border-gray-200 p-1 w-full max-h-12 overflow-auto">
                      {offer.description}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full md:w-2/5 gap-y-0.5">
              <h3 className="">Summary</h3>
              <div className="flex flex-row justify-between">
                <span>Credit Applied</span>
                <span>{creditCost}</span>
              </div>
              <div className="flex flex-row justify-between">
                <span>Total Cost</span>
                <span>$0.00</span>
              </div>
              <hr className="m-0" />
              <div className="flex flex-row justify-between">
                <p className="mb-0">Credits remaining after purchase</p>
                <span>{creditCount - creditCost}</span>
              </div>
            </div>
          </div>

          {/* non-mobile */}
          <div className="flex flex-col md:flex-row justify-around mt-4">
            <span
              className="hidden md:flex underline cursor-pointer my-auto md:w-2/5"
              onClick={() => setOpen(false)}
            >
              Go Back
            </span>
            <Button
              additionalStyling="hidden md:block md:w-2/5 p-4"
              onClick={redeemCredit}
              style="red"
            >
              Purchase with {creditCost} Credit{creditCost === 1 ? "" : "s"}
            </Button>
          </div>

          {/* mobile */}
          <div className="flex flex-col md:hidden">
            <Button
              additionalStyling="p-3 w-full"
              onClick={redeemCredit}
              style="red"
            >
              Purchase with {creditCost} Credit{creditCost === 1 ? "" : "s"}
            </Button>
            <p className="text-sm text-center m-0 pt-1">
              Get immediate access - for life!
            </p>
          </div>
        </span>
      </Modal>
    </>
  );
};

export default CreditCheckoutModal;
