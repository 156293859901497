import React, { useEffect, useState } from "react";
import { api } from "../../../api";

export const defaultVideoPreferences = {
  quality: "720p",
  speed: 1,
  captions: {
    on: true,
    font: "Arial, Helvetica, sans-serif",
    size: "12pt",
    text: "255 255 255",
    background: "0 0 0",
  },
};

export default function VideoSettings() {
  const [open, setOpen] = useState(false);
  const [videoPreferences, setVideoPreferences] = useState({});
  const [init, setInit] = useState(false);

  useEffect(() => {
    (async () => {
      const profile = await api.getUserProfile();
      setVideoPreferences(
        profile?.video_preferences || defaultVideoPreferences
      );
      setInit(true);
    })();
  }, []);

  async function update(key = "", value = "", subSetting = "") {
    let newPrefs = {};
    if (subSetting) {
      newPrefs = {
        ...videoPreferences,
        [subSetting]: { ...videoPreferences[subSetting], [key]: value },
      };
    } else {
      newPrefs = { ...videoPreferences, [key]: value };
    }
    setVideoPreferences(newPrefs);
    await api.updateProfileVideoPreferences(newPrefs);
  }

  if (!init) return <div>loading</div>;

  return (
    <div>
      {/* This will need to be switched over to Button once this file is converted to typescript */}
      <button onClick={() => setOpen(!open)}>Video Settings</button>
      {open && (
        <div className="flex flex-col gap-y-2 p-2 bg-white shadow">
          <h4 className="m-0">General Defaults</h4>
          <div className="flex flex-row gap-x-2">
            <label htmlFor="font">Quality</label>
            <select
              id="quality"
              name="quality"
              onChange={(e) => update(e.target.id, e.target.value)}
              value={videoPreferences?.quality}
            >
              <option value="1080p">1080p</option>
              <option value="720p">720p</option>
              <option value="540p">540p</option>
            </select>
          </div>
          <div className="flex flex-row gap-x-2">
            <label htmlFor="font">Speed</label>
            <select
              id="speed"
              name="speed"
              onChange={(e) => update(e.target.id, e.target.value)}
              value={videoPreferences?.speed}
            >
              <option value={2}>2x</option>
              <option value={1.5}>1.5x</option>
              <option value={1.25}>1.25x</option>
              <option value={1}>1x</option>
              <option value={0.5}>0.5x</option>
            </select>
          </div>
          <h4 className="m-0">Caption Settings</h4>
          <div className="flex flex-row gap-x-2">
            <label htmlFor="on">Show Captions</label>
            <input
              type="checkbox"
              id="on"
              name="on"
              defaultChecked={videoPreferences?.captions?.on}
              onChange={(e) =>
                update(e.target.id, e.target.checked, "captions")
              }
            />
          </div>
          <div className="flex flex-row gap-x-2">
            <label htmlFor="text">Text Color</label>
            <select
              id="text"
              name="text"
              onChange={(e) => update(e.target.id, e.target.value, "captions")}
              value={videoPreferences?.captions?.text}
            >
              <option value="0 0 0">Black</option>
              <option value="255 255 255">White</option>
            </select>
          </div>
          <div className="flex flex-row gap-x-2">
            <label htmlFor="font">Background Color</label>
            <select
              id="background"
              name="background"
              onChange={(e) => update(e.target.id, e.target.value, "captions")}
              value={videoPreferences?.captions?.background}
            >
              <option value="0 0 0">Black</option>
              <option value="255 255 255">White</option>
            </select>
          </div>
          <div className="flex flex-row gap-x-2">
            <label htmlFor="font">Font Family</label>
            <select
              id="font"
              name="font"
              onChange={(e) => update(e.target.id, e.target.value, "captions")}
              value={videoPreferences?.captions?.font}
            >
              <option value="Arial, Helvetica, sans-serif">Arial</option>
              <option value="Comic Sans MS, Comic Sans">Comic Sans</option>
              <option value="Times">Times</option>
            </select>
          </div>
          <div className="flex flex-row gap-x-2">
            <label htmlFor="size">Font Size</label>
            <select
              id="size"
              name="size"
              onChange={(e) => update(e.target.id, e.target.value, "captions")}
              value={videoPreferences?.captions?.size}
            >
              <option value="6pt">50%</option>
              <option value="12pt">100%</option>
              <option value="18pt">150%</option>
              <option value="24pt">200%</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
}
