import { AiFillStar } from "react-icons/ai";
import { AiOutlineCheck } from "react-icons/ai";
import { api } from "../../../api";
import { Card } from "../../../components/card/card";
import { Lesson, LessonPlan } from "../learningLibraryTypes";
import React, { useEffect, useState } from "react";
import Thumbnail from "./Thumbnail";
import { useNavigate } from "react-router-dom";
import useTracking from "../../../hooks/useTracking";

const ModalRow = ({
  courseId,
  isFinished,
  isNext,
  isStarted,
  lesson,
  lessonPlan,
  name,
  onRowClick,
  purchased,
  secondsWatched,
  thumbnail,
}: {
  courseId?: string | undefined;
  isFinished?: boolean;
  isNext?: boolean;
  isStarted?: boolean;
  lesson?: Lesson;
  lessonPlan?: LessonPlan;
  name: string;
  onRowClick?: () => void;
  product?: any;
  purchased?: boolean;
  secondsWatched?: number;
  thumbnail?: any;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [duration, setDuration] = useState<any>(null);
  const [formattedDuration, setFormattedDuration] = useState("");

  const navigate = useNavigate();
  const trackEvent = useTracking();

  // the link that the user will be directed to on click of the row
  const getRowLink = () => {
    const lessonCount = (lessonPlan as any)?.lessons?.length;

    if (!purchased || !lessonPlan?.id) return "";

    if (lessonCount == 1) {
      return `/learning-library/course/${courseId}/lesson-plan/${
        lessonPlan.id
      }/lesson/${(lessonPlan as any).lessons[0].id}`;
    }
    if (lessonCount > 1 && lesson?.id) {
      return `/learning-library/course/${courseId}/lesson-plan/${lessonPlan.id}/lesson/${lesson.id}`;
    }
    return `/learning-library/course/${courseId}/lesson-plan/${lessonPlan.id}`;
  };

  // sum seconds of all resources with durations
  const getDurationOfLesson = (l: Lesson | undefined) => {
    let totalSeconds = 0;
    if (l?.primaryResources) {
      totalSeconds = l?.primaryResources.reduce((sum: any, resource: any) => {
        return (sum += (resource.metadata || ({} as any))?.duration || 0);
      }, 0);
    }
    return Math.round(totalSeconds);
  };

  // format a count of seconds
  const formatDuration = (totalSeconds: number) => {
    if (totalSeconds) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      const result = [];
      if (hours) result.push(`${hours}h`);
      result.push(`${minutes.toString().padStart(2, "0")}m`);
      result.push(`${seconds.toString().padStart(2, "0")}s`);

      return result.join(" ");
    }
    return "";
  };

  useEffect(() => {
    // if row is lesson
    if (lesson?.primaryResources) {
      // display total duration of all resources in lesson
      setDuration(getDurationOfLesson(lesson));
      setFormattedDuration(formatDuration(getDurationOfLesson(lesson)));

      // row is lessonPlan
    } else if (lessonPlan?.id) {
      let totalDuration = 0;

      if (lessonPlan?.lessons) {
        for (const lesson of lessonPlan.lessons) {
          totalDuration = totalDuration + getDurationOfLesson(lesson);
        }

        setDuration(totalDuration);
        setFormattedDuration(formatDuration(totalDuration));
      }
    }
  }, []);

  const RowBody = () => {
    let description = lessonPlan?.description || "";

    if (lesson) {
      description = lesson?.description || "";
    }

    if (description.length > 200) {
      description = description.substring(0, 200 - 1) + "&hellip;";
    }

    return (
      <>
        <div className="hidden md:flex flex-col flex-1 md:ml-4 ml-2">
          <span
            className="font-bold text-xs md:text-lg items-center -mr-12 md:mr-0 md:h-fit flex-wrap"
            data-testid="lesson-plan-name-desktop"
          >
            {name}
          </span>
          {description && (
            <div
              className="text-xs text-gray-700"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>

        <div className="hidden md:flex flex-row md:flex-col items-end w-full md:w-fit">
          <p className="md:mb-0 m-0 text-xs ml-2">{formattedDuration}</p>
          {isNext ? (
            <div className="flex flex-row">
              <p className="text-xs font-bold text-orange-500 hidden md:flex">
                {isStarted ? "Continue" : "Up Next"}
              </p>
              <AiFillStar className="text-orange-500 ml-1" />
            </div>
          ) : (
            <AiOutlineCheck
              className={isFinished ? "text-green-500" : "invisible"}
            />
          )}
        </div>
      </>
    );
  };

  const MobileRowBody = () => {
    return (
      <div className="flex md:hidden flex-row pl-2 h-full w-full justify-between">
        <div
          className="font-bold text-xs h-fit truncate w-2/3"
          data-testid="lesson-plan-name-mobile"
        >
          {name}
        </div>

        <div className="flex whitespace-no-wrap">
          <p className="md:mb-0 m-0 text-xs ml-2">{formattedDuration}</p>
          {isNext ? (
            <div className="flex flex-row">
              <p className="text-lg font-bold text-orange-500 hidden md:flex">
                {isStarted ? "Continue" : "Up Next"}
              </p>
              <AiFillStar className="text-orange-500 ml-1" />
            </div>
          ) : (
            <AiOutlineCheck
              className={isFinished ? "text-green-500" : "invisible"}
            />
          )}
        </div>
      </div>
    );
  };

  // if the lesson has duration, show a progress bar
  const ProgressBar = () => {
    if (!duration || !lesson) return null;

    const percentageComplete = () => {
      const width = screen.width;
      if (!secondsWatched || secondsWatched == 0) return "0";
      const percent = Math.ceil((secondsWatched / duration) * 100);
      return Math.ceil((percent * width) / 100);
    };

    return (
      <div className="bg-gray-700 w-full h-2 md:hidden ">
        <div
          className={`bg-brand-tertiary opacity-100 h-2`}
          style={{ width: `${percentageComplete()}px` }}
        />
      </div>
    );
  };

  const handleClick = async () => {
    if (lesson) {
      await api.recordUserProgressOnLesson(lesson.id);
      trackEvent({
        action: "learning_interaction",
        meta: {
          interaction: "started",
          learningType: "lesson",
          learningTypeId: lesson.id,
          learningTypeName: lesson.name,
        },
      });
      navigate(getRowLink());
    }
    navigate(getRowLink());
  };

  return (
    <div
      onClick={onRowClick ? onRowClick : handleClick}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
      className="relative z-10 flex-col h-48 md:h-fit md:mb-2 overflow-hidden md:border-none border-0 border-b-4 border-solid border-brand-primary"
    >
      <Card useCursor={true} addedStyling="shadow-none md:shadow-md">
        <div className="flex flex-col md:flex-row h-full">
          <Thumbnail
            primaryResource={thumbnail}
            isHovering={isHovering}
            className="flex items-center my-auto h-36 w-full md:h-full md:w-fit overflow-hidden pointer-events-none"
          />
          <ProgressBar />
          <RowBody />
          <MobileRowBody />
        </div>
      </Card>
    </div>
  );
};

export default ModalRow;
