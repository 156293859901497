import React, { useEffect } from "react";
import { SnackbarType } from "./snackbarType";
import { closeSvgPath, checkSvgPath, continueSvgPath } from "../commonStyles";

export default function Snackbar({
  text = "text-white text-base font-semibold",
  border = "border-gray-700",
  background = "bg-gray-700",
  icon,
  multiline = false,
  anchorOrigin = "top center",
  message,
  open,
  onClose,
  autoHideDuration = 10000,
}: SnackbarType) {
  const top = anchorOrigin.includes("top");
  const bottom = anchorOrigin.includes("bottom");
  const left = anchorOrigin.includes("left");
  const right = anchorOrigin.includes("right");
  const center = anchorOrigin.includes("center");

  // style position based on props
  // if bottom right, account for backToTop button and zendesk help button in the way (it's locked to top z-value so we can't overlap it)
  const position =
    anchorOrigin === "center"
      ? "inset-0 m-auto"
      : `
    ${top && "top-0"} ${bottom && "bottom-0"} ${left && "left-0"} ${
      right && "right-0"
    } 
    ${bottom && right && "bottom-28"} 
    ${((center && top) || (center && bottom)) && "inset-x-0 mx-auto"}  
    ${((center && right) || (center && left)) && "inset-y-0 my-auto"}`;

  const containerStyle = `m-2 px-4 py-3 border-solid rounded shadow-lg`;
  const iconStyle = `cursor-pointer ${
    multiline ? "w-24 h-6 -mr-2" : "w-6"
  } ml-4 fill-current hover:text-gray-400`;

  const path = () => {
    switch (icon) {
      case "continue":
        return continueSvgPath;
      case "check":
        return checkSvgPath;
      default:
        return closeSvgPath;
    }
  };

  // when snackbar is opened, if it has an autoHide prop, start the countdown
  useEffect(() => {
    if (open && autoHideDuration) {
      setTimeout(() => {
        onClose();
      }, autoHideDuration);
    }
  }, [open]);

  return (
    <div
      role="alert"
      className={`${
        open ? "flex" : "hidden"
      } fixed flex z-50 ${position} ${text} ${border} ${background} ${containerStyle} h-fit whitespace-normal ${
        multiline
          ? "w-5/6 md:w-1/2 xl:w-1/3"
          : "w-[96vw] mx-2 justify-between md:mx-auto md:w-fit"
      }`}
    >
      <div dangerouslySetInnerHTML={{ __html: message }} />
      <svg
        className={`${iconStyle} ${text}`}
        onClick={() => onClose()}
        role="button"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <title>Close</title>
        <path d={path()} />
      </svg>
    </div>
  );
}
