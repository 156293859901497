import { FaSpinner } from "react-icons/fa";
import React, { MouseEvent, ReactNode, useEffect, useState } from "react";
import { ButtonTypes, ButtonStyles } from "./buttonTypes";

const Button = ({
  additionalStyling,
  ariaLabel,
  children,
  isLoading = false,
  link,
  onClick,
  newTab = false,
  startDisabled = false,
  style = "",
  buttonId,
  type = "button",
  ...data
}: {
  additionalStyling?: string;
  ariaLabel?: string;
  children?: ReactNode;
  isLoading?: boolean;
  buttonId?: string;
  link?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  newTab?: boolean;
  startDisabled?: boolean;
  style?: ButtonStyles;
  type?: ButtonTypes;
}) => {
  const [disabled, setDisabled] = useState<boolean>(startDisabled);
  const [loading, setLoading] = useState<boolean>(isLoading);

  useEffect(() => {
    setDisabled(startDisabled);
    setLoading(isLoading);
  }, [startDisabled, isLoading]);

  const styles = {
    primary: `text-white font-bold bg-brand-primary
    hover:bg-brand-tertiary rounded`,
    red: `font-bold border rounded bg-red-500 border-red-800 border-2
    text-white hover:bg-red-600`,
    text: `bg-transparent border-transparent font-semibold
    text-brand-primary hover:text-brand-tertiary`,
    white: `no-underline shadow hover:shadow-lg font-semibold
    text-lg md:text-2xl text-brand-primary hover:text-white p-2 bg-white border-2
    border-solid border-brand-primary rounded-lg`,
  };

  const defaultStyle = style ? styles[style] : "";
  const computedStyling = `${defaultStyle} ${additionalStyling}`;
  const computedAriaLabel =
    ariaLabel || (typeof children === "string" ? children : "button");

  const computedOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    if (link) {
      newTab ? window.open(link, "_blank") : (window.location.href = link);
    } else if (onClick) {
      // TODO: not sure this works as expected
      if (onClick.constructor.name != "AsyncFunction") {
        console.log("AsyncButton is designed to work with async functions.");
      }
      setLoading(true);
      setDisabled(true);
      await onClick(event);
      setLoading(false);
      setDisabled(false);
    }
  };

  return (
    <button
      type={type}
      disabled={disabled}
      aria-label={computedAriaLabel}
      className={`cursor-pointer disabled:bg-gray-300 ${computedStyling}`}
      onClick={computedOnClick}
      data-testid={data && (data as any)["data-testid"]}
    >
      {loading && <FaSpinner className="inline mr-3 animate-spin" />}
      {children}
    </button>
  );
};

export default Button;
