import React from "react";
import {
  AiOutlineFileSearch,
  AiOutlineLogout,
  AiOutlineRead,
  AiOutlineSetting,
  AiOutlineShopping,
} from "react-icons/ai";

export default function getPageIcon(label = "", className = "") {
  switch (label) {
    case "My Notes":
      return <AiOutlineFileSearch className={className} />;
    case "Discover":
      return <AiOutlineShopping className={className} />;
    case "Settings":
      return <AiOutlineSetting className={className} />;
    case "Logout":
      return <AiOutlineLogout className={className} />;
    case "My Library":
    default:
      return <AiOutlineRead className={className} />;
  }
}
