import React, { useEffect, useState, useRef } from "react";
import { definitions } from "../../../types";
import { api, getUrlForResource, resourceIsDownloadable } from "../../api";
import DetectClickOutside from "../../hooks/useDetectClickOutside";
import useTracking from "../../hooks/useTracking";
import ModalRow from "../../pages/learningLibrary/modal/ModalRow";
import ResourceTab from "../../pages/learningLibrary/modal/ResourceTab";
import { useNavigate } from "react-router-dom";

type SearchedLessons = definitions["lessons"] & {
  lesson_plan_id: string;
  lesson_plan_name: string;
  course_name: string;
  course_id: string;
  content_type: "lesson";
};
type SearchedResources = definitions["resources"] & {
  content_type: "resource ";
};

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [matchedContent, setMatchedContent] = useState<
    (SearchedLessons | SearchedResources)[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [searchBarHasFocus, setSearchBarHasFocus] = useState(false);
  const track = useTracking();
  const navigate = useNavigate();
  const searchBarRef = useRef(null);

  const closeModal = () => {
    track({
      action: "page_interaction",
      meta: {
        interaction: "close-search-modal",
        resourceType: "content_search_results",
      },
    });
    setShouldShow(false);
  };

  DetectClickOutside(searchBarRef, closeModal, searchBarHasFocus);

  const search = async (e: any) => {
    e?.preventDefault();
    setIsLoading(true);
    setShouldShow(true);

    (window as any).disableCache();
    const response = await api.search(searchQuery);
    if (response.error) {
      setIsLoading(false);
      return;
    }
    (window as any).enableCache();

    track({
      action: "content_search",
      meta: { numberOfResults: response.data.length, searchQuery: searchQuery },
    });

    setMatchedContent(response.data);
    setIsLoading(false);
  };

  const popupContent = () => {
    if (isLoading) return <div>Loading...</div>;
    if (matchedContent.length === 0)
      return <div>{`No results found for "${searchQuery}"`}</div>;

    const lessons = [
      ...new Set(matchedContent.filter((c) => c.content_type === "lesson")),
    ].map((content) => {
      if (content.content_type === "lesson") {
        return (
          <ModalRow
            onRowClick={() => {
              track({
                action: "page_interaction",
                meta: {
                  interaction: "visit-lesson-from-search",
                  resourceType: "lesson",
                },
              });
              navigate(
                `/learning-library/course/${content.course_id}/lesson-plan/${content.lesson_plan_id}/lesson/${content.id}`
              );
            }}
            name={[content.course_name, content.lesson_plan_name, content.name]
              .filter((x) => x)
              .join(" - ")}
            key={`lesson ${content?.id}`}
            lesson={content}
            data-te
            purchased={true}
            courseId={content.course_id}
          />
        );
      }
    });

    const resourceContent = [
      ...new Set(
        matchedContent.filter((content) => {
          return (content as any).content_type === "resource";
        })
      ),
    ].map((resource) => {
      return {
        ...resource,
        url: getUrlForResource(resource as any),
        downloadable: resourceIsDownloadable(resource as any),
      };
    });

    return (
      <div>
        <div className="float-right -mt-2.5">
          <div>
            {lessons.length > 0 && (
              <a href="#lessons" className="text-blue-500 hover:text-blue-700">
                Lessons ({lessons.length})
              </a>
            )}
          </div>
          <div>
            {resourceContent.length > 0 && (
              <a
                href="#resources"
                className="text-blue-500 hover:text-blue-700"
              >
                Resources ({resourceContent.length})
              </a>
            )}
          </div>
        </div>
        {lessons.length > 0 && (
          <div id="lessons">
            <h1 className="text-2xl">Lessons</h1>
            {lessons}
          </div>
        )}

        {resourceContent.length > 0 && (
          <div id="resources">
            <h1 className="text-2xl">Resources</h1>
            <ResourceTab
              onResourceClick={() => {
                track({
                  action: "page_interaction",
                  meta: {
                    interaction: "view-resource-from-search",
                    resourceType: "resource",
                  },
                });
              }}
              resources={resourceContent as any}
              opts={{
                noteable: false,
                trackProgress: false,
                videoClasses: "w-full",
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const handleFocus = () => {
    setSearchBarHasFocus(true);
  };

  const handleBlur = () => {
    setSearchBarHasFocus(false);
  };

  return (
    <div ref={searchBarRef}>
      <form onSubmit={search}>
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div className="relative flex items-center">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="bg-white block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Lessons"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bg-brand-primary hover:bg-brand-primary-darker focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Search
          </button>
        </div>
      </form>

      {shouldShow && (
        <div className="scroll-smooth bg-white absolute p-4 shadow z-50 rounded-lg -mt-4 right-4 w-[80vw] md:w-[40vw] max-h-[80vh] overflow-auto">
          {popupContent()}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
