import React, { useState } from "react";
import { Card } from "../../../components/card/card";
import Thumbnail from "../../learningLibrary/modal/Thumbnail";

import { Lesson, LessonPlan } from "../../learningLibrary/learningLibraryTypes";

const PreviewModalRow = ({
  lesson,
  lessonPlan,
  thumbnail = null,
  purchased = false,
}: {
  lesson?: Lesson;
  lessonPlan?: LessonPlan;
  product?: any;
  thumbnail?: any;
  purchased?: boolean;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  let name = lessonPlan?.name || "";
  let description = lessonPlan?.description || "";

  if (lesson) {
    name = lesson?.name || "";
    description = lesson?.description || "";
  }

  function RowBody() {
    return (
      <div className="flex-1 ml-4">
        <span className="font-bold items-center -mr-12 md:mr-0 h-full md:h-fit flex flex-row w-full">
          {name}
        </span>
        {description && (
          <div
            className="hidden md:flex text-sm text-gray-700"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    );
  }

  return (
    <div
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
      onClick={() => setShowDescription(!showDescription)}
      className={`relative z-10 h-fit -mb-4 md:mb-4`}
    >
      <Card
        useCursor={false}
        addedStyling="md:border-none border-brand-primary border-solid border-opacity-25"
      >
        <>
          <div className="flex">
            <Thumbnail
              primaryResource={thumbnail}
              isHovering={isHovering}
              className="flex items-center my-auto h-24 w-24 md:h-full md:w-fit overflow-hidden"
            />
            <RowBody />
          </div>
          {description && (
            <div
              className="flex m-0 md:hidden p-2 mt-2"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </>
      </Card>
    </div>
  );
};

export default PreviewModalRow;
