import { api, supabase } from "./api";
import { brandConfig } from "./helpers/brandConfig";
import initializeTracking from "./helpers/initializeTracking";
import { Helmet } from "react-helmet";
import Layout from "./components/layout";
import LocationReporter from "./locationReporter";
import React, { createContext, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Discover from "./pages/discover";
import CoursePage from "./pages/learningLibrary/modal/CoursePage";
import Dashboard from "./pages/dashboard";
import Enroll from "./pages/enroll";
import Home from "./pages/home";
import LearningLibrary from "./pages/learningLibrary";
import Lesson from "./pages/learningLibrary/lesson";
import LessonPlanPage from "./pages/learningLibrary/modal/LessonPlanPage";
import LoginForm from "./pages/loginForm";
import NotFoundPage from "./pages/404";
import PreviewModal from "./pages/discover/modal/PreviewModal";
import ResetPassword from "./pages/resetPassword";
import UserSettings from "./pages/userSettings";
import Intro from "./pages/enroll/intro";
import Notes from "./pages/notes";

import { UserContextProvider } from "./contexts/UserContext";
import OfferPage from "./pages/learningLibrary/OfferPage";
import { NestedNavigationProvider } from "./contexts/NestedNavigation";
import { CustomCssVars } from "./components/CustomCssVars";
import ShortCodeReplacer from "./components/resources/ShortCodeReplacer";

export const ProfileContext = createContext({
  profile: {},
  setProfile: (_) => {},
});
export const NoteContext = createContext({ notes: [], setNotes: (_) => {} });

function App() {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [notes, setNotes] = useState([]);
  const [brand, setBrand] = useState();

  useEffect(() => {
    (async () => {
      const fetchBrand = async (abbreviation) => {
        return api.ensureOne(
          await supabase
            .from("brands")
            .select("*, brand_configurations:brand_configurations (*)")
            .eq("abbreviation", abbreviation)
        );
      };

      const brandAbbreviation = brandConfig.getBrandAbbreviationForCurrentURL();

      let brand;
      if (brandConfig.getFetchBrandOptions().includes(brandAbbreviation)) {
        brand = await fetchBrand(brandAbbreviation);
      } else {
        console.warn("Unknown brand!");
      }
      if (brand?.id) {
        localStorage.setItem("brandId", brand.id);
        setBrand(brand);
        const brandResources = await api.getBrandResources(brand.id);

        brandConfig.setBrandConfig(
          brand.id,
          brand.name,
          brand.description,
          brand.abbreviation,
          brand.brand_configurations && brand.brand_configurations[0],
          brandResources
        );
      }

      const favicon = document.getElementById("favicon");
      favicon.href = brandConfig.favicon();
      const appleTouchIcon = document.getElementById("appleTouchIcon");
      appleTouchIcon.href = brandConfig.favicon();
      const name = brandConfig.brandFullName();

      document
        .querySelector("#manifest-placeholder")
        .setAttribute("href", "/public/manifest.json");
      var myDynamicManifest = {
        name: name,
        short_name: name,
        description: name,
        start_url: ".",
        display: "standalone",
        theme_color: "#000000",
        background_color: "#ffffff",
        icons: [
          {
            src: brandConfig.favicon(),
            sizes: "64x64 32x32 24x24 16x16",
            type: "image/png",
          },
        ],
      };
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], { type: "application/json" });
      const manifestURL = URL.createObjectURL(blob);
      document
        .querySelector("#manifest-placeholder")
        .setAttribute("href", manifestURL);

      const root = document.documentElement;
      const colors = brandConfig.getCustomColors();
      root.style.setProperty("--color-primary", colors.primary);
      root.style.setProperty("--color-secondary", colors.secondary);
      root.style.setProperty("--color-tertiary", colors.tertiary);
      root.style.setProperty("--color-quaternary", colors.quaternary);
      root.style.setProperty(
        "--color-videojs-bar",
        `${colors.primary.replaceAll(" ", ", ")}, 0.7`
      );

      if (name) {
        document.title = `${name} Learning Experience`;
        document.description =
          brandConfig.brandDescription ||
          `Online learning portal for ${name}'s courses`;
      } else {
        document.title = "Learning Experience";
        document.description = "Online learning portal.";
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (brand?.id != undefined) {
      initializeTracking(brand);
    }
  }, [brand]);

  if (loading) return null;

  return (
    <BrowserRouter>
      {brandConfig.id && (
        <Helmet>
          <script
            async
            id="ze-snippet"
            src={`https://static.zdassets.com/ekr/snippet.js?key=${brandConfig.zendeskKey()}`}
          />
        </Helmet>
      )}
      <CustomCssVars brandConfig={brandConfig} />
      <UserContextProvider>
        <NestedNavigationProvider>
          <ProfileContext.Provider value={{ profile, setProfile }}>
            <NoteContext.Provider value={{ notes, setNotes }}>
              <LocationReporter />
              <ShortCodeReplacer />
              <Layout>
                <Routes>
                  <Route path="*" element={<NotFoundPage />} />
                  <Route path="" element={<Home />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/notes" element={<Notes />} />
                  <Route
                    path="/learning-library"
                    element={<LearningLibrary />}
                  />
                  <Route
                    path="/learning-library/course/:courseId"
                    element={<CoursePage />}
                  />
                  <Route
                    path="/learning-library/course/:courseId/lesson-plan/:lessonPlanId"
                    element={<LessonPlanPage />}
                  />
                  <Route
                    path="/learning-library/offer/:offerId"
                    element={<OfferPage />}
                  />
                  <Route
                    path="/learning-library/course/:courseId/lesson-plan/:lessonPlanId/lesson/:lessonId"
                    element={<Lesson />}
                  />
                  <Route path="/discover" element={<Discover />} />
                  <Route
                    path="/discover/course/:courseId"
                    element={
                      <Discover>
                        <PreviewModal />
                      </Discover>
                    }
                  />
                  <Route path="/login-form" element={<LoginForm />} />
                  <Route path="/settings" element={<UserSettings />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/welcome" element={<ResetPassword />} />
                  <Route path="/enroll" element={<Enroll />} />
                  <Route path="/enroll/intro" element={<Intro />} />
                </Routes>
              </Layout>
            </NoteContext.Provider>
          </ProfileContext.Provider>
        </NestedNavigationProvider>
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;
