import React from "react";
import { brandConfig, logoCC } from "../helpers/brandConfig";

export default function Dashboard() {
  return (
    <nav className="flex flex-col items-center">
      <a href="https://www.combinedcuriosity.com/">
        <img alt="cc icon" src={logoCC} height="150" />
      </a>
      <hr className="w-full" />
      <p className="uppercase text-xs -mt-4 mb-1 font-semibold text-gray-500">
        Click a logo to go to its course selection page
      </p>
      <hr className="w-full" />

      <article className="flex flex-wrap flex-row gap-x-8 justify-around w-full">
        {brandConfig.getBrandList().map((b) => (
          <a
            key={`brand_link_${b.label}`}
            aria-label={`brand_link_${b.label}`}
            href={`/${b.label}/learning-library`}
            className="my-auto"
          >
            <img
              alt={`${b.label} icon`}
              src={b.logo}
              height={b?.height || "150"}
            />
          </a>
        ))}
      </article>
    </nav>
  );
}
