import React from "react";
import {
  AiOutlineLeft,
  AiOutlineInfoCircle,
  AiOutlineClose,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import renderResource from "./resources/renderResource";

// mobile only secondary top nav bar
export default function MobileHeader({
  hideInfo,
  showInfo,
  setShowInfo,
  goBack,
  defaultBack = "/learning-library",
  title,
  thumbnail,
  description,
}: {
  hideInfo?: boolean;
  showInfo?: boolean;
  setShowInfo?: any;
  goBack?: any;
  defaultBack?: string;
  title?: string;
  thumbnail?: any;
  description?: string;
}) {
  const navigate = useNavigate();

  // on click of top left arrow icon button
  function onClick() {
    if (showInfo) return;
    if (goBack) goBack();
    else navigate(defaultBack);
  }

  // if info button in top right should be displayed
  const infoBtn = () => {
    if (hideInfo)
      return (
        <AiOutlineInfoCircle className="invisible text-4xl -mb-0.5 mr-1" />
      );
    if (showInfo)
      return (
        <AiOutlineClose
          className={`text-4xl -mb-0.5 mr-1`}
          onClick={() => setShowInfo(false)}
        />
      );
    return (
      <AiOutlineInfoCircle
        className={`text-4xl -mb-0.5 mr-1`}
        onClick={() => setShowInfo(true)}
      />
    );
  };

  return (
    <>
      <header className="flex md:hidden fixed top-14 left-0 h-14 w-full bg-white z-40">
        <span className="bg-brand-primary bg-opacity-20 w-full h-full px-2 flex items-center justify-between">
          <AiOutlineLeft
            className={`text-4xl -mb-0.5 mr-1 ${showInfo && "invisible"}`}
            onClick={onClick}
          />
          <h4 className="m-0">{title}</h4>
          {infoBtn()}
        </span>
      </header>
      <div className="flex md:hidden h-10" />{" "}
      {/* don't let header overlap content on mobile */}
      {showInfo && (
        <section
          className="p-4 overflow-y-scroll gray-scrollbar"
          style={{ height: "calc(100vh - 7rem)" }}
        >
          {thumbnail?.type === "video_resource" ? (
            renderResource(thumbnail as any, {}, () => {}, {
              videoClasses: "m-auto mb-4",
            })
          ) : (
            <img
              alt={`${title} cover image`}
              src={thumbnail}
              className="w-full"
            />
          )}
          <p className="m-0 -mt-4 font-semibold">
            {description || "No description"}
          </p>
        </section>
      )}
    </>
  );
}
