import React from "react";
import { MdSettings } from "react-icons/md";
import { VideoJsPlayer } from "video.js";
import LocalUserSettings from "../helpers/LocalUserSettings";
import Button from "./buttons/Button";

type VideoInterstitialType = {
  showInterstitial: boolean;
  setShowInterstitial: (show: boolean) => void;
  player?: VideoJsPlayer;
};

const VideoInterstitial = ({
  showInterstitial,
  setShowInterstitial,
  player,
}: VideoInterstitialType) => {
  const [showSettings, setShowSettings] = React.useState<boolean>(false);

  const disableAutoPause = () => {
    LocalUserSettings.set("autoPause", false);
    setShowInterstitial(false);
    player?.play();
  };

  if (LocalUserSettings.get("autoPause") === false) {
    return null;
  }

  return (
    <div
      className={`${
        showInterstitial ? "" : "hidden"
      } absolute bg-white w-4/5 p-16 z-50 top-1/4 rounded-lg shadow-lg left-0 right-0 m-auto text-center text-xl`}
    >
      <div className="absolute top-4 right-4">
        <MdSettings
          className={`hover:text-brand-primary cursor-pointer transition-all duration-300 ${
            showSettings ? "rotate-45" : "rotate-0"
          }`}
          onClick={() => setShowSettings(!showSettings)}
        />
        {showSettings && (
          <div className="bg-white shadow rounded p-8 absolute right-0 w-64">
            <Button onClick={disableAutoPause} style="primary">
              Disable AutoPause
            </Button>
          </div>
        )}
      </div>
      Take a second to reflect on what you just learned, practice for a second,
      or just take a breath!
      <div className="grid grid-rows-2 grid-cols-1 grid- md:grid-rows-1 md:grid-cols-3 mt-8 items-baseline">
        <a
          href="#"
          onClick={() => {
            setShowInterstitial(false);
            player?.currentTime(player?.currentTime() - 30);
            player?.play();
          }}
          className="md:justify-self-start text-md"
        >
          Rewind 30 seconds
        </a>

        <div className="mt-4 md:mt-0">
          <Button
            onClick={() => {
              setShowInterstitial(false);
              player?.play();
            }}
            style="primary"
          >
            Continue
          </Button>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default VideoInterstitial;
