import React, { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

const useCollapseToggle = () => {
  const [collapsed, setCollapsed] = useState(false);

  const collapseToggleFn = () => setCollapsed(!collapsed);

  const collapseToggle = (children: React.ReactNode) => (
    <div
      onClick={collapseToggleFn}
      className="flex flex-row justify-between items-baseline cursor-pointer hover:text-brand-tertiary"
    >
      {children}
      <span
        className={`text-sm leading-6 ${
          collapsed ? "rotate-0" : "rotate-180"
        } transition-all duration-500`}
        onClick={collapseToggleFn}
      >
        {<AiOutlineDown />}
      </span>
    </div>
  );

  const collapsibleContent = (children: React.ReactNode) => (
    <div
      className={`transition-all duration-500 ${
        collapsed ? "h-0 overflow-hidden opacity-0" : "h-auto opacity-100"
      }`}
    >
      {children}
    </div>
  );

  return { collapseToggle, collapsed, collapsibleContent };
};

export default useCollapseToggle;
