import React, { useEffect, useState } from "react";
import { api } from "../../../api";
import Button from "../../../components/buttons/Button";
import {
  FaCcAmex,
  FaCreditCard,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaPlus,
} from "react-icons/fa";
import { brandConfig } from "../../../helpers/brandConfig";
import { loadStripe } from "@stripe/stripe-js";
import ReplaceWhileLoading from "../../../components/loadingIndicator/ReplaceWhileLoading";
import { UserInfo } from "../userSettingsTypes";
import Snackbar from "../../../components/snackbar/snackbar";

const Payment = ({ userInfo }: { userInfo: UserInfo }) => {
  const [cardElement, setCardElement] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [showAddCard, setShowAddCard] = useState<boolean>(false);
  const [stripe, setStripe] = useState<any>(null);

  const getPaymentMethods = async () => {
    const response: any = await api.getStripePaymentMethods();
    if (response.ok) {
      const paymentMethods = await response.json();
      setPaymentMethods(paymentMethods);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // disabling no-undef here - this appears to be an eslint issue
        // eslint-disable-next-line no-undef
        const loadedStripe = await loadStripe(
          process.env[
            "REACT_APP_" +
              brandConfig.brandAbbreviation?.toUpperCase() +
              "_STRIPE_PUBLISHABLE_KEY"
          ] || ""
        );
        setStripe(loadedStripe);

        await getPaymentMethods();
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!stripe?.elements()) return;
      const elements = stripe.elements();
      const card = elements.create("card", {
        style: {
          base: {
            color: "#000",
            fontWeight: "500",
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
              color: "#fce883",
            },
          },
          invalid: {
            // iconColor: '#FFC7EE',
            // color: '#FFC7EE',
          },
        },
      });
      // Check if the card element exists before mounting
      const cardElement = document.getElementById("card");
      if (cardElement) {
        card.mount("#card");
        setCardElement(card);
      } else {
        console.error("Card element not found in the DOM");
      }
    })();
  }, [stripe, showAddCard]);

  const capitalizeString = (string: string) => {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
  };

  const toggleShowAddCard = () => {
    return setShowAddCard(!showAddCard);
  };

  const submitNewCard = async () => {
    try {
      setLoading(true);
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: userInfo.email,
        },
      });

      if (paymentMethod.error) {
        setErrorMessage(paymentMethod.error.message);
        setLoading(false);
        return;
      }

      await api.attachStripePaymentMethod(paymentMethod.id);
      setTimeout(async () => {
        await getPaymentMethods();
        setLoading(false);
      }, 1500);

      cardElement.clear();
      toggleShowAddCard();
      setSuccessMessage("Payment method added");
    } catch (e) {
      setLoading(false);
    }
  };

  const removePaymentMethod = async (id: string) => {
    try {
      setLoading(true);
      await api.removeStripePaymentMethod(id);
      await getPaymentMethods();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const makeDefaultPaymentMethod = async (id: string) => {
    try {
      setLoading(true);
      await api.updateDefaultStripePaymentMethod(id);
      await getPaymentMethods();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const creditCardLogo = (type: string) => {
    const cardLogoClasses = "mr-2 text-xl align-middle";
    switch (type) {
      case "amex": {
        return <FaCcAmex className={cardLogoClasses} />;
      }
      case "diners": {
        return <FaCcDinersClub className={cardLogoClasses} />;
      }
      case "discover": {
        return <FaCcDiscover className={cardLogoClasses} />;
      }
      case "jcb": {
        return <FaCcJcb className={cardLogoClasses} />;
      }
      case "mastercard": {
        return <FaCcMastercard className={cardLogoClasses} />;
      }
      case "visa": {
        return <FaCcVisa className={cardLogoClasses} />;
      }
      default: {
        return <FaCreditCard className={cardLogoClasses} />;
      }
    }
  };

  return (
    <section className="flex flex-col gap-x-6 w-full pb-4">
      <h3>Payment Information</h3>
      <Snackbar
        message={successMessage}
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage("")}
      />
      <Snackbar
        message={errorMessage}
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
      />
      <ReplaceWhileLoading loading={loading}>
        <>
          {paymentMethods.length ? (
            <div className="ml-3">
              {paymentMethods.map((pm: any, index: number) => (
                <div className="my-2" key={`pm-${index}`}>
                  <div>
                    {creditCardLogo(pm.card.brand)}
                    {capitalizeString(pm.card.brand)}
                    &nbsp;Ending In {pm.card.last4}
                    {pm.metadata.default === "true" ? (
                      <span className="text-xs text-blue-600 bg-gray-300 ml-3 p-1">
                        Default
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {pm.metadata.default !== "true" && (
                    <div>
                      <Button
                        onClick={() => removePaymentMethod(pm.id)}
                        style="text"
                      >
                        Remove Card
                      </Button>
                      |
                      <Button
                        onClick={() => makeDefaultPaymentMethod(pm.id)}
                        style="text"
                      >
                        Make Default Card
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div>
              <span>No Payment methods on file.</span>
            </div>
          )}
          {!showAddCard ? (
            <div className="flex flex-col w-full lg:w-1/2">
              <Button onClick={toggleShowAddCard} style="text">
                <FaPlus className="mr-1 align-middle" />
                Add New Card
              </Button>
            </div>
          ) : (
            <div className="flex flex-col justify-center w-full lg:w-1/2">
              <div className="border-2 border-gray-100 rounded p-4 flex-1">
                <div id="card" />
                <Button
                  additionalStyling="mt-3 mr-2"
                  onClick={toggleShowAddCard}
                  style="primary"
                >
                  Cancel
                </Button>
                <Button
                  additionalStyling="mt-3"
                  onClick={submitNewCard}
                  style="primary"
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </>
      </ReplaceWhileLoading>
    </section>
  );
};

export default Payment;
