import { useState } from "react";
import videojs, { VideoJsPlayer } from "video.js";

export const customControlBarButton = ({
  player,
  buttonName,
  clickHandler,
  svg,
  index,
}: any) => {
  var ButtonComp = videojs.getComponent("Button");
  var newButton = new ButtonComp(player, { clickHandler } as any);
  newButton.addClass(buttonName);
  player.controlBar.addChild(newButton, {}, index || 1);
  (document.querySelector(`.${buttonName} .vjs-icon-placeholder`) as any).id =
    buttonName;
  (
    document.querySelector(`.${buttonName} .vjs-icon-placeholder`) as any
  ).innerHTML = svg;
  (
    document.querySelector(`.${buttonName} .vjs-icon-placeholder`) as any
  ).className = "cursor-pointer";
};

export const addSkipForwardButton = (player: VideoJsPlayer) => {
  // skip forward button
  customControlBarButton({
    player,
    buttonName: "skipForwardBtn",
    clickHandler: function () {
      let time = player.currentTime() + 15;
      player.currentTime(time);
    },
    svg: `
          <svg width="24" height="14" stroke="white" stroke-width="2" fill="white">
            <polyline points="3,2 10,7 3,12" />
            <polyline points="13,2 20,7 13,12" />
          </svg>`,
  });
};

export const addSkipBackwardButton = (player: VideoJsPlayer) => {
  // skip backward button
  customControlBarButton({
    player,
    buttonName: "skipBackBtn",
    clickHandler: function () {
      let time = player.currentTime() - 15;
      player.currentTime(time);
    },
    svg: `
          <svg width="24" height="14" stroke="white" stroke-width="2" fill="white">
            <polyline points="10,3 3,7 10,12" />
            <polyline points="20,3 13,7 20,12" />
          </svg>`,
  });
};

export const addTheatherButton = (
  player: VideoJsPlayer,
  setTheaterMode: (mode: boolean) => void
) => {
  let mode = false;
  customControlBarButton({
    player,
    index: 8,
    buttonName: "theaterModeBtn",
    clickHandler: function () {
      setTheaterMode(!mode);
      mode = !mode;
    },
    svg: `
            <svg width="24" height="14" stroke="white" stroke-width="3" fill="none">
              <rect width="24" height="14"/>
            </svg>`,
  });
};

export const addNoteButton = (
  player: VideoJsPlayer,
  note: (e: any) => void
) => {
  // note button
  customControlBarButton({
    player,
    index: 8,
    buttonName: "noteBtn",
    clickHandler: function (e: any) {
      note(e);
    },
    svg: `
            <svg width="24" height="14" stroke="white" stroke-width="1" fill="white">
              <polyline points="8,14 8,1 16,1 16,14 12,7 8,14" />
            </svg>`,
  });
};
