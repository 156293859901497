import {
  AiOutlineDownload,
  AiOutlineFilePdf,
  AiOutlineLink,
} from "react-icons/ai";
import { definitions } from "../../../types/supabase";
import { ImImage } from "react-icons/im";
import { MdVideoLibrary } from "react-icons/md";
import { Modal } from "../../components/modal/modal";
import React from "react";
import renderResource from "../../components/resources/renderResource";
import useCollapseToggle from "../../components/useCollapseToggle";

export function SecondaryResources({
  resources,
  selectedResource,
  setSelectedResource,
}: {
  resources: any;
  selectedResource: definitions["resources"] | null;
  setSelectedResource: any;
}) {
  if (resources.length === 0) return null;

  const { collapseToggle, collapsed, collapsibleContent } = useCollapseToggle();

  const row = (r: any) => {
    const style =
      "no-underline flex items-center text-black hover:bg-gray-50 cursor-pointer";

    let icon;
    switch (r.type) {
      case "youtube_resource":
      case "vimeo_resource":
      case "video_resource":
        icon = <MdVideoLibrary />;
        break;
      case "pdf_resource":
        icon = <AiOutlineFilePdf />;
        break;
      case "image_resource":
        icon = <ImImage />;
        break;
      default:
        icon = <AiOutlineLink />;
        break;
    }

    if (!r.s3_path) {
      return (
        <a
          href={r.url}
          target="_blank"
          rel="noreferrer"
          key={`resource_${r.id}`}
          className={style}
        >
          <span className="text-sm leading-6 ">
            {icon} <span className="ml-2 hover:underline">{r.name}</span>
          </span>
        </a>
      );
    }

    return (
      <a
        onClick={() => setSelectedResource(r)}
        key={`resource_${r.id}`}
        className={style}
      >
        <span className="text-sm leading-6 ">
          {icon} <span className="ml-2 hover:underline">{r.name}</span>
        </span>
      </a>
    );
  };

  const downloadableCheck = (r: any) =>
    r.url && (r.type === "pdf_resource" || r.type === "image_resource");

  return (
    <>
      {selectedResource && (
        <Modal
          setOpen={setSelectedResource}
          contentClassName="relative bg-gray-100 p-2 m-2 my-auto md:mx-8 xl:mx-48 cursor-default w-full overflow-auto"
          closeButtonClassName="absolute z-40 top-0 right-0 px-2 font-bold text-lg cursor-pointer bg-opacity-20 bg-black hover:bg-gray-300 text-white hover:text-black"
        >
          <h3 className="mt-3 pb-4 mb-1 ml-2">{selectedResource.name}</h3>
          {downloadableCheck(selectedResource) && (
            <a
              href={selectedResource.url}
              key={`${selectedResource.id}_download`}
              className="pb-2 md:absolute md:top-1 md:right-10 md:text-sm text-brand-primary hover:text-brand-secondary"
              download={true}
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineDownload className="-mb-0.5 mr-0.5" />
              Download
            </a>
          )}
          {renderResource(selectedResource as any, {}, () => {}, {})}
        </Modal>
      )}

      <div className="flex flex-col justify-center text-left bg-white ring-1 ring-black ring-opacity-5 lg:ring-opacity-0 px-4 mt-4 md:mt-0 resource-block">
        {collapseToggle(
          <h2 className="text-sm flex justify-between my-2">Resources</h2>
        )}

        {collapsibleContent(
          <div className="space-y-1 mb-4 overflow-y-auto max-h-[20vh]">
            {resources.map((resource: any) => row(resource))}
          </div>
        )}
      </div>
    </>
  );
}
