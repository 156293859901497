import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ModalTypes } from "./modalTypes";

export function Modal({
  setOpen,
  children,
  navOnClose,
  frameClassName = "",
  contentClassName = "",
  closeButtonClassName = "",
}: ModalTypes) {
  const navigate = useNavigate();

  const mobileComputedContentClassName = `flex flex-col relative rounded-lg mt-14 h-full my-auto cursor-default w-full ${contentClassName}`;
  const mobileComputedFrameClassName = `flex md:hidden justify-center bg-black bg-opacity-50 pointer-events-auto w-full h-full fixed top-16 left-0 z-30 cursor-pointer ${frameClassName}`;

  const computedContentClassName = `flex flex-col relative rounded-lg bg-gray-100 mx-2 md:mx-8 xl:mx-48 min-h-96 h-5/6 my-auto cursor-default w-full max-h-screen overflow-auto ${contentClassName}`;
  const computedFrameClassName = `hidden md:flex justify-center bg-black bg-opacity-50 w-full h-full fixed top-0 left-0 z-30 cursor-pointer ${frameClassName}`;
  const computedCloseButtonClassName = `absolute z-40 top-2 right-1 text-3xl md:text-xl cursor-pointer text-gray-500 hover:text-black ${closeButtonClassName}`;

  function close() {
    if (navOnClose) navigate(navOnClose, { replace: true });
    else setOpen(false);
  }

  return (
    <>
      {/* mobile */}
      <div
        id="popup_frame_mobile"
        className={mobileComputedFrameClassName}
        onClick={(e: any) => e.target.id === "popup_frame_mobile" && close()}
      >
        <div
          id="popup_content_mobile"
          className={`gray-scrollbar ${mobileComputedContentClassName}`}
        >
          <div
            id="close_modal_btn_mobile"
            onClick={close}
            className={computedCloseButtonClassName}
          >
            <AiOutlineClose />
          </div>
          {children}
        </div>
      </div>

      {/* non-mobile */}
      <div
        id="popup_frame"
        className={computedFrameClassName}
        onClick={(e: any) => e.target.id === "popup_frame" && close()}
      >
        <div
          id="popup_content"
          className={`gray-scrollbar ${computedContentClassName}`}
        >
          <div
            id="close_modal_btn"
            onClick={close}
            className={computedCloseButtonClassName}
          >
            <AiOutlineClose />
          </div>
          {children}
        </div>
      </div>
    </>
  );
}
