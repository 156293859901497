import { Link } from "react-router-dom";
import React, { ReactNode } from "react";

const StyledLink = ({
  children,
  route,
  styling,
}: {
  children: ReactNode;
  route: string;
  styling: string;
}) => {
  return (
    <Link to={route} className={`${styling}`}>
      {children}
    </Link>
  );
};

export default StyledLink;
