import React, { useEffect, useRef, useState } from "react";

const ToggleContent = ({
  onToggle = () => null,
  toggle,
  content,
}: {
  onToggle?: Function;
  toggle: React.ReactElement;
  content: React.ReactElement;
}) => {
  const [open, setOpen] = useState(false);
  const toggleTrigger = useRef(null);

  useEffect(() => {
    const closeContent = (event: any) => {
      if (event.target !== (toggleTrigger.current as any)?.children[0]) {
        setOpen(false);
      }
    };

    document.addEventListener("click", closeContent);
    return () => document.removeEventListener("click", closeContent);
  });

  return (
    <div className="relative z-50 ">
      <div
        onClick={() => {
          setOpen(!open);
          onToggle();
        }}
        ref={toggleTrigger}
      >
        {toggle}
      </div>
      {open && content}
    </div>
  );
};

export default ToggleContent;
