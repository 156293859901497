import { Step } from "react-joyride";
import { brandConfig } from "../helpers/brandConfig";
import React from "react";
import { definitions } from "../../types/supabase";
import VideoPlayer from "./videojs_player";
import generateVideoJsOptions from "./generateVideoJsOptions";

export class JoyRideConfig {
  static pages = {
    courseModal: "welcome-tour-course-modal",
    learningLibrary: "welcome-tour-learning-library",
    lessonPage: "welcome-tour-lesson-page",
    lessonPlanModal: "welcome-tour-lesson-plan-modal",
  };

  static setCourseModalCompleted() {
    localStorage.setItem(`welcome-tour-course-modal`, "completed");
  }

  static getCourseModalCompleted() {
    return localStorage.getItem(`welcome-tour-course-modal`) == "completed";
  }

  static setLearningLibraryCompleted() {
    localStorage.setItem(`welcome-tour-learning-library`, "completed");
  }

  static getLearningLibraryCompleted() {
    return localStorage.getItem(`welcome-tour-learning-library`) == "completed";
  }

  static setLessonPageCompleted() {
    localStorage.setItem(`welcome-tour-lesson-page`, "completed");
  }

  static getLessonPageCompleted() {
    return localStorage.getItem(`welcome-tour-lesson-page`) == "completed";
  }

  static setLessonPlanModalCompleted() {
    localStorage.setItem(`welcome-tour-lesson-plan-modal`, "completed");
  }

  static getLessonPlanModalCompleted() {
    return (
      localStorage.getItem(`welcome-tour-lesson-plan-modal`) == "completed"
    );
  }

  static reset() {
    Object.keys(JoyRideConfig.pages).map((key) => {
      localStorage.removeItem((JoyRideConfig.pages as any)[key]);
    });
  }
}

export const getLearningLibraryStepsForUserProfile = (
  profile?: definitions["profiles"]
) => {
  const learningLibrarySteps: Step[] = [
    {
      target: "body",
      content: `Hi there! Welcome to your new ${brandConfig.brandType()} Learning Experience! Before you begin your journey, let's take a quick tour of the platform.`,
      placement: "center",
    },
  ];

  const videoResource = profile
    ? brandConfig.getWelcomeVideoForUserProfile(profile)
    : null;

  if (videoResource) {
    const options = generateVideoJsOptions(videoResource);
    const videoPlayer = (
      <div className="sm:w-[90vw] md:w-[70vw] lg:w-[50vw] bg-white m-auto">
        <VideoPlayer
          options={options}
          resourceId={videoResource.resource_id}
          lessonName={""}
          noteable={false}
        />
      </div>
    );

    learningLibrarySteps.push({
      target: "body",
      content: videoPlayer,
      placement: "center",
      styles: {
        options: {
          width: "80vw",
        },
      },
    });
  }

  learningLibrarySteps.push({
    target: ".product-0",
    content:
      'All your purchased courses will be available here in the Learning Library. Click on your first course to get started. You can get back to this page at any time by clicking "Library" in the upper left! Once you begin your courses, this page will display exactly where you left off.',
    placement: "auto",
  });

  return learningLibrarySteps;
};

export const courseModalSteps: Step[] = [
  {
    target: ".course-0",
    content:
      "Welcome to the first course! Below, you will find all your classes. Click on the first one to get started.",
    placement: "left",
    disableBeacon: true,
  },
];

export const lessonPlanModalSteps: Step[] = [
  {
    target: ".lesson-plan-0",
    content:
      "You're on a roll! Inside your class, you will find all included lessons, exercises, and resources. Click on Lesson 1 to start learning.",
    placement: "auto",
    disableBeacon: true,
  },
];

export const lessonPageSteps: Step[] = [
  {
    target: ".primary-resource-container",
    content:
      "Tada! You made it. This is where the real fun begins! Your lessons and exercises will be visible front and center. If you have to leave or accidentally exit in the middle of a lesson, no worries! Your Learning Library will save exactly where you left off.",
    placement: "auto",
    disableBeacon: true,
  },
  {
    target: ".resource-block",
    content:
      "You can access your lesson resources and additional information here in the Resources tab.",
    placement: "auto",
    disableBeacon: true,
  },
  {
    target: ".next-up-button",
    content:
      'Once you\'re finished, click through the next lesson or exercise here—or, take a break and come back later. You will never have to worry about your spot being lost, it will always be kept in the "Library" tab on the upper left.',
    placement: "auto",
    disableBeacon: true,
  },
  {
    target: "iframe#launcher",
    content:
      "Finally, if you ever have questions or need help, just ask! We'll be happy to make sure you have everything you need to help you on your new journey.",
    placement: "auto",
    disableBeacon: true,
  },
];
