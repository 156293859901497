import React from "react";
import ContactSupport from "./ContactSupport";
import GoBackLink from "./GoBackLink";
import imgErrorBoundary from "../components/images/imgErrorBoundary.png";

class ErrorBoundary extends React.Component {
  state: { hasError: boolean };
  props: any;

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  handleLinkClicked() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col w-full text-center items-center pt-24">
          <img
            className="sm:w-1/2"
            alt="Image by storyset = https://storyset.com/illustration/no-data/cuate"
            src={imgErrorBoundary}
          />
          <p className="md:text-xl">
            Uh-oh! Something went wrong. Please go back to the previous page.
          </p>
          <p className="md:text-xl">
            If the problem persists, please contact support.
          </p>
          <div className="flex flex-col md:flex-row gap-4">
            <GoBackLink
              additionalStyling="hover:bg-gray-300"
              linkClicked={this.handleLinkClicked.bind(this)}
            />
            <ContactSupport additionalStyling="hover:bg-brand-secondary" />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
