import { Event } from "@combined-curiosity/collector-js";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[];
  }
}

export type trackEvent = (event: Event) => void;

const pushToQueue = (event: Event, transport: "beacon" | "xhr"): void => {
  window.cc = window.cc || {};
  window.cc.event_queue = window.cc.event_queue || [];
  window.cc.event_queue?.push({
    cmd: "track",
    transport,
    data: event,
  });
};

const useTracking = () => {
  const { user } = useContext(UserContext);
  const pushToEventQueue = (
    event: Event,
    transport: "beacon" | "xhr" = "xhr"
  ) => {
    window.dataLayer = window.dataLayer || [];

    event.userId = event.userId || user?.id;
    event.email = event.email || user?.email;

    // switch to notify gtm for particular events
    switch (event.action) {
      case "pageview":
        window.dataLayer.push({
          event: "route-change",
          data: event,
          user_id: user?.id,
          email: user?.email,
        });
        break;
      case "login":
        window.dataLayer.push({
          event: "login",
          user_id: user?.id,
          email: user?.email,
        });
        break;
      default:
        window.dataLayer.push({
          user_id: user?.id,
          email: user?.email,
          ...event,
        });
    }

    pushToQueue(event, transport);
  };

  return pushToEventQueue;
};

export default useTracking;
