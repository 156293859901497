import React, { useState } from "react";
import PersonalInfo from "../userSettings/settingsComponents/PersonalInfo";
import useConfetti from "../../components/useConfetti";

const PasswordPrompt = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [afterSave, setAfterSave] = useState<boolean>(false);
  const { triggerConfetti } = useConfetti();
  return (
    <div className="flex flex-col items-center justify-center bg-gray-100">
      {afterSave && (
        <header className="flex flex-col text-center bg-brand-primary text-white w-full -m-8 mb-0 py-4 md:p-8 ">
          <h1 className="pt-12 text-2xl lg:text-3xl">
            Nice, we&apos;ve saved your updates!
          </h1>
          <p className="text-lg lg:text-xl m-6">
            Feel free to close this popup and continue.
            <br />
            We hope you enjoy your lessons!
          </p>
        </header>
      )}

      {!afterSave && (
        <>
          <header className="flex flex-col text-center bg-brand-primary text-white w-full -m-8 mb-0 py-4 md:p-8 ">
            <h1 className="pt-12 text-2xl lg:text-3xl">
              We hope you&apos;ve been enjoying the Intro Course!
            </h1>
          </header>
          <p className="text-lg lg:text-xl m-6">
            Need to step away and come back later? No problem!
            <br />
            Set a password for your account and can come back anytime.
          </p>

          <div className="flex flex-col items-center px-5 w-full">
            <PersonalInfo
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
              setUserInfo={setUserInfo}
              userInfo={userInfo}
              afterSave={() => {
                setAfterSave(true);
                triggerConfetti();
              }}
              fieldsOnly={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PasswordPrompt;
