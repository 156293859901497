import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/buttons/Button";
import { useNestedNavigation } from "../../contexts/NestedNavigation";

export default function Breadcrumbs({
  courseName,
  lessonPlan,
}: {
  courseName?: string;
  lessonPlan?: any;
}) {
  let { courseId, lessonPlanId } = useParams();
  const navigate = useNavigate();
  const { nestedNavigation } = useNestedNavigation();

  const crumbs = [
    {
      requires: true,
      name: "Learning Library",
      link: `/learning-library`,
    },
    {
      type: "offer",
      requires: nestedNavigation.offer,
      name: nestedNavigation.offer?.name,
      link: `/learning-library/offer/${nestedNavigation.offer?.id}`,
    },
    {
      type: "course",
      requires: courseId,
      name: `${courseName}`,
      link: `/learning-library/course/${courseId}`,
    },
    {
      type: "lessonPlan",
      requires: lessonPlan,
      name: `${lessonPlan?.name}`,
      link: `/learning-library/course/${courseId}/lesson-plan/${lessonPlanId}`,
    },
  ];

  const defaultStyle =
    "bg-transparent border-transparent p-0 text-gray-500 text-xs";

  // _>_
  const divider = (
    <span className={`${defaultStyle} pt-0.5`}>&nbsp;&gt;&nbsp;</span>
  );

  // if lesson plan has one lesson, hide the lesson plan breadcrumb in the lesson page
  let displayedCrumbs = crumbs;
  if ((lessonPlan as any)?.lessons?.length === 1) {
    displayedCrumbs = crumbs.slice(0, crumbs.length - 1);
  }

  displayedCrumbs = displayedCrumbs.filter((c) => c.requires);

  return (
    <nav className="hidden md:flex flex-row flex-nowrap whitespace-nowrap">
      {displayedCrumbs.map((c, index) => (
        <span key={`breadcrumb_${c.link}`}>
          {index > 0 && divider}
          <Button
            additionalStyling={`${defaultStyle} hover:text-gray-700 hover:underline`}
            key={`breadcrumb_${c.name}`}
            onClick={() => navigate(c.link)}
          >
            {c.name}
          </Button>
        </span>
      ))}
    </nav>
  );
}
