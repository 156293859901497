import React from "react";
import ContactSupport from "../components/ContactSupport";
import GoBackLink from "../components/GoBackLink";
import img404 from "../components/images/404.png";

export default function NotFoundPage() {
  return (
    <div className="flex flex-col w-full text-center items-center pt-24">
      <img
        className="sm:w-1/2"
        alt="Image by storyset = https://www.freepik.com/free-vector/404-error-with-landscape-concept-illustration_20602785.htm"
        src={img404}
      />
      <p className="md:text-xl">
        Sorry, we couldn&apos;t find the page you&apos;re looking for.
      </p>
      <p className="md:text-xl">
        Try going back to the previous page or contact support.
      </p>
      <div className="flex flex-col md:flex-row gap-4">
        <GoBackLink additionalStyling="hover:bg-gray-300" />
        <ContactSupport additionalStyling="hover:bg-brand-secondary" />
      </div>
    </div>
  );
}
