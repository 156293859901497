import React from "react";
import { ProductCardType } from "./productCardType";
import cartAdd from "../images/cartAdd.png";
import cartPresent from "../images/cartPresent.png";

export function ProductCard({
  containerClassName = "relative overflow-hidden h-40 text-white rounded-lg",
  product,
  title = "",
  description = "",
  img = "",
  inCart = false,
  purchased = false,
  onClick,
  context = "library",
}: ProductCardType) {
  const released = product
    ? new Date() > new Date(product?.released_at || "")
    : true;

  const defaultStyle = `
    h-full w-full px-6 z-10 pl-0
    flex flex-wrap absolute content-end transition
    bg-opacity-0 hover:bg-opacity-50 rounded-lg`;

  const descriptionContainerStyle = `${defaultStyle} ${
    purchased ? "bg-brand-primary" : "bg-brand-secondary"
  }`;

  const hoverText = () => {
    if (!released) return "Coming Soon!";
    return product?.description || description;
  };

  return (
    <section
      data-testid="product-card"
      className={`${released ? "cursor-pointer" : ""} ${containerClassName} ${
        product?.thumbnail?.url || img ? "" : "bg-brand-primary"
      }`}
      onClick={() => (released ? onClick() : "")}
    >
      <h3 className="absolute z-20 p-4">
        <div className="bg-brand-primary bg-opacity-75 -ml-4 p-2 pl-4 rounded rounded-tl-none rounded-bl-none">
          {product?.name || title}
        </div>
      </h3>
      <div className={released ? descriptionContainerStyle : defaultStyle}>
        {hoverText() && (
          <p
            className={`${
              released && "absolute top-20 h-full opacity-0 hover:opacity-100"
            } p-1 pl-4 mr-6 rounded rounded-tl-none rounded-bl-none bg-gray-800 bg-opacity-75 max-h-32 font-semibold overflow-hidden leading-5 ${
              context != "library" ? "hidden" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: hoverText() }}
          ></p>
        )}
      </div>
      <div className="absolute object-cover h-full w-full bg-brand-primary">
        <img
          onError={(event) => ((event.target as any).style.display = "none")}
          alt="product cover image"
          src={product?.thumbnailImage?.url || img}
          className={`absolute object-cover h-full w-full ${
            !purchased && context == "library" ? "filter saturate-0" : ""
          }`}
        />
      </div>
      {/* todo - cart is not fully implemented - commenting for now */}
      {/* {!purchased && (
        <img
          alt="cart icon"
          src={inCart ? cartPresent : cartAdd}
          className={`absolute h-8 top-4 right-4 z-10 p-1 rounded-lg ${
            inCart ? "bg-green-500" : "bg-gray-500"
          }`}
        />
      )} */}
    </section>
  );
}
