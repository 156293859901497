import { AiFillCaretRight, AiOutlineRead } from "react-icons/ai";
import NextUpModal from "./modal/NextUpModal";
import { api } from "../../api";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useTracking from "../../hooks/useTracking";

const NextBtn = ({
  nextIcon,
  onNext,
  subtitle,
  thumbnail,
  title = "Next Up",
}: {
  nextIcon?: boolean;
  onNext?: any;
  subtitle?: string;
  thumbnail?: string;
  title?: string;
}) => (
  <nav
    className="
      font-semibold
      flex 
      items-center
      flex-row-reverse
      md:flex-row
      justify-between 
      px-2
      rounded 
      text-white
      cursor-pointer
      bg-brand-primary
      hover:shadow-md
      hover:scale-105
      transition-all

      md:justify-end
      my-2
      py-2
    "
    onClick={onNext}
  >
    <div className="next-up-button flex flex-col text-left my-auto mr-2 h-full items-center jutify-center ">
      <p className="m-0">{title}</p>
      {/* <p className="m-0 text-xs underline whitespace-normal">
          {subtitle}
        </p> */}
    </div>
    {nextIcon ? (
      <AiFillCaretRight className="text-2xl self-end" />
    ) : (
      <AiOutlineRead className="text-2xl self-end" />
    )}
    {thumbnail && (
      <img
        alt="next up lesson icon"
        src={thumbnail}
        className="h-10 ml-2 my-auto rounded-2xl"
      />
    )}
  </nav>
);

const NextUpButton = ({
  courseName,
  courseThumb,
  currentLesson,
  introCompletionOfferLink,
  lessonPlan,
  nextLesson,
  nextLessonPlan,
  setShowSpecialOffer,
  userName,
}: {
  courseName?: string;
  courseThumb?: string;
  currentLesson?: any;
  introCompletionOfferLink: string;
  lessonPlan?: any;
  nextLesson?: any;
  nextLessonPlan?: any;
  setShowSpecialOffer: (showSpecialOffer: boolean) => void;
  userName?: string;
}) => {
  const { courseId, lessonPlanId, lessonId } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState<Boolean>(false);
  const trackEvent = useTracking();

  useEffect(() => {
    if (open) setShowSpecialOffer(false);
  }, [open]);

  if (nextLesson) {
    return (
      <NextBtn
        // navigate, then scroll to the top after a second (to allow the next page to load)
        onNext={async () => {
          if (lessonId) {
            await api.recordUserProgressOnLesson(lessonId, {
              isCompleted: true,
            });
            trackEvent({
              action: "learning_interaction",
              meta: {
                interaction: "completed",
                learningType: "lesson",
                learningTypeId: lessonId,
                learningTypeName: currentLesson?.name,
              },
            });
          }
          await api.recordUserProgressOnLesson(nextLesson.id);
          trackEvent({
            action: "learning_interaction",
            meta: {
              interaction: "started",
              learningType: "lesson",
              learningTypeId: nextLesson.id,
              learningTypeName: nextLesson.name,
            },
          });
          navigate(
            `/learning-library/course/${courseId}/lesson-plan/${lessonPlanId}/lesson/${nextLesson.id}`
          );
          setTimeout(() => window.scrollTo({ top: 0 }), 1000);
        }}
        subtitle={nextLesson.name}
        thumbnail={nextLesson.thumbnail?.url}
        nextIcon
      />
    );
  }

  if (nextLessonPlan) {
    // if the first lesson plan in a course is a welcome one with a single welcome lesson,
    // or if this is a course that is just resources (each lesson plan is one lesson),
    // then auto-nav without a "congrats" modal
    if ((lessonPlan as any)?.lessons?.length === 1) {
      return (
        <NextBtn
          onNext={async () => {
            if (lessonPlanId) {
              await api.recordUserProgressOnClass(lessonPlanId, {
                isCompleted: true,
              });
              trackEvent({
                action: "learning_interaction",
                meta: {
                  interaction: "completed",
                  learningType: "class",
                  learningTypeId: lessonPlanId,
                  learningTypeName: lessonPlan?.name,
                },
              });
            }
            await api.recordUserProgressOnLesson(nextLessonPlan.lessons[0].id);
            trackEvent({
              action: "learning_interaction",
              meta: {
                interaction: "started",
                learningType: "lesson",
                learningTypeId: nextLessonPlan.lessons[0].id,
                learningTypeName: nextLessonPlan.lessons[0].name,
              },
            });
            navigate(
              `/learning-library/course/${courseId}/lesson-plan/${nextLessonPlan.id}/lesson/${nextLessonPlan.lessons[0].id}`
            );
          }}
          subtitle={nextLessonPlan.name}
        />
      );
    }

    return (
      <>
        <NextBtn
          onNext={() => setOpen(true)}
          subtitle="Click to continue"
          title="Lesson Plan Complete!"
        />
        <NextUpModal
          courseId={courseId}
          courseName={courseName || ""}
          currentLessonPlanId={lessonPlanId || ""}
          currentLessonPlanName={lessonPlan?.name}
          introCompletionOfferLink={introCompletionOfferLink}
          modalType="lesson_plan_complete"
          nextLessonId={nextLessonPlan?.lessons[0]?.id}
          nextLessonPlanId={nextLessonPlan.id}
          nextLessonPlanName={nextLessonPlan.name}
          nextText={`Begin ${nextLessonPlan?.name || "next lesson plan"}`}
          open={open}
          setOpen={setOpen}
          subtitle={`You completed ${lessonPlan?.name || "the lesson plan"}!`}
          thumbnail={lessonPlan?.thumbnail?.url}
          title={`Congrats${userName ? `, ${userName}` : ""}!`}
        />
      </>
    );
  }

  // if no more lesson plans in the course to continue to = course is completed
  return (
    <>
      <NextBtn
        onNext={() => setOpen(true)}
        subtitle="Click to go back to Course Page"
        title="That's all!"
      />
      <NextUpModal
        courseId={courseId}
        courseName={courseName || ""}
        currentLessonPlanId={lessonPlanId || ""}
        currentLessonPlanName={lessonPlan?.name}
        introCompletionOfferLink={introCompletionOfferLink}
        open={open}
        modalType="course_complete"
        nextText="Select a different course"
        setOpen={setOpen}
        subtitle={`You completed ${courseName || "the course"}!`}
        thumbnail={courseThumb}
        title={`Congrats${userName ? `, ${userName}` : ""}!`}
      />
    </>
  );
};

export default NextUpButton;
