import React from "react";
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineRead,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const LessonBackButton = ({
  lessonId,
  lessonPlanId,
  courseId,
}: {
  lessonId?: string;
  lessonPlanId?: string;
  courseId?: string;
}) => {
  const navigate = useNavigate();

  if (!lessonId || !lessonPlanId || !courseId) return <div></div>;

  return (
    <nav
      className="
        font-semibold flex items-center flex-row-reverse md:flex-row px-2 rounded 
        text-brand-primary md:hover:text-brand-tertiary cursor-pointer mobile-border-tertiary
        bg-white md:bg-transparent hover:bg-white my-4"
      onClick={() =>
        navigate(
          `/learning-library/course/${courseId}/lesson-plan/${lessonPlanId}/lesson/${lessonId}`
        )
      }
    >
      <AiFillCaretLeft className="text-2xl" />

      <div className="next-up-button flex flex-col text-left my-auto mr-2 h-full items-end">
        <p className="m-0">Back</p>
      </div>
    </nav>
  );
};

export default LessonBackButton;
