import { brandConfig } from "../../../helpers/brandConfig";
import Button from "../../../components/buttons/Button";
import { api } from "../../../api";
import { Facebook } from "../../../components/buttons/Share";
import { Modal } from "../../../components/modal/modal";
import React, { useEffect, useState } from "react";
import useConfetti from "../../../components/useConfetti";
import { useNavigate } from "react-router-dom";
import useTracking from "../../../hooks/useTracking";

export default function NextUpModal({
  confetti = true,
  courseId,
  courseName,
  currentLessonPlanId,
  currentLessonPlanName,
  introCompletionOfferLink,
  modalType = "next_up_modal",
  nextLessonId,
  nextLessonPlanId,
  nextLessonPlanName,
  nextText,
  open,
  setOpen,
  subtitle,
  thumbnail,
  title,
}: {
  confetti?: Boolean;
  courseId?: string;
  courseName: string;
  currentLessonPlanId: string;
  currentLessonPlanName: string;
  introCompletionOfferLink: string;
  modalType?: string;
  nextLessonId?: string;
  nextLessonPlanId?: string;
  nextLessonPlanName?: string;
  nextText?: string;
  open: Boolean;
  setOpen: any;
  subtitle?: string;
  thumbnail?: string;
  title?: string;
}) {
  const navigate = useNavigate();
  const trackEvent = useTracking();
  const { triggerConfetti } = useConfetti();
  const [specialOffer, setSpecialOffer] = useState<{
    link: string;
    text: string;
  }>({ text: "", link: "" });

  const buildNextLink = () => {
    if (!courseId || !nextLessonPlanId || !nextLessonId)
      return "/learning-library";
    return `/learning-library/course/${courseId}/lesson-plan/${nextLessonPlanId}/lesson/${nextLessonId}`;
  };

  const buildBackLink = () => {
    if (!courseId) return "/learning-library";
    return `/learning-library/course/${courseId}`;
  };

  useEffect(() => {
    if (open && confetti) triggerConfetti();
    if (subtitle && subtitle.includes("Intro Course")) {
      setSpecialOffer({
        link: `${introCompletionOfferLink}?utm_source=lx&utm_medium=in_app_offer&utm_campaign=lesson_plan_completion&utm_content=${currentLessonPlanId}`,
        text: "Get your exclusive discount!",
      });
    }
  }, [open]);

  return (
    <Modal
      setOpen={setOpen}
      frameClassName={`justify-center items-center ${
        open ? "block md:block" : "hidden md:hidden"
      }`}
      contentClassName="h-full md:h-fit md:w-2/3 md:justify-center whitespace-normal"
    >
      <div
        className="p-4 flex flex-col bg-white overflow-x-hidden"
        style={{ maxHeight: "calc(100vh - 7rem)" }}
      >
        <header className="flex flex-col gap-y-4 text-center font-bold bg-brand-primary text-white -m-8 mb-0 py-4 md:p-8 ">
          <span className="pt-8 text-3xl xl:text-5xl">{title}</span>
          <span className="text-xl xl:text-2xl mx-6 whitespace-normal">
            {subtitle}
          </span>
        </header>
        <section className="my-6 md:my-16 justify-center flex flex-col md:flex-row gap-2 md:gap-8">
          <img
            alt="finished thumbnail"
            src={thumbnail || brandConfig.navbarImage()}
            className="h-40 m-0 object-contain"
          />
          <div className="flex flex-col justify-between text-sm md:text-base text-center md:text-left">
            <p className="mb-1">
              <b>Tell your friends and family</b>
              <br />
              Share your progress with the world!
            </p>
            <Facebook
              className="self-center md:self-start"
              url={`${brandConfig.brandHomepageLink()}?utm_source=fb&utm_medium=social_share&utm_campaign=lx_sharing&utm_content=${modalType}`}
              hashtag={brandConfig.brandName?.replace(/\s/g, "")}
            />
          </div>
        </section>
        {specialOffer?.link && (
          <Button
            additionalStyling="px-4 py-2 md:text-lg xl:text-xl"
            onClick={() => {
              setOpen(false);
              window.open(specialOffer.link, "_blank");
            }}
            style="primary"
          >
            {specialOffer.text}
          </Button>
        )}
        {courseId && nextLessonPlanId && nextLessonId && (
          <Button
            additionalStyling="px-4 py-2 md:text-lg xl:text-xl"
            onClick={async () => {
              await api.recordUserProgressOnCourse(courseId);
              trackEvent({
                action: "learning_interaction",
                meta: {
                  interaction: "started",
                  learningType: "course",
                  learningTypeId: courseId,
                  learningTypeName: courseName,
                },
              });
              await api.recordUserProgressOnClass(currentLessonPlanId, {
                isCompleted: true,
              });
              trackEvent({
                action: "learning_interaction",
                meta: {
                  interaction: "completed",
                  learningType: "class",
                  learningTypeId: currentLessonPlanId,
                  learningTypeName: currentLessonPlanName,
                },
              });
              await api.recordUserProgressOnClass(nextLessonPlanId);
              trackEvent({
                action: "learning_interaction",
                meta: {
                  interaction: "started",
                  learningType: "class",
                  learningTypeId: nextLessonPlanId,
                  learningTypeName: nextLessonPlanName || "",
                },
              });
              setOpen(false);
              navigate(buildNextLink());
            }}
            style="primary"
          >
            {nextText}
          </Button>
        )}
        <Button
          additionalStyling="uppercase underline -mb-2 pt-4"
          onClick={async () => {
            if (modalType === "course_complete" && courseId) {
              await api.recordUserProgressOnCourse(courseId, {
                isCompleted: true,
              });
              trackEvent({
                action: "learning_interaction",
                meta: {
                  interaction: "completed",
                  learningType: "course",
                  learningTypeId: courseId,
                  learningTypeName: courseName,
                },
              });
              await api.recordUserProgressOnClass(currentLessonPlanId, {
                isCompleted: true,
              });
              trackEvent({
                action: "learning_interaction",
                meta: {
                  interaction: "completed",
                  learningType: "class",
                  learningTypeId: currentLessonPlanId,
                  learningTypeName: currentLessonPlanName,
                },
              });
            }
            setOpen(false);
            navigate(buildBackLink());
          }}
          style="text"
        >
          Go back
        </Button>
      </div>
    </Modal>
  );
}
