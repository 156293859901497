import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { definitions } from "../../../types";
import { api, supabase } from "../../api";
import { Modal } from "../../components/modal/modal";
import { ProductCard } from "../../components/productCard/productCard";
import { useNestedNavigation } from "../../contexts/NestedNavigation";
import Breadcrumbs from "./Breadcrumbs";

const OfferPage = () => {
  let { offerId } = useParams();
  const [products, setProducts] = useState<Array<definitions["products"]>>([]);
  const [offer, setOffer] = useState<definitions["offers"] | undefined>(
    undefined
  );
  const navigate = useNavigate();

  // useSidebarNavigation uses a provider/consumer pattern to set the sidebar navigation
  const { nestedNavigation } = useNestedNavigation();

  useEffect(() => {
    (async () => {
      if (offerId) {
        const response = api.ensureOne(
          await supabase
            .from("offers")
            .select(
              `
          *,
          packages (
            packages_products (
              *,
              products (*)
            )
          )
        `
            )
            .eq("id", offerId)
        );

        setOffer(response);
        nestedNavigation.setOffer(response);

        const products = response.packages.packages_products.map((pp: any) => {
          return pp.products;
        });

        setProducts(products);
      }
    })();
  }, [offerId]);

  return (
    <div>
      <Breadcrumbs />
      <div className="text-3xl bold">{offer?.name}</div>
      <div className="text-xl">{offer?.description}</div>

      <article className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full pt-8">
        {products.map((p, index) => (
          <ProductCard
            key={`courseSnippet_${p.id}`}
            containerClassName={`product-${index} product-0 relative overflow-hidden rounded-2xl h-56 text-white ${
              p.product_sub_type === "credit" ? "hidden" : ""
            }`}
            product={p}
            onClick={() => navigate(`/learning-library/course/${p.id}`)}
            // img={p.thumbnail?.url}
            inCart={false}
            purchased={true}
          />
        ))}
      </article>
    </div>
  );
};

export default OfferPage;
