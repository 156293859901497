import { api } from "../../../api";
import Button from "../../../components/buttons/Button";
import React, { useEffect, useState } from "react";

const PreviewCTA = ({
  offerId,
  setSelectedOffer,
  setOpen,
}: {
  offerId: string;
  setSelectedOffer: Function;
  setOpen: Function;
}) => {
  const [redeemable, setRedeemable] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const response: any = await api.getCreditCount();
      if (response.ok) {
        const customerCredits: any = await response.json();
        const offer = await api.getOffer(offerId);
        setSelectedOffer(offer);
        if (offer?.price_in_credits > 0 && customerCredits.count > 0)
          setRedeemable(true);
      } else if (response.error) {
        console.log(response.error);
      }
    })();
  }, []);

  if (!redeemable) return <div />;

  return (
    <div className="w-full md:w-fit m-4 md:m-0 md:absolute md:right-8">
      <Button
        additionalStyling="p-3 w-full"
        onClick={() => setOpen(true)}
        style="red"
      >
        Purchase with credit
      </Button>
      <p className="text-sm text-center m-0 pt-1 md:-mb-4">
        Get immediate access - for life!
      </p>
    </div>
  );
};

export default PreviewCTA;
