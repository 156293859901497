import React, { useState } from "react";

// Prop examples here: https://linear.app/cceng/issue/APP-170/add-tooltips
export default function Tooltip({
  childStyle,
  titleStyle,
  children,
  title,
  showTail = false,
  underline = true,
  placement = "bottom",
  theme = "default",
  nowrap = false, // useful if title is very small
}: {
  childStyle?: string;
  titleStyle?: string;
  children?: any;
  title?: string;
  showTail?: boolean;
  underline?: boolean;
  placement?: string;
  theme?: string;
  nowrap?: boolean;
}) {
  const [showTooltip, setShowTooltip] = useState(false);

  const placementStyle = () => {
    switch (placement) {
      case "top":
        return "bottom-[100%] left-[50%] ml-[-50%] justify-center";
      case "right":
        return "md:-top-1 md:left-[105%]";
      case "left":
        return "-top-1 right-[105%] justify-end";
      case "bottom":
      default:
        return "top-[100%] left-[50%] ml-[-50%] justify-center";
    }
  };

  const titleColor = () => {
    const text = `text-base md:text-sm font-semibold p-1 rounded shadow ${
      nowrap && "whitespace-nowrap"
    }`;
    switch (theme) {
      case "dark":
        return `${text} bg-gray-700 text-white`;
      case "light":
        return `${text} bg-white text-gray-700`;
      case "default":
        return `${text} bg-gray-500 text-white`;
      default:
        return `${text} ${theme}`;
    }
  };

  const tail = (
    <div
      className={`hidden md:flex z-10 h-5 w-7 -mr-3 mt-1 rounded-sm ${titleColor()}`}
      style={{ transform: "rotate(45deg)" }}
    />
  );

  if (!title) return children;

  return (
    <span className="relative cursor-pointer">
      <span
        className={`whitespace-nowrap ${
          underline && "border-0 border-b-2 border-dotted border-brand-primary"
        } ${childStyle}`}
        onMouseOver={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
      >
        {children}
      </span>
      <span
        onMouseOver={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
        className={`z-50 absolute flex ${
          !showTooltip && "hidden"
        } ${placementStyle()} ${titleStyle}`}
      >
        {showTail && tail}
        <span className={`z-20 ${titleColor()}`}>{title}</span>
      </span>
    </span>
  );
}
