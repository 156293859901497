import React, { useState } from "react";
import { MdLoop } from "react-icons/md";

const Audio = ({
  className,
  source,
  type = "audio/mp3",
}: {
  className?: string;
  source: string;
  type?: string;
}) => {
  const [isLooping, setIsLooping] = useState(false);

  return (
    <div className="flex flex-row items-center gap-2">
      <audio
        className={className}
        controls
        controlsList="nodownload"
        loop={isLooping}
      >
        <source src={source} type={type} />
        Your browser does not support the audio tag.
      </audio>
      <MdLoop
        className={`rounded-full h-12 w-12 p-2 cursor-pointer hover:bg-gray-200 ${
          isLooping ? "text-blue-600 bg-blue-200" : "text-gray-400"
        }`}
        onClick={() => setIsLooping(!isLooping)}
      />
    </div>
  );
};
export default Audio;
