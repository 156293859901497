import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { brandConfig } from "../helpers/brandConfig";
import ToggleContent from "./ToggleableDropdown";
import ToggleContentLink from "./ToggleContentLink";

const TopNavbar = ({
  profile,
  user,
  navOptions,
}: {
  profile: any;
  user: any;
  navOptions: any;
}) => {
  const dropDown = (
    <div className="w-full bg-white fixed left-0 md:rounded shadow z-50 border-opacity-50 border-0 border-b-4 border-solid border-brand-primary">
      <hr className="-ml-4 my-0" />
      {(profile?.name || user?.email) && (
        <div className="text-sm py-2 px-4 mobile-bottom-border-light">
          <p className="font-semibold m-0">{profile?.name}</p>
          <p className="font-semibold m-0">
            {profile?.creditsCount || 0} credits
          </p>
          <p className="text-gray-500 m-0 pt-2">{user?.email}</p>
        </div>
      )}
      {navOptions.map((option: any) => {
        return (
          <div key={`navOptions_${option.link}`} className="h-14 flex flex-col">
            <hr className="-ml-4 my-0" />
            <ToggleContentLink
              label={option.toggleContentName}
              route={option.link}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <nav
      className="flex md:hidden flex-row justify-between 
        z-50 p-2 w-full h-14 fixed top-0 left-0 side-nav-step 
        bg-white mobile-bottom-border-light"
    >
      <img
        className="h-full w-14"
        alt="brand logo"
        src={brandConfig.favicon()}
      />
      <h1 className="mx-4 my-auto w-full text-xl">
        {brandConfig.brandFullName()}
      </h1>
      <ToggleContent
        toggle={<AiOutlineMenu className="text-4xl h-10" />}
        content={dropDown}
      />
    </nav>
  );
};

export default TopNavbar;
