import React from "react";
import { AiFillFacebook } from "react-icons/ai";
import { FacebookShareButton } from "react-share";

export function Facebook({
  className = "",
  url = "",
  hashtag = "",
}: {
  className?: any;
  url?: any;
  hashtag?: any;
}) {
  return (
    <span
      className={`text-white font-bold bg-brand-primary hover:bg-brand-tertiary rounded bg-blue-500 w-fit px-2 py-1 ${className}`}
    >
      <FacebookShareButton url={url} hashtag={`#${hashtag}`}>
        <AiFillFacebook className="-mb-0.5 mr-1" /> Share
      </FacebookShareButton>
    </span>
  );
}
