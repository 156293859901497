import React from "react";
import {
  calculateContrastingColor,
  calculateDarkerColor,
} from "../helpers/colorManipulation";

// used as default instead of white when given brand has no config
export const ccBrandConfig = {
  primary_rgb_color: "255 219 56",
  secondary_rgb_color: "242 177 130",
  tertiary_rgb_color: "236 223 218",
  quaternary_rgb_color: "53 69 177",
};

export const CustomCssVars = ({ brandConfig }: any) => {
  let cssVars = "";

  const primaryColor =
    brandConfig?.primary_rgb_color || ccBrandConfig.primary_rgb_color;
  const secondaryColor =
    brandConfig?.secondary_rgb_color || ccBrandConfig.secondary_rgb_color;
  const tertiaryColor =
    brandConfig?.tertiary_rgb_color || ccBrandConfig.tertiary_rgb_color;
  const quaternaryColor =
    brandConfig?.quaternary_rgb_color || ccBrandConfig.quaternary_rgb_color;

  cssVars += `--color-primary: ${primaryColor};`;
  cssVars += `--color-secondary: ${secondaryColor};`;
  cssVars += `--color-tertiary: ${tertiaryColor};`;
  cssVars += `--color-quaternary: ${quaternaryColor};`;

  cssVars += `--primary-darker: ${calculateDarkerColor(primaryColor, 5)};`;
  cssVars += `--secondary-darker: ${calculateDarkerColor(secondaryColor, 5)};`;
  cssVars += `--tertiary-darker: ${calculateDarkerColor(tertiaryColor, 5)};`;
  cssVars += `--tertiary-text: ${calculateDarkerColor(tertiaryColor, 50)};`;
  cssVars += `--quaternary-darker: ${calculateDarkerColor(
    quaternaryColor,
    5
  )};`;
  cssVars += `--text-for-primary: ${calculateContrastingColor(primaryColor)};`;
  cssVars += `--text-for-secondary: ${calculateContrastingColor(
    secondaryColor
  )};`;
  cssVars += `--text-for-tertiary: ${calculateContrastingColor(
    tertiaryColor
  )};`;
  cssVars += `--text-for-quaternary: ${calculateContrastingColor(
    quaternaryColor
  )};`;

  return (
    <style id="custom-css-vars">{`:root { 
    ${cssVars}
  }`}</style>
  );
};
