import { data } from "autoprefixer";
import React, { useEffect, useRef, useState } from "react";
import YouTube from "react-youtube";

export function YoutubePlayer({
  children,
  className,
  src,
  title,
  metadata = {},
  setMetadata,
}: any) {
  const urlSearchParams = new URLSearchParams(src.split("?")[1]);
  const videoId = Object.fromEntries(urlSearchParams.entries()).v;

  if (!videoId) {
    return <div>No videoId found</div>;
  }

  const [currentMetadata, setCurrentMetadata] = useState(metadata);
  const player = useRef(null);
  const offset = metadata.playbackOffsetInSeconds || 0;

  useEffect(() => {
    (async () => {
      if (player?.current) {
        // set initial playback offset
        setTimeout(async () => {
          const duration = await (player.current as any)
            .getInternalPlayer()
            .getDuration();

          if (duration < metadata.playbackOffsetInSeconds + 5) {
            // nop, within 5 seconds of end, start from beginning
          } else {
            (player.current as any).getInternalPlayer().seekTo(offset);
          }
        });

        // update the metadata every 5 seconds
        setInterval(async () => {
          const time = await (player.current as any)
            .getInternalPlayer()
            .getCurrentTime();
          setCurrentMetadata({
            ...currentMetadata,
            playbackOffsetInSeconds: time,
          });
        }, 5000);
      }
    })();
  }, [player]);

  useEffect(() => {
    (async () => {
      if (!player) {
        return;
      }
      if (JSON.stringify(currentMetadata) === JSON.stringify(metadata)) {
        return;
      } // bail early
      setMetadata(currentMetadata);
    })();
  }, [currentMetadata, metadata]);

  return (
    <YouTube
      videoId={videoId}
      ref={player}
      className={className}
      iframeClassName={className}
      opts={{
        playerVars: {
          modestbranding: 1,
          rel: 0,
        },
      }}
    />
  );
}
