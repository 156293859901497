import React, { useContext, useEffect } from "react";
import { api } from "../api";
import BackToTopButton from "./buttons/BackToTopButton";
import { brandConfig } from "../helpers/brandConfig";
import ErrorBoundary from "./ErrorBoundary";
import PropTypes from "prop-types";
import SideNavbar from "./SideNavbar";
import { useNavigate, useLocation } from "react-router-dom";
import { ProfileContext } from "../App";
import { UserContext } from "../contexts/UserContext";
import "./layout.css";
import TopNavbar from "./TopNavbar";
import CornerNav from "./CornerNav";

export default function Layout({ children }) {
  // redirect to login form if user is not logged in
  const navigate = useNavigate();
  const location = useLocation();

  const { user, loading } = useContext(UserContext);
  const { profile, setProfile } = useContext(ProfileContext);
  const baseContainerStyle =
    "bg-blue-100 bg-opacity-25 flex flex-col justify-between min-h-screen";

  // redirect user if they're not logged in
  useEffect(() => {
    if (loading == false) {
      (async () => {
        if (!user) {
          if (
            location.pathname.includes("/reset-password") ||
            location.pathname.includes("/welcome") ||
            location.pathname.includes("/enroll")
          ) {
            // do nothing, carry on
          } else {
            navigate("/login-form");
          }
        }
        // remove when we want the catalog to be live for all brands
        if (
          user &&
          (location.pathname === "/discover" ||
            location.pathname.includes("/discover/")) &&
          brandConfig.brandAbbreviation !== "nma"
        ) {
          navigate("/learning-library");
        }
        if (
          user &&
          (location.pathname === "/" ||
            location.pathname.includes("/users/sign_in"))
        ) {
          navigate("/learning-library");
        }
        // load user to populate drop down info section
        // temp - get first initial of name (or email if no name) to use in place of profile image
        const profile = await api.getUserProfile();
        // setUser(currentUser)
        setProfile(profile);
      })();
    }
  }, [loading]);

  if (
    location.pathname.includes("/login-form") ||
    location.pathname.includes("/reset-password") ||
    location.pathname.includes("/welcome")
  ) {
    return (
      <div className={baseContainerStyle}>
        <div className="md:text-right text-[0.6rem] md:text-xs pl-4 md:pr-24 pb-4">
          <div className="md:m-auto w-full md:h-full pt-4 md:ml-8 md:mt-56">
            {children}
          </div>
          <div className="no-wrap md:text-left text-[0.6rem] md:text-xs absolute bottom-4">
            &copy; {new Date().getFullYear()} All rights reserved.
          </div>
        </div>
      </div>
    );
  }

  const navOptions = [
    {
      sideNavName: "My Library",
      toggleContentName: "My Library",
      link: "/learning-library",
    },
    { sideNavName: "My Notes", toggleContentName: "My Notes", link: "/notes" },
    {
      sideNavName: "Settings",
      toggleContentName: "Settings",
      link: "/settings",
    },
    { sideNavName: "Logout", toggleContentName: "Logout", link: "/login-form" },
  ];

  // add discover link after library in mobile list but only if brand NMA
  if (brandConfig.brandAbbreviation === "nma") {
    // disabling discover until we have a use for it
    // navOptions.splice(2, 0, { sideNavName: "Discover", toggleContentName: "Discover", link: "/discover" })
  }

  return (
    <>
      {/* <CornerNav profile={profile} user={user} navOptions={navOptions} /> */}
      <TopNavbar profile={profile} user={user} navOptions={navOptions} />
      <SideNavbar navOptions={navOptions} />

      <div
        className={`${baseContainerStyle} pt-8 pr-4 pb-4 -mb-12 pl-6 md:pl-28 lg:pl-48`}
      >
        <ErrorBoundary>
          <div className="w-full h-full mt-12 md:mt-0">{children}</div>
        </ErrorBoundary>
        <div className="no-wrap md:text-left text-[0.6rem] md:text-xs md:pr-24 pt-12 -pb-16 md:pb-2">
          &copy; {new Date().getFullYear()} All rights reserved.
        </div>
      </div>

      <BackToTopButton
        className="
        fixed bottom-14 right-3 m-2 px-2.5 py-1.5 z-50 rounded-3xl
        text-white bg-brand-tertiary hover:bg-brand-quaternary"
      />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
