import { useEffect } from "react";

// Hook that runs a passed function when a click is made outside of the passed ref
// Hook that runs a passed function when a click is made outside of the passed ref
export default function DetectClickOutside(
  ref: any,
  action: Function,
  searchBarHasFocus: boolean
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      // Check if clicking ref's element or descendent elements
      if (ref.current && !ref.current.contains(event.target)) {
        // Call the action only if the search bar has focus
        if (searchBarHasFocus) {
          action();
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, action, searchBarHasFocus]);
}
