import SearchBar from "./searchBar/searchBar";
import React from "react";
import ErrorBoundary from "./ErrorBoundary";

const PageHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-center mr-0">
      {children}

      <div className="w-full sm:w-1/2 xl:w-1/4">
        <ErrorBoundary>
          <SearchBar />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default PageHeader;
