import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { api } from "./api";

if (localStorage.getItem("cacheEnabled") === "false") {
  console.log("cache is disabled.");
}

class QueryBuilder {
  supabase: SupabaseClient;
  options: any;

  constructor(supabase: SupabaseClient) {
    this.supabase = supabase;
    this.options = {};
  }

  from(table: string) {
    this.options.from = table;
    return this;
  }

  select(query: string) {
    this.options.select = query;
    return this;
  }

  eq(key: string, value: any) {
    this.options.eq = this.options.eq || [];
    this.options.eq = [...this.options.eq, { key, value }];
    return this;
  }

  is(key: string, value: any) {
    this.options.is = this.options.is || [];
    this.options.is = [...this.options.is, { key, value }];
    return this;
  }

  in(key: string, value: any) {
    this.options.in = this.options.in || [];
    this.options.in = [...this.options.in, { key, value }];
    return this;
  }

  order(column: string, direction?: { ascending: boolean }) {
    this.options.order = { column, direction };
    return this;
  }

  limit(limit: number) {
    this.options.limit = limit;
    return this;
  }

  // what supabase uses instead of offset bc they are dumb
  range(from: number, to: number) {
    this.options.range = { from, to };
    return this;
  }

  skipCache() {
    this.options.skipCache = true;
    return this;
  }

  hasValidCache() {
    if (localStorage.getItem("cacheEnabled") === "false") {
      return { hasValidCache: false, data: null };
    }

    const queryCache = JSON.parse(localStorage.getItem("queryCache") || "{}");

    if (this.options.skipCache) {
      return { hasValidCache: false, data: null };
    }

    const possibleValue = queryCache[JSON.stringify(this.options)];
    if (!possibleValue || possibleValue?.expiresAt < Date.now()) {
      delete queryCache[JSON.stringify(this.options)];
      return { hasValidCache: false, data: null };
    }

    return { hasValidCache: true, data: possibleValue.data };
  }

  addToCache(data: any) {
    const queryCache = JSON.parse(localStorage.getItem("queryCache") || "{}");
    queryCache[JSON.stringify(this.options)] = {
      data: data,
      expiresAt: Date.now() + 1000 * 60 * 60, // 1 hour
    };
    localStorage.setItem("queryCache", JSON.stringify(queryCache));
  }

  buildQuery() {
    const { hasValidCache, data } = this.hasValidCache();
    if (hasValidCache) {
      return { inCache: true, data: data, query: null };
    }

    let query = this.supabase
      .from(this.options.from)
      .select(this.options.select);

    if (this.options.eq) {
      this.options.eq.forEach(({ key, value }: { key: string; value: any }) => {
        query = query.eq(key, value);
      });
    }

    if (this.options.is) {
      this.options.is.forEach(({ key, value }: { key: string; value: any }) => {
        query = query.is(key, value);
      });
    }

    if (this.options.in) {
      this.options.in.forEach(({ key, value }: { key: string; value: any }) => {
        query = query.in(key, value);
      });
    }

    if (this.options.order) {
      query = query.order(
        this.options.order.column,
        this.options.order.direction
      );
    }

    if (this.options.limit) {
      query = query.limit(this.options.limit);
    }

    if (this.options.range) {
      query = query.range(this.options.range.from, this.options.range.to);
    }

    return { inCache: false, query: query, data: null };
  }

  async ensureOne() {
    this.options.ensureOne = true;
    const { inCache, data, query } = this.buildQuery();
    if (inCache) {
      return data;
    }

    const response = await query;

    this.addToCache(api.ensureOne(response));
    return api.ensureOne(response);
  }

  async getData() {
    this.options.getData = true;
    const { inCache, data, query } = this.buildQuery();
    if (inCache) {
      return data;
    }
    const response = await query;

    this.addToCache(api.getData(response));
    return api.getData(response);
  }
}

export class XmsSupabase {
  supabase: SupabaseClient;
  options: any = {};
  queryCount = 0;
  queries: any = {};

  constructor(supabaseUrl: string, supabaseKey: string) {
    this.supabase = createClient(supabaseUrl, supabaseKey);
  }

  from(table: string) {
    this.queries[table] = this.queries[table] || 0;
    this.queries[table] += 1;
    this.queryCount += 1;

    if (localStorage.getItem("queryCountingEnabled") === "true") {
      console.log(this.queries, this.queryCount);
    }

    return new QueryBuilder(this.supabase).from(table);
  }

  static resetCache() {
    localStorage.setItem("queryCache", "{}");
  }
}

(window as any).enableQueryCounting = () => {
  localStorage.setItem("queryCountingEnabled", "true");
};

(window as any).disableQueryCounting = () => {
  localStorage.setItem("queryCountingEnabled", "false");
};

(window as any).disableCache = () => {
  localStorage.setItem("cacheEnabled", "false");
};

(window as any).enableCache = () => {
  localStorage.setItem("cacheEnabled", "true");
};

// This `now` should get precompiled so that on every deploy
// the user will get a fresh cache.

// disabling no-undef here - this appears to be an eslint issue
// eslint-disable-next-line no-undef
const lastDeploy = process.env["REACT_APP_GIT_SHA"];
if (localStorage.getItem("lastDeploy") !== lastDeploy) {
  console.log("resetting cache");
  XmsSupabase.resetCache();
  localStorage.setItem("lastDeploy", lastDeploy || "");
}
