export const getEnvVarOrRaise = (envVar: string | undefined): string => {
  if (envVar === undefined) {
    throw new Error(`${envVar} is not defined`);
  }

  // disabling no-undef here - this appears to be an eslint issue
  // eslint-disable-next-line no-undef
  const readValue = process.env["REACT_APP_" + envVar];

  if (readValue === undefined) {
    throw new Error(
      `The environment variable REACT_APP_${envVar} is not defined`
    );
  }

  return readValue;
};
