import { api } from "../../../api";
import { brandConfig } from "../../../helpers/brandConfig";
import Breadcrumbs from "../Breadcrumbs";
import byOrder from "../../../helpers/byOrder";
import {
  courseModalSteps,
  JoyRideConfig,
} from "../../../components/joyrideSteps";
import getUnfinishedLessonPlans from "../../../helpers/progress/getUnfinishedLessonPlans";
import {
  LessonPlan,
  Product,
  ResourceConsumerWithResource,
} from "../learningLibraryTypes";
import ModalHeader from "./ModalHeader";
import ModalRow from "./ModalRow";
import React, { useEffect, useState } from "react";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import ResourceTab from "./ResourceTab";
import ReplaceWhileLoading from "../../../components/loadingIndicator/ReplaceWhileLoading";
import Snackbar from "../../../components/snackbar/snackbar";
import { useLocation, useParams } from "react-router-dom";
import useTabs from "./useTabs";
import { useNestedNavigation } from "../../../contexts/NestedNavigation";
import useTracking from "../../../hooks/useTracking";

const CoursePage = () => {
  let { courseId } = useParams();
  const { state }: { state: any } = useLocation();
  const colors = brandConfig.getCustomColors();

  const [course, setCourse] = useState<Product>();
  const [productResources, setProductResources] = useState<
    Array<ResourceConsumerWithResource>
  >([]);
  const [lessonPlans, setLessonPlans] = useState<Array<LessonPlan>>([]);
  const [runWelcomeTour, setRunWelcomeTour] = useState<boolean>(false);
  const [nextLessonPlan, setNextLessonPlan] = useState<any>(null);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [successMessage, setSuccessMessage] = useState<string>(
    state?.successMessage || ""
  );
  const [userProgresses, setUserProgresses] = useState<any>({});
  const { nestedNavigation } = useNestedNavigation();
  const trackEvent = useTracking();

  useEffect(() => {
    if (!JoyRideConfig.getCourseModalCompleted()) {
      // temp - turned off until overhaul
      // setRunWelcomeTour(true)
    }
  }, []);

  useEffect(() => {
    if (!courseId) return;

    (async () => {
      await api.recordUserProgressOnCourse(courseId);

      const count = await api.getLessonPlanCount(courseId);

      if (count > 30) {
        // for tjb -- req to avoid 414 err ('uri too long')
        const [lessonPlansP1, lessonPlansP2, lessonPlansP3] = await Promise.all(
          [
            api.getLessonPlanAndResources(courseId, 0, 30),
            api.getLessonPlanAndResources(courseId, 31, 60),
            api.getLessonPlanAndResources(courseId, 61, 90),
          ]
        );
        setLessonPlans(
          lessonPlansP1.concat(lessonPlansP2).concat(lessonPlansP3)
        );
      } else {
        setLessonPlans(await api.getLessonPlanAndResources(courseId));
      }

      const [productResources, product, productProgress] = await Promise.all([
        api.getProductResourcesForProduct(courseId),
        api.getProduct(courseId),
        api.getUserLearningProgressByProduct(courseId),
      ]);
      setProductResources(productResources);
      setCourse(product);
      nestedNavigation.setCourse(product);
      setUserProgresses(productProgress);

      trackEvent({
        action: "learning_interaction",
        meta: {
          interaction: "started",
          learningType: "course",
          learningTypeId: courseId,
          learningTypeName: product.name,
        },
      });
    })();
  }, [courseId]);

  // after loading lesson plans, find next up lesson plan of course:
  // pick earliest order but ignore those that are marked as finished
  useEffect(() => {
    if (!lessonPlans) return;
    const unfinishedLessonPlans = getUnfinishedLessonPlans({
      lessonPlans,
      userProgresses,
    });

    setNextLessonPlan(unfinishedLessonPlans?.sort(byOrder)[0]);
  }, [lessonPlans, userProgresses]);

  const renderLessonPlan = () => (
    <section className="h-full overflow-auto md:h-fit md:overflow-hidden">
      {lessonPlans.map((lessonPlan: any) => (
        <ModalRow
          courseId={courseId}
          data-testid={`lesson-plan-${lessonPlan.id}`}
          isFinished={
            userProgresses.lessonPlanProgresses?.find(
              (lessonPlanProgress: any) =>
                lessonPlanProgress.learningTypeId === lessonPlan.id
            )?.isCompleted
          }
          isNext={nextLessonPlan?.id === lessonPlan.id}
          isStarted={
            nextLessonPlan?.id === lessonPlan.id && nextLessonPlan?.isStarted
          }
          key={`lesson plan row ${lessonPlan.id}`}
          lessonPlan={lessonPlan}
          name={lessonPlan.name}
          purchased={true}
          thumbnail={lessonPlan?.thumbnail || course?.thumbnail}
        />
      ))}
    </section>
  );

  const renderResources = () => {
    return <ResourceTab resources={productResources} />;
  };

  const [tabList, content] = useTabs([
    { tabName: "Lesson Plans", content: renderLessonPlan() },
    { tabName: "Resources", content: renderResources() },
  ]);

  const handleJoyrideCallbacks = (data: any) => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      JoyRideConfig.setCourseModalCompleted();
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0) {
        setStepIndex(nextStepIndex);
      }
    }
  };

  return (
    <>
      <Snackbar
        message={successMessage}
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage("")}
      />
      <ReplaceWhileLoading loading={!course}>
        <>
          <Breadcrumbs courseName={course?.name} />

          <ModalHeader
            description={course?.description || ""}
            title={course?.name || ""}
            thumbnail={course?.thumbnail}
          />

          {runWelcomeTour && (
            <ReactJoyride
              callback={handleJoyrideCallbacks}
              continuous
              disableScrolling
              showSkipButton
              stepIndex={stepIndex}
              run={true}
              steps={courseModalSteps}
              styles={{
                options: {
                  arrowColor: `rgba(${colors.tertiary.split(/[, ]+/)})`,
                  primaryColor: `rgba(${colors.primary.split(/[, ]+/)})`,
                },
              }}
            />
          )}
          {tabList}
          <div
            className="block md:hidden gray-scrollbar"
            style={{ height: "calc(100vh - 9.5rem)" }}
          >
            {content}
          </div>
          <div className="hidden md:block gray-scrollbar">{content}</div>
        </>
      </ReplaceWhileLoading>
    </>
  );
};

export default CoursePage;
