import { definitions } from "../../types";
import Collector from "@combined-curiosity/collector-js";

const initializeTracking = (brand: definitions["brands"]) => {
  const params = {
    organizationId: brand.id,
    endpointBaseUrl:
      brand.event_tracking_domain ||
      "https://stg-events-api.combinedcuriosity.io",
  };
  window.cc = window.cc || {};
  window.cc.organization_id = brand.id;
  window.cc.collector_env = "lms";
  Collector.initialize(params);
};

export default initializeTracking;
