// eslint-disable-next-line no-undef
const confetti = require("canvas-confetti");

// display a burst of confetti in the center of the screen
export default function useConfetti() {
  // create invisible top layer in the screen to display confetti on
  const confettiCanvas = document.createElement("canvas");
  confettiCanvas.style.position = "fixed";
  confettiCanvas.style.left = "0px";
  confettiCanvas.style.top = "0px";
  confettiCanvas.style.zIndex = "100";
  confettiCanvas.style.pointerEvents = "none";
  document.body.appendChild(confettiCanvas);
  const textCanvas = document.createElement("canvas");
  textCanvas.style.position = "fixed";
  textCanvas.style.left = "0px";
  textCanvas.style.top = "0px";
  textCanvas.style.zIndex = "90";
  textCanvas.style.pointerEvents = "none";
  document.body.appendChild(textCanvas);

  // display the confetti animation with optional message
  function triggerConfetti(message) {
    confettiCanvas.height = window.innerHeight;
    confettiCanvas.width = window.innerWidth;
    const myConfetti = confetti.create(confettiCanvas, { resize: true });
    myConfetti({
      particleCount: 100,
      spread: 300,
      origin: { x: 0.5, y: 0.5 },
    });

    let ctx = textCanvas.getContext("2d");
    if (message) {
      if (ctx) {
        ctx.canvas.height = window.innerHeight;
        ctx.canvas.width = window.innerWidth;
        // White background behind text
        ctx.fillStyle = "#fff";
        ctx.fillRect(
          textCanvas.width / 2 - 200,
          textCanvas.height / 2 - 20,
          400,
          70
        );
        // Create gradient
        var gradient = ctx.createLinearGradient(
          textCanvas.width / 2 - 100,
          0,
          textCanvas.width / 2 + 100,
          0
        );
        gradient.addColorStop(0, "magenta");
        gradient.addColorStop(0.5, "blue");
        gradient.addColorStop(1.0, "red");
        // Add text
        ctx.font = "30px Verdana";
        ctx.textBaseline = "top";
        ctx.textAlign = "center";
        ctx.fillStyle = gradient;
        ctx.fillText(message, textCanvas.width / 2, textCanvas.height / 2);
      }
    }

    setTimeout(() => {
      myConfetti.reset();
      if (message && ctx)
        ctx.clearRect(0, 0, textCanvas.width, textCanvas.height);
    }, 3000);
  }

  return { triggerConfetti };
}
