import React, { useState } from "react";
import { ModalHeaderType } from "../learningLibraryTypes";
import NavBackButton from "../../../components/buttons/NavBackButton";
import MobileHeader from "../../../components/MobileHeader";

const ModalHeader = ({
  backText,
  navigateBackFn,
  thumbnail,
  description,
  title,
}: ModalHeaderType) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      {/* mobile */}
      <MobileHeader
        showInfo={showInfo}
        setShowInfo={setShowInfo}
        goBack={navigateBackFn}
        title={title}
        thumbnail={thumbnail}
        description={description}
      />

      {/* non-mobile */}
      <header className="hidden md:flex flex-row justify-between lg:max-h-52 w-full">
        <div className="flex flex-col w-full">
          <NavBackButton backText={backText} navigateBackFn={navigateBackFn} />
          <h3 className="pt-2">{title}</h3>
          {thumbnail?.url && (
            <img
              alt={`${title} cover image`}
              src={thumbnail.url}
              className="h-32 rounded flex mx-auto md:hidden"
            />
          )}
          <div className="max-h-32 lg:max-h-52 h-full overflow-y-auto rounded-lg">
            <p className="text font-semibold">{description}</p>
          </div>
        </div>
        {/* show thumb on right in >medium screens */}
        {thumbnail?.url && (
          <img
            alt={`${title} cover image`}
            src={thumbnail?.url}
            className="ml-6 h-32 mt-14 lg:mt-0 lg:h-52 rounded hidden md:flex"
          />
        )}
      </header>
    </>
  );
};

export default ModalHeader;
