const knownKeys = ["autoPause"] as const;

class LocalUserSettings {
  public static get(key: (typeof knownKeys)[number]) {
    try {
      const savedValue = localStorage.getItem(key);
      if (savedValue) {
        return JSON.parse(savedValue || "");
      }
    } catch (error) {
      return undefined;
    }
  }

  public static set(key: (typeof knownKeys)[number], value: any) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  }

  public static resetAll() {
    try {
      knownKeys.forEach((key) => {
        localStorage.removeItem(key);
      });
    } catch (e) {
      console.error(e);
    }
  }
}

export default LocalUserSettings;
