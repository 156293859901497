import React from "react";
import { FaSpinner } from "react-icons/fa";

const LoadingIndicator = ({
  size = "medium",
}: {
  size?: "small" | "medium" | "large";
}) => {
  let width,
    height = 80;
  switch (size) {
    case "small":
      width = height = 40;
      break;
    case "medium":
      width = height = 60;
      break;
    case "large":
      width = height = 80;
      break;
    default:
      width = height = 80;
  }

  return (
    <div className="flex justify-center items-center w-full h-full">
      <FaSpinner className="inline animate-spin" style={{ width, height }} />
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LoadingIndicator;
