import { api } from "../../../api";
import { brandConfig } from "../../../helpers/brandConfig";
import Breadcrumbs from "../Breadcrumbs";
import byOrder from "../../../helpers/byOrder";
import getProgress from "../../../helpers/progress/getProgress";
import getUnfinishedLessons from "../../../helpers/progress/getUnfinishedLessons";
import {
  Lesson,
  LessonPlan,
  Product,
  ResourceConsumerWithResource,
} from "../learningLibraryTypes";
import {
  lessonPlanModalSteps,
  JoyRideConfig,
} from "../../../components/joyrideSteps";
import ModalHeader from "./ModalHeader";
import ModalRow from "./ModalRow";
import React, { useEffect, useState } from "react";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import ReplaceWhileLoading from "../../../components/loadingIndicator/ReplaceWhileLoading";
import ResourceTab from "./ResourceTab";
import { useNavigate, useParams } from "react-router-dom";
import { useNestedNavigation } from "../../../contexts/NestedNavigation";
import useTabs from "./useTabs";
import useTracking from "../../../hooks/useTracking";

const LessonPlanPage = () => {
  let { courseId, lessonPlanId } = useParams();
  const navigate = useNavigate();
  const colors = brandConfig.getCustomColors();

  const [course, setCourse] = useState<Product>();
  const [lessons, setLessons] = useState<Array<Lesson>>([]);
  const [lessonPlan, setLessonPlan] = useState<LessonPlan>();
  const [lessonPlanResources, setLessonPlanResources] = useState<
    Array<ResourceConsumerWithResource>
  >([]);
  const [lessonProgresses, setLessonProgresses] = useState<any>([]);
  const [runWelcomeTour, setRunWelcomeTour] = useState<boolean>(false);
  const [nextLesson, setNextLesson] = useState<any>(null);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [userProgresses, setUserProgresses] = useState<any>([]);
  const { nestedNavigation } = useNestedNavigation();
  const trackEvent = useTracking();

  useEffect(() => {
    if (!JoyRideConfig.getLessonPlanModalCompleted()) {
      // temp - turned off until overhaul
      // setRunWelcomeTour(true)
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!courseId) return;

      const productProgress =
        await api.getUserLearningProgressByProduct(courseId);
      setUserProgresses(productProgress);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!lessonPlanId) return;

      await api.recordUserProgressOnClass(lessonPlanId);
      const lessonPlan: any = await api.findLessonPlan(lessonPlanId);

      trackEvent({
        action: "learning_interaction",
        meta: {
          interaction: "started",
          learningType: "class",
          learningTypeId: lessonPlanId,
          learningTypeName: lessonPlan?.name,
        },
      });

      if (lessonPlan) {
        nestedNavigation.setLessonPlan(lessonPlan);
        const product = await api.findProduct(lessonPlan.product_id);
        setCourse(product);
      }
      setLessonPlan(lessonPlan);
      setLessons(lessonPlan.lessons);
      setLessonPlanResources(
        [...lessonPlan.primaryResources, ...lessonPlan.secondaryResources].sort(
          byOrder
        )
      );
    })();
  }, [lessonPlanId]);

  // after loading lessons, find next up lesson of lesson plan:
  // pick earliest order but ignore those that are marked as finished
  useEffect(() => {
    if (!lessonPlanId || !lessons) return;
    const { lessonProgresses } = userProgresses;
    setLessonProgresses(lessonProgresses);
    const unfinishedLessons = getUnfinishedLessons({
      lessons,
      lessonProgresses,
    });

    setNextLesson(unfinishedLessons?.sort(byOrder)[0]);
  }, [lessons, userProgresses]);

  const getSecondsWatched = (lesson: Lesson) => {
    let seconds = 0;
    const lessonProgress = getProgress({
      entity: lesson,
      progresses: lessonProgresses,
    });
    if (!lessonProgress) return seconds;
    for (const resourceProgress of lessonProgress.resourceProgress) {
      seconds += resourceProgress?.playbackOffsetInSeconds || 0;
    }
    return seconds;
  };

  const renderLessons = () => (
    <section className="h-full overflow-auto" data-testid="lesson-plan-header">
      {lessons.map((lesson: any) => (
        <ModalRow
          courseId={courseId}
          data-te
          isFinished={
            userProgresses.lessonProgresses?.find(
              (lessonProgress: any) =>
                lessonProgress.learningTypeId === lesson.id
            )?.isCompleted
          }
          isNext={nextLesson?.id === lesson.id}
          isStarted={nextLesson?.id === lesson.id && nextLesson?.isStarted}
          key={`lesson ${lesson.id}`}
          lesson={lesson}
          lessonPlan={lessonPlan}
          name={lesson.name}
          purchased={true}
          secondsWatched={getSecondsWatched(lesson)}
          thumbnail={lesson?.thumbnail || lesson?.primaryResources?.[0]}
        />
      ))}
    </section>
  );

  const renderResources = () => {
    return <ResourceTab resources={lessonPlanResources} />;
  };

  const [tabList, content] = useTabs([
    { tabName: "Lessons", content: renderLessons() },
    { tabName: "Resources", content: renderResources() },
  ]);

  const handleJoyrideCallbacks = (data: any) => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      JoyRideConfig.setLessonPlanModalCompleted();
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0) {
        setStepIndex(nextStepIndex);
      }
    }
  };

  return (
    <ReplaceWhileLoading loading={!course}>
      <>
        <Breadcrumbs courseName={course?.name} lessonPlan={lessonPlan} />

        <ModalHeader
          description={lessonPlan?.description || ""}
          title={lessonPlan?.name || ""}
          thumbnail={lessonPlan?.thumbnail}
        />

        {runWelcomeTour && (
          <ReactJoyride
            callback={handleJoyrideCallbacks}
            continuous
            disableScrolling
            showSkipButton
            stepIndex={stepIndex}
            run={true}
            steps={lessonPlanModalSteps}
            styles={{
              options: {
                arrowColor: `rgba(${colors.tertiary.split(/[, ]+/)})`,
                primaryColor: `rgba(${colors.primary.split(/[, ]+/)})`,
              },
            }}
          />
        )}
        {tabList}
        <div
          className="block md:hidden gray-scrollbar"
          style={{ height: "calc(100vh - 9.5rem)" }}
        >
          {content}
        </div>
        <div className="hidden md:block gray-scrollbar">{content}</div>
      </>
    </ReplaceWhileLoading>
  );
};

export default LessonPlanPage;
