import { ModalHeaderType } from "../../learningLibrary/learningLibraryTypes";
import NavBackButton from "../../../components/buttons/NavBackButton";
import renderResource from "../../../components/resources/renderResource";
import React, { useState } from "react";
import MobileHeader from "../../../components/MobileHeader";

const PreviewHeader = ({
  backText,
  navigateBackFn,
  thumbnailResource,
  description,
  title,
  hideInfo,
}: ModalHeaderType) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      {/* mobile */}
      <MobileHeader
        hideInfo={hideInfo}
        showInfo={showInfo}
        setShowInfo={setShowInfo}
        defaultBack="/discover"
        goBack={navigateBackFn}
        title={title}
        thumbnail={thumbnailResource}
        description={description}
      />

      {/* non-mobile */}
      <header className="hidden md:flex flex-row justify-between w-full">
        <div className="flex flex-col w-full">
          <NavBackButton backText={backText} navigateBackFn={navigateBackFn} />

          {/* <h3 className="absolute text-5xl text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">{title}</h3> */}

          {thumbnailResource &&
            renderResource(thumbnailResource as any, {}, () => {}, {
              imageClasses: "sm:h-[50vh] md:h-[70vh]",
              videoClasses: "m-auto mb-4",
            })}
        </div>
      </header>
    </>
  );
};

export default PreviewHeader;
