import React, { useState } from "react";
import SideNavbarLink from "./SideNavbarLink";
import { brandConfig } from "../helpers/brandConfig";
import { Link } from "react-router-dom";
import { useNestedNavigation } from "../contexts/NestedNavigation";

const SideNavbar = ({ navOptions }: { navOptions: any }) => {
  return (
    <nav
      className="
        hidden md:flex lg:w-32 flex-col gap-y-4
        z-10 p-2 h-full w-18 lg:w-40 fixed top-0 left-0
        bg-white ring-1 ring-black ring-opacity-5
        side-nav-step"
    >
      <Link to="/learning-library">
        <img
          className="mx-auto my-6 w-16 lg:my-4 lg:w-32"
          alt="brand logo"
          src={brandConfig.navbarImage()}
        />
      </Link>
      {navOptions.map((option: any, index: string) => {
        return (
          <SideNavbarLink
            key={`sideNavLink_${index}`}
            label={option.sideNavName}
            route={option.link}
          />
        );
      })}
    </nav>
  );
};

export default SideNavbar;
