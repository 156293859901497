import { AiOutlineArrowLeft } from "react-icons/ai";
import Button from "./buttons/Button";
import React from "react";
import { useNavigate } from "react-router-dom";

const GoBackLink = ({
  additionalStyling,
  linkClicked = () => null,
}: {
  additionalStyling?: string;
  linkClicked?: () => void;
}) => {
  let navigate = useNavigate();

  const handleClick = (e: any) => {
    e.preventDefault();
    navigate(-1);
    linkClicked();
  };

  return (
    <Button
      additionalStyling={additionalStyling}
      onClick={handleClick}
      style="white"
    >
      <AiOutlineArrowLeft className="-mb-1 mr-1" />
      Go Back
    </Button>
  );
};

export default GoBackLink;
