import Button from "../../components/buttons/Button";
import React from "react";

// Currently this is hardcoded to work for our only introductory course.
// If we want to add more we'll need to add a prop to this component for the link.
// We will also want to update the special offer set in NextUpModal.tsx

export default function SpecialOffer({
  lessonPlanId,
  link,
}: {
  lessonPlanId: string;
  link: string;
}) {
  return (
    <div className="my-2 p-4 bg-brand-secondary rounded-lg">
      <p className="text-xl text-center whitespace-normal font-bold">
        Congratulations on completing the introductory course!
      </p>
      <Button
        additionalStyling="px-4 py-2 md:text-lg xl:text-xl"
        link={`${link}?utm_source=lx&utm_medium=in_app_offer&utm_campaign=lesson_plan_completion&utm_content=${lessonPlanId}`}
        newTab
        style="primary"
      >
        Get your exclusive discount!
      </Button>
    </div>
  );
}
