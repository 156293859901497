import { Fragment } from "react";
import { apiUrl } from "../../api";
import { brandConfig } from "../../helpers/brandConfig";

class ProductLinkFetcher {
  productLinks: { [id: string]: { url: string; name: string } } = {};

  constructor() {
    this.fetchProductLinks();
  }

  async fetchProductLinks() {
    if (JSON.stringify(this.productLinks) != "{}") {
      return;
    }

    const response = await fetch(apiUrl + "/resources/product-links/");

    if (response.ok) {
      const { links } = await response.json();

      links.forEach(
        (link: {
          id: string;
          url: string;
          metadata: { redirect_url: string };
          name: string;
        }) => {
          this.productLinks[link.id] = {
            url: link.metadata.redirect_url,
            name: link.name,
          };
        }
      );
    }
  }

  replaceProductShortCodes() {
    const productIdLinks = Array.from(
      document.querySelectorAll("[data-product-id]")
    );

    for (let i = 0; i < productIdLinks.length; i++) {
      const link = productIdLinks[i];
      const linkElement = link as unknown as HTMLElement;

      linkElement.setAttribute(
        "href",
        brandConfig.brandServerUrl() +
          "/products/" +
          linkElement.dataset.productId +
          `?lms_path=${window.location.pathname}`
      );
      linkElement.innerHTML =
        this.productLinks[linkElement.dataset.productId || ""]?.name;
      linkElement.removeAttribute("data-product-id");
    }
  }
}

const productLinkFetcher = new ProductLinkFetcher();
export { productLinkFetcher };

document.onload = async () => {
  await productLinkFetcher.replaceProductShortCodes();
};

const replaceShortCodes = (html: string) => {
  const productLinkRegex = /\[\[product-link="([^*\]]+)"\]\]/g;

  return html.replace(productLinkRegex, (_match, productId) => {
    return `<a target="_blank" data-product-id="${productId}" href="#">#</a>`;
  });
};

export default replaceShortCodes;
