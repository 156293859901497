import { api } from "../../api";
import { brandConfig } from "../../helpers/brandConfig";
import byOrder from "../../helpers/byOrder";
import Breadcrumbs from "./Breadcrumbs";
import { definitions } from "../../../types/supabase";
import { JoyRideConfig, lessonPageSteps } from "../../components/joyrideSteps";
import {
  Lesson as LessonType,
  LessonPlan,
  LessonWithThumbnail,
  Product,
  ResourceConsumerWithResource,
} from "./learningLibraryTypes";
import MobileHeader from "../../components/MobileHeader";
import NextUpButton from "./NextUpController";
import Notes from "./Notes";
import React, { useEffect, useState } from "react";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import renderResource from "../../components/resources/renderResource";
import ReplaceWhileLoading from "../../components/loadingIndicator/ReplaceWhileLoading";
import { SecondaryResources } from "./SecondaryResources";
import SpecialOffer from "./SpecialOffer";
import { useNavigate, useParams } from "react-router-dom";
import {
  NestedNavigationView,
  useNestedNavigation,
} from "../../contexts/NestedNavigation";
import useTracking from "../../hooks/useTracking";
import LessonBackButton from "./LessonBackButton";
import { Modal } from "../../components/modal/modal";
import PasswordPrompt from "./passwordPrompt";

// page for a course's class's lesson
// holds a video and the resources and captions from it
export default function Lesson() {
  const [course, setCourse] = useState<Product | undefined>(undefined);
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const [lesson, setLesson] = useState<LessonType | undefined>(undefined);
  const [previousLesson, setPreviousLesson] = useState<LessonType | undefined>(
    undefined
  );
  const [lessonPlan, setLessonPlan] = useState<LessonPlan | undefined>(
    undefined
  );
  const [lessonProgresses, setLessonProgresses] = useState<
    definitions["user_lesson_progress"] | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [nextLesson, setNextLesson] = useState<LessonWithThumbnail | undefined>(
    undefined
  );
  const [nextLessonPlan, setNextLessonPlan] = useState<any>(undefined);
  const [resourceProgressMetadata, setResourceProgressMetadata] = useState<any>(
    {}
  );
  const [runWelcomeTour, setRunWelcomeTour] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<
    definitions["resources"] | null
  >(null);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState<boolean>(false);
  const [showSpecialOffer, setShowSpecialOffer] = useState<boolean>(false);
  const [startJoyride, setStartJoyride] = useState<boolean>(true);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [userName, setUserName] = useState<string>("");
  const { nestedNavigation } = useNestedNavigation();

  const { lessonId, lessonPlanId, courseId } = useParams();
  const navigate = useNavigate();
  const colors = brandConfig.getCustomColors();
  const introCompletionOfferLink = brandConfig.introCompletionOfferLink();
  const trackEvent = useTracking();

  useEffect(() => {
    if (!JoyRideConfig.getLessonPageCompleted()) {
      // temp - turned off until overhaul
      // setRunWelcomeTour(true)
    }
  }, []);

  useEffect(() => {
    (async () => {
      const profile = await api.getUserProfile();
      setUserName(profile?.name);

      if (lessonId == undefined) return;

      const { lessonProgresses } = courseId
        ? await api.getUserLearningProgressByProduct(courseId)
        : [];
      const lessonProgress = lessonProgresses.find(
        (lp: any) => lp.learningTypeId === lessonId
      );
      setLessonProgresses(lessonProgress);
      const resourceProgressMetadata =
        lessonProgress?.resourceProgress.length > 0
          ? {
              resourceConsumerId:
                lessonProgress.resourceProgress[0]?.resourceConsumerId,
              finished: lessonProgress.resourceProgress[0]?.isCompleted,
              playbackOffsetInSeconds:
                lessonProgress.resourceProgress[0]?.playbackOffsetInSeconds,
            }
          : {};
      setResourceProgressMetadata(resourceProgressMetadata);

      const lesson = await api.findLesson(lessonId);
      setLesson(lesson);
      nestedNavigation.setLesson(lesson);

      if (lesson) {
        const lessonPlan = await api.findLessonPlan(
          lesson.lesson_plan_id.toString()
        );

        if (lessonPlan) {
          setLessonPlan(lessonPlan);

          if (lessonPlan.lessons) {
            const next: any = lessonPlan.lessons
              .sort(byOrder)
              .filter((l) => (l?.order || 0) > (lesson?.order || 0))[0];

            setNextLesson(next);

            const previous: any = lessonPlan.lessons
              .sort(byOrder)
              .filter((l) => (l?.order || 0) < (lesson?.order || 0))
              .slice(-1)[0];

            setPreviousLesson(previous);
          }
          const nextPlan = await api.getNextLessonPlan(lessonPlan);
          setNextLessonPlan(nextPlan);

          const product = await api.findProduct(lessonPlan.product_id);
          setCourse(product);
        }
      }

      setLoading(false);
      // for now, we do not reset all progress on lesson load
      // if a component wants to reset its progress, it should do so on mount
    })();
  }, [lessonId]);

  // mark non-video resources as "finished" (since they have been viewed completely)
  // (to make the same completed mark display as lessons that are videos in the LessonPlan modal)
  useEffect(() => {
    if (lesson?.primaryResources) {
      setHasVideo(
        Boolean(
          lesson.primaryResources.find((p) => p.type === "video_resource")
        )
      );
      for (const resource of lesson.primaryResources) {
        if (
          resource?.type != "vimeo_resource" &&
          resource?.type != "youtube_resource" &&
          resource?.type != "video_resource"
        ) {
          setResourceProgressMetadata({
            ...resourceProgressMetadata,
            resourceConsumerId: resource.id,
            finished: true,
            type: resource.type,
          });
        }
      }
    }
  }, [lesson]);

  useEffect(() => {
    if (!lesson) return;

    // if (course?.name.includes("Intro Course") && 3 === lesson?.order) {
    //   setShowPasswordPrompt(true);
    // }
  }, [lesson]);

  useEffect(() => {
    if (!lesson) return;

    // Check if the course is the Intro Course and
    // if the lesson is the last lesson in the course and
    // if the lesson has been marked as finished
    if (
      resourceProgressMetadata?.finished === true &&
      course?.name.includes("Intro Course") &&
      lessonPlan?.lessons?.length === lesson?.order
    ) {
      setShowSpecialOffer(true);
    }

    (async () => {
      await api.recordUserProgressOnLesson(lesson.id, resourceProgressMetadata);
      if (
        resourceProgressMetadata.finished &&
        resourceProgressMetadata.playbackOffsetInSeconds
      ) {
        // nop? Unsure
      } else if (resourceProgressMetadata.finished) {
        const resourceTypeMap: Record<string, "image" | "pdf"> = {
          image_resource: "image",
          pdf_resource: "pdf",
        };
        trackEvent({
          action: "learning_interaction",
          meta: {
            interaction: "viewed",
            resourceType: resourceTypeMap[resourceProgressMetadata?.type],
            resourceConsumerId: resourceProgressMetadata?.resourceConsumerId,
            resourceViewed: resourceProgressMetadata?.finished,
          },
        });
      }
    })();
  }, [lesson, JSON.stringify(resourceProgressMetadata)]);

  const renderPrimaryResource = (
    resource: ResourceConsumerWithResource,
    alignWithVideo?: boolean
  ) => {
    let opts = {
      imageClasses: "w-full",
      alignWithVideo,
    };
    return renderResource(
      resource,
      resourceProgressMetadata,
      setResourceProgressMetadata,
      opts
    );
  };

  let secondaryResources: any = [];
  if (!loading) {
    secondaryResources = [
      ...(lesson?.secondaryResources as any),
      ...(lessonPlan as any).secondaryResources,
    ];
  }

  const handleJoyrideCallbacks = (data: any) => {
    const { action, index, status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      JoyRideConfig.setLessonPageCompleted();
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0) setStepIndex(nextStepIndex);
      if (index === 1) setStepIndex(nextStepIndex);
      if (index === 2) setStepIndex(nextStepIndex);
      if (index === 3) setStepIndex(nextStepIndex);
    }
  };

  if (!lessonId) return <p>This lesson does not exist.</p>;

  return (
    <ReplaceWhileLoading loading={loading}>
      <>
        {runWelcomeTour && (
          <ReactJoyride
            callback={handleJoyrideCallbacks}
            continuous
            run={startJoyride}
            showSkipButton
            steps={lessonPageSteps}
            stepIndex={stepIndex}
            styles={{
              options: {
                arrowColor: `rgba(${colors.tertiary.split(/[, ]+/)})`,
                primaryColor: `rgba(${colors.primary.split(/[, ]+/)})`,
              },
            }}
          />
        )}
        {lesson && !api.userHasAccessToLesson(lesson!) && (
          <p>This lesson has no content.</p>
        )}

        {showPasswordPrompt && (
          <Modal
            key="password-prompt"
            setOpen={setShowPasswordPrompt}
            contentClassName="h-fit md:w-2/3"
          >
            <PasswordPrompt />
          </Modal>
        )}

        {/* instead of breadcrumbs on mobile */}
        <MobileHeader
          hideInfo={true}
          goBack={() =>
            selectedResource
              ? setSelectedResource(null)
              : navigate(
                  `/learning-library/course/${courseId}/lesson-plan/${lessonPlanId}`
                )
          }
          title={lessonPlan?.name}
        />

        <header>
          <div className="flex lg:hidden items-center justify-between">
            <LessonBackButton
              lessonPlanId={lessonPlanId}
              courseId={courseId}
              lessonId={previousLesson?.id}
            />
            <NextUpButton
              courseName={course?.name}
              courseThumb={course?.thumbnail?.url}
              introCompletionOfferLink={introCompletionOfferLink}
              lessonPlan={lessonPlan}
              nextLesson={nextLesson}
              nextLessonPlan={nextLessonPlan}
              setShowSpecialOffer={setShowSpecialOffer}
              userName={userName}
            />
          </div>
          <h3 className="m-0 mb-2">{lesson?.name}</h3>
          <Breadcrumbs courseName={course?.name} lessonPlan={lessonPlan} />
        </header>
        <article className="lg:flex lg:flex-row justify-between gap-8 pt-8">
          <section className="flex flex-col w-full lg:mr-96">
            <div
              dangerouslySetInnerHTML={{ __html: lesson?.description || "" }}
            />
            {lesson?.primaryResources &&
              lesson?.primaryResources.map((resource, index) => {
                return (
                  <div
                    key={`primaryResource_${resource.id}`}
                    className="primary-resource-container mb-12"
                  >
                    {renderPrimaryResource(resource, hasVideo)}
                  </div>
                );
              })}
          </section>

          <section className="z-20 lg:fixed lg:top-0 lg:right-0 lg:h-full lg:w-96 lg:bg-white lg:ring-1 lg:ring-black lg:ring-opacity-5 lg:p-4 lg:mb-4 lg:mt-0 mt-4 whitespace-nowrap flex flex-col">
            <div className="hidden lg:flex items-center justify-between">
              <LessonBackButton
                lessonPlanId={lessonPlanId}
                courseId={courseId}
                lessonId={previousLesson?.id}
              />
              <NextUpButton
                courseName={course?.name}
                courseThumb={course?.thumbnail?.url}
                introCompletionOfferLink={introCompletionOfferLink}
                lessonPlan={lessonPlan}
                nextLesson={nextLesson}
                nextLessonPlan={nextLessonPlan}
                setShowSpecialOffer={setShowSpecialOffer}
                userName={userName}
              />
            </div>
            {showSpecialOffer && (
              <SpecialOffer
                lessonPlanId={lessonPlan?.id || ""}
                link={introCompletionOfferLink}
              />
            )}
            <SecondaryResources
              resources={secondaryResources}
              selectedResource={selectedResource}
              setSelectedResource={setSelectedResource}
            />
            <NestedNavigationView />
            <Notes resourceId={lesson?.primaryResources?.[0]?.resource_id} />
          </section>
        </article>
        {/* Future implementation - Video captions */}
        {/* <h4 className="brand-subheader">
          Follow Along
        </h4>
        <article className="p-4 h-44 overflow-scroll bg-white ring-1 ring-black ring-opacity-5">
          <p className="text-sm leading-6">{loremIpsum}</p>
        </article> */}
      </>
    </ReplaceWhileLoading>
  );
}
