import getProgress from "./getProgress";

const getUnfinishedLessons = ({
  lessons,
  lessonProgresses,
}: {
  lessons: any[];
  lessonProgresses: any[];
}) => {
  const unfinishedLessons = [];
  for (const lesson of lessons) {
    const lessonProgress = getProgress({
      entity: lesson,
      progresses: lessonProgresses,
    });
    if (!lessonProgress || !lessonProgress.isCompleted) {
      // if progress isn't finished but still has a saved playback offset, mark as started
      unfinishedLessons.push({
        id: lesson.id,
        isStarted: lessonProgress?.isStarted,
        order: lesson.order,
      });
    }
  }

  return unfinishedLessons;
};

export default getUnfinishedLessons;
