import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../api";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdDelete, MdEdit } from "react-icons/md";
import { NoteContext } from "../../App";
import Button from "../../components/buttons/Button";
import Snackbar from "../../components/snackbar/snackbar";
import { formatHHMMSS } from "../../helpers/formatTimestamp";

export default function Notes({ resourceId = "" }: { resourceId?: string }) {
  const { courseId, lessonPlanId, lessonId } = useParams();
  const { notes, setNotes } = useContext(NoteContext);
  const [showNoteResp, setShowNoteResp] = useState<any>(null);
  const [status, setStatus] = useState<any>({});
  const [showAll, setShowAll] = useState("");

  async function refreshNotes() {
    if (!resourceId) {
      // avoid console warning on initial lesson page load
      return;
    }
    const list = await api.getNotesForResource(resourceId);
    setNotes(list || []);
  }
  useEffect(() => {
    (async () => {
      await refreshNotes();
    })();
  }, [resourceId]);

  useEffect(() => {
    if (status?.type === "update") {
      document.getElementById("note_input")?.focus();
    }
  }, [status]);

  // if a note was just created, set it to update and focus on it
  useEffect(() => {
    const newest: any = [...notes].sort((a: any, b: any) =>
      (b?.created_at || 0) > (a?.created_at || 0) ? 1 : -1
    )[0];
    const created: any = new Date(newest?.created_at);

    if ((Date.now() - created) / 60000 < 0.01) {
      setStatus({
        type: "update",
        id: newest.id,
        notes: newest.notes,
        name: newest.name,
      });
    }
  }, [notes]);

  async function onCreateNote() {
    try {
      const player: any = document.getElementById(
        `video_${resourceId}_html5_api`
      );
      if (player) player.pause();
      const timestamp = player?.currentTime || 0;
      const resp = await api.createNote(
        resourceId,
        timestamp,
        courseId,
        lessonPlanId,
        lessonId
      );
      setShowNoteResp(resp);
      await refreshNotes();
    } catch (e) {
      console.error(e);
    }
  }

  async function onUpdateNote(id = "") {
    try {
      await api.updateNote(id, status?.name, status?.notes);
      setStatus({});
      await refreshNotes();
    } catch (e) {
      console.error(e);
    }
  }

  async function onDeleteNote(id = "") {
    try {
      await api.deleteNote(id);
      setStatus({});
      await refreshNotes();
    } catch (e) {
      console.error(e);
    }
  }

  const actionButtons = (r: any) => {
    if (status?.type === "update" && status?.id === r.id) {
      return (
        <div className="flex flex-row gap-x-2">
          <Button
            style="text"
            additionalStyling="text-xs m-0 p-0"
            onClick={() => onUpdateNote(r.id)}
          >
            Update
          </Button>
          <Button
            style="text"
            additionalStyling="text-gray-500 text-xs m-0 p-0"
            onClick={() => setStatus({})}
          >
            Cancel
          </Button>
        </div>
      );
    }

    if (status?.type === "delete" && status?.id === r.id) {
      return (
        <div className="flex flex-row gap-x-2">
          <Button
            style="text"
            additionalStyling="text-xs m-0 p-0"
            onClick={() => onDeleteNote(r.id)}
          >
            Delete
          </Button>
          <Button
            style="text"
            additionalStyling="text-gray-500 text-xs m-0 p-0"
            onClick={() => setStatus({})}
          >
            Cancel
          </Button>
        </div>
      );
    }

    return (
      <>
        <MdEdit
          className="text-brand-primary hover:text-brand-tertiary"
          onClick={() =>
            setStatus({
              type: "update",
              id: r.id,
              notes: r.notes,
              name: r.name,
            })
          }
        />
        <MdDelete
          className="text-brand-primary hover:text-brand-tertiary"
          onClick={() => setStatus({ type: "delete", id: r.id })}
        />
      </>
    );
  };

  const body = (r: any) => {
    let showing = showAll === r.id;

    if (status?.type === "update" && status?.id === r.id) {
      return (
        <input
          id="note_input"
          placeholder="Type note here"
          className="p-1"
          onChange={(e) => setStatus({ ...status, notes: e.target.value })}
          defaultValue={r.notes}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") onUpdateNote(r.id);
            if (e.key === "Escape") setStatus({});
          }}
        />
      );
    }

    return (
      <div className="flex flex-row relative whitespace-normal cursor-pointer">
        <span
          onClick={() =>
            setStatus({
              type: "update",
              id: r.id,
              notes: r.notes,
              name: r.name,
            })
          }
          className={`${!r?.notes?.length && "text-gray-400"} ${
            r?.notes?.length > 160 && "mb-5"
          } ${!showing && "max-h-24 overflow-hidden"}`}
        >
          {r?.notes?.length > 0 ? r.notes : "Click to edit"}
        </span>
        <span
          onClick={() => setShowAll(showing ? "" : r.id)}
          className={`${
            r?.notes?.length > 160 ? "absolute" : "hidden"
          } bottom-0 right-0 bg-white pl-1 pt-1 text-xs font-semibold text-brand-primary hover:text-brand-tertiary`}
        >
          Show {showing ? "Less" : "More"}
        </span>
      </div>
    );
  };

  const row = (r: any, last: Boolean) => {
    return (
      <div className="flex flex-col text-sm gap-y-2">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col font-semibold whitespace-normal">
            {r?.timestamp > 1 && formatHHMMSS(r?.timestamp)}
          </div>
          <div className="flex flex-row pl-2 gap-x-2 text-xl cursor-pointer">
            {actionButtons(r)}
          </div>
        </div>
        {body(r)}
        {!last && <hr className="m-0" />}
      </div>
    );
  };

  return (
    <>
      <Snackbar
        message={showNoteResp}
        open={showNoteResp}
        onClose={() => setShowNoteResp(null)}
      />
      <div className="max-h-64 lg:max-h-[45vh] flex flex-col text-left bg-white ring-1 ring-black ring-opacity-5 lg:ring-opacity-0 p-4 mb-4 mt-4 gap-y-4 md:mt-0 resource-block">
        <div className="flex flex-row justify-between h-4">
          <h2 className="text-sm">My Notes</h2>
          <AiOutlinePlusCircle
            className="text-2xl text-brand-primary hover:text-green-500 cursor-pointer -mt-1"
            onClick={onCreateNote}
          />
        </div>
        {notes.length > 0 ? (
          <div className="ring-1 ring-black ring-opacity-5 p-2 flex flex-col gap-y-2 overflow-x-hidden overflow-y-scroll gray-scrollbar">
            {notes.map((b: any, i: number) => (
              <span key={`note_${i}`}>{row(b, notes.length === i + 1)}</span>
            ))}
          </div>
        ) : (
          <div
            className="ring-1 ring-black ring-opacity-5 p-2 cursor-pointer"
            onClick={onCreateNote}
          >
            <span className="text-gray-400 text-sm">
              Click here to create a note
            </span>
          </div>
        )}
      </div>
    </>
  );
}
