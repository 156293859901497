import { api } from "../../api";
import Button from "../buttons/Button";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5"; // this has to load this way for the web worker to load properly
import React, { useEffect, useState } from "react";
import { ResourceConsumerWithResource } from "../../pages/learningLibrary/learningLibraryTypes";
import useTracking from "../../hooks/useTracking";

const PdfViewer = ({
  resource,
}: {
  resource: ResourceConsumerWithResource;
}) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const trackEvent = useTracking();

  useEffect(() => {
    (async () => {
      if (resource.consumer_type === "lesson") {
        await api.recordUserProgressOnLesson(resource.consumer_id, {
          resourceConsumerId: resource.id,
          viewed: true,
        });
        trackEvent({
          action: "learning_interaction",
          meta: {
            interaction: "viewed",
            resourceConsumerId: resource.id,
            resourceType: "pdf",
            resourceViewed: true,
          },
        });
      }
    })();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <div className="relative flex justify-center">
      <Document
        file={resource.url}
        onLoadSuccess={onDocumentLoadSuccess}
        className="flex justify-center"
      >
        <Page
          pageNumber={pageNumber}
          width={600}
          onRenderSuccess={() => setIsLoaded(true)}
        />
      </Document>

      {isLoaded && (
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-white rounded-xl h-11">
          <Button
            additionalStyling="bg-white w-8 mr-3 h-11 rounded border-none hover:bg-slate-100"
            ariaLabel="Previous page"
            onClick={() => setPageNumber(pageNumber - 1)}
            startDisabled={pageNumber <= 1}
          >
            ‹
          </Button>
          <span>{`${pageNumber} of ${numPages}`}</span>
          <Button
            additionalStyling="bg-white w-8 ml-3 h-11 rounded border-none hover:bg-slate-100"
            ariaLabel="Next page"
            onClick={() => setPageNumber(pageNumber + 1)}
            startDisabled={pageNumber >= numPages}
          >
            ›
          </Button>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
