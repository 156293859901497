import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { api } from "../../api";

import Dashboard from "../dashboard";
import { brandConfig } from "../../helpers/brandConfig";
import ReplaceWhileLoading from "../../components/loadingIndicator/ReplaceWhileLoading";
import OfferCard from "../../components/offerCard/OfferCard";
import Tooltip from "../../components/tooltip";

export default function LearningLibrary({ children }: { children?: any }) {
  const navigate = useNavigate();
  const currentPage = window.location.pathname;
  let { courseId, lessonPlanId, lessonId } = useParams();

  const [lastPage, setLastPage] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [displayOffers, setDisplayOffers] = useState([]);

  useEffect(() => {
    (async () => {
      const apiDisplayOffers = await api.getDisplayOffers();
      setDisplayOffers(apiDisplayOffers);

      setOpen(Boolean(courseId));
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    // check for user using browser backwards button or clicking off modal
    // ex: /piaf/learning-library/course/1 -> /piaf/learning-library
    if (
      lastPage.split("/").length > currentPage.split("/").length &&
      currentPage.split("/").length === 2
    ) {
      setOpen(false);
    }
    setLastPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (loading || open) return;
    setOpen(Boolean(courseId));
  }, [courseId, lessonPlanId, lessonId]);

  if (!brandConfig.brandAbbreviation) return <Dashboard />;

  const productRoute = (product: any, offerId: string) => {
    return `/discover/course/${product.id}?offerId=${offerId}`;
  };

  return (
    <>
      <h3 className="learning-step">
        Discover
        <Tooltip
          title="Here you can search through our course catalog to discover your next art project!"
          placement="right"
          titleStyle="w-64"
          childStyle="m-2"
          underline={false}
          showTail={true}
        >
          <AiOutlineInfoCircle className="-mb-0.5" />
        </Tooltip>
      </h3>

      {children}

      <ReplaceWhileLoading loading={loading}>
        <>
          <article className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            {displayOffers.map((o: any, index) => (
              <OfferCard
                key={`courseSnippet_${o.id}`}
                containerClassName={`cursor-pointer product-0 relative overflow-hidden rounded-2xl h-56 text-white`}
                offer={o}
                onClick={() =>
                  navigate(
                    productRoute(
                      o.metadata.primary_products[0],
                      o.metadata.offer_id
                    )
                  )
                }
              />
            ))}
          </article>
        </>
      </ReplaceWhileLoading>
    </>
  );
}
