import getFinishedLessons from "./getFinishedLessons";
import getProgress from "./getProgress";
import getUnfinishedLessons from "./getUnfinishedLessons";

const getUnfinishedLessonPlans = ({
  lessonPlans,
  userProgresses,
}: {
  lessonPlans: any[];
  userProgresses: any;
}) => {
  const unfinishedLessonPlans = [];
  const { lessonPlanProgresses, lessonProgresses } = userProgresses;
  for (const lessonPlan of lessonPlans) {
    const unfinishedLessons = getUnfinishedLessons({
      lessons: lessonPlan.lessons,
      lessonProgresses,
    });
    const finishedLessons = getFinishedLessons({
      lessons: lessonPlan.lessons,
      lessonProgresses,
    });

    const lessonPlanProgress = getProgress({
      entity: lessonPlan,
      progresses: lessonPlanProgresses,
    });
    if (
      unfinishedLessons.length !== 0 &&
      (!lessonPlanProgress || !lessonPlanProgress.isCompleted)
    ) {
      // if one of the lessons is started, mark the lesson plan as started
      unfinishedLessonPlans.push({
        id: lessonPlan.id,
        isStarted:
          unfinishedLessons.some((lesson: any) => lesson.isStarted) ||
          finishedLessons.length !== 0,
        order: lessonPlan.order,
        unfinishedLessons,
      });
    }
  }

  return unfinishedLessonPlans;
};

export default getUnfinishedLessonPlans;
