import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { definitions } from "../../types";
import { api } from "../api";
import useCollapseToggle from "../components/useCollapseToggle";
import useTracking from "../hooks/useTracking";

export class NestedNavigation {
  offer: definitions["offers"] | null = null;
  course: definitions["products"] | null = null;
  lesson: definitions["lessons"] | null = null;
  lessonPlan: definitions["lesson_plans"] | null = null;
  private rerenderFunctions: Array<() => void> = [];

  lessonPlans: Array<definitions["lesson_plans"]> = [];
  lessons: Array<definitions["lessons"]> = [];

  async fetchData() {
    if (this.lesson?.id) {
      const lessonPlan = await api.findLessonPlan(this.lesson.lesson_plan_id);

      if (lessonPlan) {
        this.lessonPlan = lessonPlan;
        this.lessonPlans = [lessonPlan];

        const course = await api.findProduct(lessonPlan.product_id);
        if (course) {
          this.course = course;
        }
      }
    }
  }

  setOffer(offer: definitions["offers"] | null) {
    this.offer = offer;
    this.course = null;
    this.lessonPlan = null;
    this.lesson = null;
    this.rerender();
  }

  setCourse(course: definitions["products"]) {
    this.course = course;
    this.lessonPlan = null;
    this.lesson = null;
    this.rerender();
  }

  setLessonPlan(lessonPlan: definitions["lesson_plans"]) {
    this.lessonPlan = lessonPlan;
    this.lesson = null;
    this.rerender();
  }

  setLesson(lesson: definitions["lessons"]) {
    this.lesson = lesson;
    this.rerender();
  }

  addRerenderFunction(rerender?: () => void) {
    if (rerender) {
      this.rerenderFunctions = [rerender];
    }
  }

  rerender() {
    this.rerenderFunctions.forEach((rerender) => rerender());
  }
}

const NestedNavigationContext = React.createContext<{
  nestedNavigation: NestedNavigation;
}>({
  nestedNavigation: new NestedNavigation(),
});

export const useNestedNavigation = (rerender?: () => void) => {
  const context = useContext(NestedNavigationContext);

  if (!context) {
    throw new Error(
      "useNestedNavigation must be used within a NestedNavigationProvider"
    );
  }

  context.nestedNavigation.addRerenderFunction(async () => {
    if (rerender) {
      await context.nestedNavigation.fetchData();
      rerender();
    }
  });

  return context;
};

export const NestedNavigationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [nestedNavigation, setNestedNavigation] = useState<NestedNavigation>(
    new NestedNavigation()
  );

  return (
    <NestedNavigationContext.Provider value={{ nestedNavigation }}>
      {children}
    </NestedNavigationContext.Provider>
  );
};

export const NestedNavigationView = () => {
  const [rerenderTrigger, setRerenderTrigger] = useState(0);
  const { nestedNavigation } = useNestedNavigation(() =>
    setRerenderTrigger((r) => r + 1)
  );
  const navigate = useNavigate();
  const { collapseToggle, collapsibleContent } = useCollapseToggle();
  const trackEvent = useTracking();

  useEffect(() => {
    setTimeout(() => {
      nestedNavigation.fetchData();
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col justify-center text-left bg-white ring-1 ring-black ring-opacity-5 lg:ring-opacity-0 px-4 mt-4 md:mt-0 resource-block">
      <>
        {nestedNavigation.lessonPlans.map((lessonPlan: any) => (
          <div key={lessonPlan.id}>
            {collapseToggle(<div className="font-bold text-sm">Lessons</div>)}

            {collapsibleContent(
              <div className="overflow-y-auto max-h-[40vh]">
                {lessonPlan.lessons.map((lesson: any) => {
                  const isActive = lesson.id == nestedNavigation.lesson?.id;
                  return (
                    <div
                      className={`p-2 m-2  rounded overflow-hidden text-ellipsis cursor-pointer ${
                        isActive
                          ? "bg-gray-300 hover:bg-gray-400"
                          : "bg-gray-50 hover:bg-gray-200"
                      }`}
                      key={lesson.id}
                      onClick={async () => {
                        await api.recordUserProgressOnLesson(lesson.id);
                        trackEvent({
                          action: "learning_interaction",
                          meta: {
                            interaction: "started",
                            learningType: "lesson",
                            learningTypeId: lesson.id,
                            learningTypeName: lesson.name,
                          },
                        });
                        navigate(
                          `/learning-library/course/${nestedNavigation.course?.id}/lesson-plan/${nestedNavigation.lessonPlan?.id}/lesson/${lesson.id}`
                        );
                      }}
                    >
                      {lesson.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </>
    </div>
  );
};
