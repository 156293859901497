import React from "react";
import LoadingIndicator from "./loadingIndicator";

const ReplaceWhileLoading = ({
  children,
  loading,
}: {
  children: React.ReactElement;
  loading: boolean;
}) => {
  return (
    <>
      {loading && <LoadingIndicator />}
      <div
        className={
          loading ? "opacity-0" : "opacity-100 transition-opacity duration-500"
        }
      >
        {children}
      </div>
    </>
  );
};

export default ReplaceWhileLoading;
