import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { api } from "./api";
import { UserContext } from "./contexts/UserContext";
import useTracking from "./hooks/useTracking";

const LocationReporter = () => {
  const location = useLocation();
  const trackEvent = useTracking();
  const { user, loading } = useContext(UserContext);

  const [lastLocationAndTimestamp, setLastLocationAndTimestamp] = useState<any>(
    {}
  );

  const locationsDifferent = (location: any, lastLocation: any) => {
    return JSON.stringify(location) !== JSON.stringify(lastLocation);
  };

  const moreThanOneSecondElapsed = (
    originalTime: number,
    currentTime: number
  ) => {
    return currentTime - originalTime > 1000;
  };

  // For some reason, useLocation fires twice on initial render. The location objects are
  // the same except for the `key` properties. This code is here to not double-report
  // first page loads. If we can figure out why it is double reporting, we can remove this logic.
  const shouldTrackRouteChange = (
    location: any,
    lastLocationAndTimestamp: any,
    currentTime: Date,
    lastActiveTime: string
  ) => {
    const mutableLocation = { ...location };
    delete mutableLocation.key;

    const mutableLastLocation = { ...lastLocationAndTimestamp.location };
    delete mutableLastLocation.key;

    if (!lastActiveTime) {
      // first time, record the visit
      return true;
    } else if (locationsDifferent(mutableLocation, mutableLastLocation)) {
      return true;
    } else if (
      moreThanOneSecondElapsed(
        lastLocationAndTimestamp.timestamp,
        currentTime.getTime()
      )
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (loading === false) {
      (async () => {
        const currentTime = new Date();
        const lastActiveLocation = localStorage.getItem("lastActiveLocation");
        const lastActiveTime = JSON.parse(
          localStorage.getItem("lastActiveTime") || "0"
        );

        if (
          location.pathname != "/login-form" &&
          lastActiveLocation === "/login-form" &&
          lastLocationAndTimestamp.location === undefined
        ) {
          // leaving for now in order to get feedback on what the intention was that may still
          // need to be kept in consideration
          // trackEvent({ action: "login", userId: user?.id, email: user?.email });
        }

        if (
          shouldTrackRouteChange(
            location,
            lastLocationAndTimestamp,
            currentTime,
            lastActiveTime
          )
        ) {
          trackEvent({ action: "pageview" });
        }

        setLastLocationAndTimestamp({
          location: location,
          timestamp: new Date().getTime(),
        });

        if (await api.getUser()) {
          localStorage.setItem(
            "lastActiveTime",
            currentTime.getTime().toString()
          );
          localStorage.setItem("lastActiveLocation", location.pathname);
        }
      })();
    }
  }, [location, loading]);

  return null;
};

export default LocationReporter;
