import Button from "../../components/buttons/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Intro = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const enroll = () => {
    navigate(`/enroll?email=${email}`);
  };

  return (
    <div className="flex justify-center items-center ">
      <div className="bg-white rounded-lg shadow p-16 mt-8 lg:mt-32 text-center">
        <div className="text-xl font-bold">{`You're almost ready to receive your free introductory course! `}</div>
        <div className="text-lg">{`Enter your email below and we'll get you started 😎`}</div>

        <input
          id="email"
          type="email"
          placeholder="Email"
          className="p-2 mt-8"
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") enroll();
          }}
          value={email}
        />

        <div>
          <Button
            additionalStyling="p-2 mt-4 px-4"
            onClick={enroll}
            startDisabled={!email}
            style="primary"
          >
            Get my free course!
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
