import { api, supabase } from "../api";
import Button from "../components/buttons/Button";
import { brandConfig } from "../helpers/brandConfig";
import PasswordField from "../components/PasswordField";
import React, { useEffect, useState } from "react";
import Snackbar from "../components/snackbar/snackbar";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [placeholder, setPlaceholder] = useState("New Password");
  const [buttonText, setButtonText] = useState("Save New Password");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const email = searchParams.get("email") || "";
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.auth.getUser();
      if (data.user) api.logout();

      if (location.pathname.includes("/welcome")) {
        setPlaceholder("Create Password");
        setButtonText("Set Password");
      }
    })();
  }, []);

  function resetForm() {
    setErrorMessage("");
    setSuccessMessage("");
    setPassword("");
  }

  const sendNewPassword = async (e: any) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("passwords must match");
      return;
    }
    const response = await api.updatePassword(token, password);
    if (response.ok) {
      const responseData = await response.json();
      setSuccessMessage(responseData.message);
      resetForm();
      navigate("/login-form", {
        state: { successMessage: responseData.message, email },
      });
    } else {
      const responseData = await response.json();
      setErrorMessage(responseData.message);
      setSuccessMessage("");
    }
  };

  return (
    <div className="bg-white shadow rounded-xl p-12 pb-4 w-3/4 md:w-1/2 lg:w-2/5 2xl:w-1/4 m-auto">
      <Snackbar
        message={successMessage}
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage("")}
      />
      <Snackbar
        message={errorMessage}
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
      />
      <a
        href={brandConfig.brandHomepageLink()}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="flex flex-col w-3/4 pb-6 m-auto cursor-pointer"
          src={brandConfig.navbarImage()}
          alt="brand logo"
        />
      </a>
      <form className="flex flex-col" onSubmit={sendNewPassword}>
        <PasswordField
          handleInfoChange={(e: any) => setPassword(e.target.value)}
          placeholder={placeholder}
          value={password}
          data-testid="password-input"
        />
        <PasswordField
          handleInfoChange={(e: any) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          value={confirmPassword}
          data-testid="password-confirmation-input"
        />
        <Button
          additionalStyling="p-2 mt-4"
          onClick={sendNewPassword}
          data-testid="set-password"
          style="primary"
          type="submit"
          buttonId="set-password-button"
        >
          {buttonText}
        </Button>
      </form>
    </div>
  );
}
