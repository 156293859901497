import React, { useEffect, useState } from "react";
import getPageIcon from "../helpers/getPageIcon";
import StyledLink from "./StyledLink";

const defaultLinkStyle = `flex flex-col lg:flex-row justify-center items-center
lg:justify-start p-2 no-underline text-brand-primary 
hover:text-brand-tertiary text-xs lg:text-base 
lg:font-semibold rounded-tr-md rounded-br-md`;

const SideNavbarLink = ({ label, route }: { label: string; route: string }) => {
  const [styling, setStyling] = useState<string>(defaultLinkStyle);
  const [selectedIcon, setSelectedIcon] = useState<JSX.Element>();
  const sideNavIcon = "w-8 h-8 lg:w-6 lg:h-6 mx-1";

  useEffect(() => {
    if (label === "Logout") {
      setStyling(
        `${defaultLinkStyle} fixed bottom-2 w-12 lg:w-32 my-auto logout-step`
      );
    } else {
      setStyling(defaultLinkStyle);
    }
  }, [location.pathname]);

  useEffect(() => {
    setSelectedIcon(getPageIcon(label, sideNavIcon));
  }, []);

  return (
    <StyledLink route={route} styling={styling}>
      {selectedIcon}
      {label}
    </StyledLink>
  );
};

export default SideNavbarLink;
