import React, { useEffect, useState } from "react";
import { definitions } from "../../../../types/supabase";
import renderResource from "../../../components/resources/renderResource";
import { RenderResourceOpts } from "../../../components/resources/resourceTypes";
import ResourceTypeIcon from "../../../components/ResourceTypeIcon";
import { ResourceConsumerWithResource } from "../learningLibraryTypes";

const ResourceTab = ({
  resources,
  opts = {},
  onResourceClick = () => {},
}: {
  resources: Array<ResourceConsumerWithResource>;
  opts?: RenderResourceOpts;
  onResourceClick?: () => void;
}) => {
  const [selectedResource, setSelectedResource] = useState<
    definitions["resources"] | null
  >(null);

  useEffect(() => {
    if (
      !document?.getElementById("selectedResource")?.childNodes.length &&
      selectedResource?.url
    ) {
      // if renderResource did not render anything, the resource is an external link
      window.open(selectedResource.url, "_blank");
    } else {
      // scroll the resource into view once it loads in a second
      setTimeout(() => {
        document
          ?.getElementById("selectedResource")
          ?.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }, [selectedResource]);

  const handleResourceClick = (resource: definitions["resources"]) => {
    setSelectedResource(resource);
    onResourceClick();
  };

  return (
    <div className="h-full overflow-auto md:h-fit md:overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-4 w-full">
        {resources.map((resource) => (
          <div
            key={resource.id}
            onClick={() => handleResourceClick(resource)}
            className="bg-white p-2 md:p-4 rounded md:shadow-md md:mb-4 cursor-pointer hover:shadow-lg 
              mobile-bottom-border-light"
          >
            <>
              <ResourceTypeIcon type={resource.type} /> <b>{resource.name}</b>
              <br />
              <div className="hidden md:flex">{resource.description}</div>
              {resource.downloadable ? (
                <a
                  href={resource.url}
                  key={`${resource.id}_download`}
                  download={true}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              ) : null}
            </>
          </div>
        ))}
      </div>

      {selectedResource && (
        <div id="selectedResource">
          {renderResource(selectedResource as any, {}, () => {}, {
            imageClasses: "sm:h-[50vh] md:h-[70vh]",
            videoClasses: "md:w-[50vw] m-auto",
            ...opts,
          })}
        </div>
      )}
    </div>
  );
};

export default ResourceTab;
