import getProgress from "./getProgress";

const getFinishedLessons = ({
  lessons,
  lessonProgresses,
}: {
  lessons: any[];
  lessonProgresses: any[];
}) => {
  const finishedLessons = [];
  for (const lesson of lessons) {
    const lessonProgress = getProgress({
      entity: lesson,
      progresses: lessonProgresses,
    });
    if (lessonProgress && lessonProgress.isCompleted) {
      finishedLessons.push({
        id: lesson.id,
        order: lesson.order,
      });
    }
  }

  return finishedLessons;
};

export default getFinishedLessons;
