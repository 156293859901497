import React, { useContext, useEffect, useState } from "react";
import { MdOndemandVideo } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";

import { NoteContext } from "../../App";
import { Card } from "../../components/card/card";
import ReplaceWhileLoading from "../../components/loadingIndicator/ReplaceWhileLoading";
import { formatHHMMSS } from "../../helpers/formatTimestamp";
import {
  Lesson,
  LessonPlan,
  Product,
} from "../learningLibrary/learningLibraryTypes";

export default function Notes() {
  const navigate = useNavigate();
  const { notes, setNotes } = useContext(NoteContext);
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState("");

  const [products, setProducts] = React.useState<Array<Product>>([]);
  const [lessonPlans, setLessonPlans] = useState<Array<LessonPlan>>([]);
  const [lessons, setLessons] = useState<Array<Lesson>>([]);

  const [courseId, setCourseId] = useState("");
  const [lessonPlanId, setLessonPlanId] = useState("");
  const [lessonId, setLessonId] = useState("");
  const [displayedNotes, setDisplayedNotes] = useState([]);

  async function refreshNotes() {
    const list = await api.getNotes();
    setNotes(list || []);
  }

  useEffect(() => {
    (async () => {
      try {
        const products = await api.getProducts();
        const currentUser = await api.getUser();

        if (currentUser?.xmsRole == "admin") {
          setProducts(products);
        } else {
          const purchases = await api.getPurchasedProducts();
          const purchasedIds = purchases?.map((p: any) => p.product_id);
          const purchasedProducts = products?.filter((p: any) =>
            purchasedIds.some((c: any) => c === p.id)
          );
          setProducts(purchasedProducts);
        }

        await refreshNotes();
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  // display notes according to filters
  useEffect(() => {
    setLoading(true);
    setDisplayedNotes(
      notes.filter((b: any) => {
        if (lessonId) {
          if (lessonId === b.lesson_id) return b;
        } else if (lessonPlanId) {
          if (lessonPlanId === b.lesson_plan_id) return b;
        } else if (courseId) {
          if (courseId === b.product_id) return b;
        } else {
          return b;
        }
      })
    );
    setLoading(false);
  }, [notes, courseId, lessonPlanId, lessonId]);

  useEffect(() => {
    if (courseId) {
      (async () => {
        try {
          setLoading(true);
          const list = await api.getLessonPlansForProduct(courseId);
          setLessonPlans(list);
          setLoading(false);
        } catch (e) {
          console.error(e);
        }
      })();
    } else {
      setLessonPlans([]);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseId && lessonPlanId) {
      (async () => {
        try {
          setLoading(true);
          const lessonPlan = await api.findLessonPlan(lessonPlanId);
          setLessons(lessonPlan?.lessons || []);
          setLoading(false);
        } catch (e) {
          console.error(e);
        }
      })();
    } else {
      setLessons([]);
    }
  }, [courseId, lessonPlanId]);

  function goToNote(b: any) {
    let url = "/learning-library";
    if (b.product_id) url = url + "/course/" + b.product_id;
    if (b.lesson_plan_id) url = url + "/lesson-plan/" + b.lesson_plan_id;
    if (b.lesson_id) url = url + "/lesson/" + b.lesson_id;
    navigate(url);
  }

  const row = (r: any) => {
    let showing = showAll === r.id;
    const chLimit = window.innerWidth < 1000 ? 150 : 400;
    const truncate = r?.notes?.length > chLimit;

    const body = () => {
      if (!truncate) return r?.notes;
      return (
        <>
          <span className="mb-3">
            {showing ? r?.notes : r?.notes.substring(0, chLimit)}
            {!showing && "..."}
          </span>
          <span
            onClick={() => setShowAll(showing ? "" : r.id)}
            className="
              absolute bottom-0 right-0 bg-white pl-1 pt-1 -mb-2 -mr-1 cursor-pointer 
              text-sm font-semibold text-brand-primary hover:text-brand-tertiary"
          >
            Show {showing ? "Less" : "More"}
          </span>
        </>
      );
    };

    return (
      <Card useCursor={false}>
        <>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col font-semibold whitespace-normal">
              {r.name}
              <br />
              {r.timestamp > 1 && formatHHMMSS(r.timestamp)}
            </div>
            <div
              className="flex flex-row pl-2 gap-x-2 text-2xl cursor-pointer"
              onClick={() => goToNote(r)}
            >
              <MdOndemandVideo className="text-brand-primary hover:text-brand-tertiary" />
            </div>
          </div>
          <div className="flex flex-row relative whitespace-normal">
            {body()}
          </div>
        </>
      </Card>
    );
  };

  // add count to option in select drop down and disable if an option has no notes
  const option = (c: any, idType: string) => {
    const count = notes?.filter((n: any) => n?.[idType] === c.id)?.length;
    return (
      <option key={c.id} value={c.id} disabled={count < 1}>
        {c.name} ({count})
      </option>
    );
  };

  return (
    <>
      <h3 className="learning-step">My Notebook</h3>
      <ReplaceWhileLoading loading={loading}>
        <>
          <div className="flex flex-col my-4 gap-y-2 lg:w-fit">
            <div className="flex flex-row justify-between">
              <select
                id="course"
                name="course"
                onChange={(e) => {
                  setCourseId(e.target.value);
                  setLessonPlanId("");
                  setLessonId("");
                }}
                value={courseId}
                className="w-5/6"
              >
                <option key="all courses" value="">
                  All
                </option>
                {products.map((c) => option(c, "product_id"))}
              </select>
            </div>
            <div
              className={`${
                !lessonPlans.length && "hidden"
              } flex flex-row justify-between`}
            >
              <label htmlFor="lessonPlan" className="w-36">
                Lesson Plan
              </label>
              <select
                id="lessonPlan"
                name="lessonPlan"
                onChange={(e) => {
                  setLessonPlanId(e.target.value);
                  setLessonId("");
                }}
                value={lessonPlanId}
                className="w-5/6"
              >
                <option key="all lesson plans" value="">
                  All Lesson Plans
                </option>
                {lessonPlans.map((c) => option(c, "lesson_plan_id"))}
              </select>
            </div>
            <div
              className={`${
                !lessons.length && "hidden"
              } flex flex-row justify-between`}
            >
              <label htmlFor="lesson" className="w-36">
                Lesson
              </label>
              <select
                id="lesson"
                name="lesson"
                onChange={(e) => setLessonId(e.target.value)}
                value={lessonId}
                className="w-5/6"
              >
                <option key="all lessons" value="">
                  All Lessons
                </option>
                {lessons.map((c) => option(c, "lesson_id"))}
              </select>
            </div>
            <span>
              {displayedNotes.length} Note
              {displayedNotes.length === 1 ? "" : "s"}
            </span>
          </div>
          {/* if any filters, display that as the breadcrumb */}
          {/* if no filters, display most recent created first */}
          {notes.length > 0 ? (
            <div className="flex flex-col gap-y-2">
              {displayedNotes.map((b: any, i: number) => (
                <span key={`note_${i}`}>{row(b)}</span>
              ))}
            </div>
          ) : (
            <div className="bg-white ring-1 ring-black ring-opacity-5 p-4">
              <h4>Welcome to your notebook!</h4>
              You can now take notes during your online lessons! You can come
              back here to view all of your notes.
            </div>
          )}
        </>
      </ReplaceWhileLoading>
    </>
  );
}
