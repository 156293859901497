import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import videojs from "video.js";
import { api } from "../api";

/*
 * VIDEOJS NOTES: https://linear.app/cceng/issue/APP-116/enhance-the-videojs-player
 *   The comment in this card holds the extensive details on the components props,
 *   and it's parent/children detail the development of this complex component.
 */

// REGISTERING PLUGINS
// eslint-disable-next-line no-undef
require("@silvermine/videojs-quality-selector")(videojs);
// eslint-disable-next-line no-undef
require("@silvermine/videojs-quality-selector/dist/css/quality-selector.css");
// eslint-disable-next-line no-undef
require("videojs-vtt-thumbnails");
import watermark from "videojs-watermark";

import { addPlayerWatermark } from "./videoPlayer/addPlayerWatermark";
import {
  addNoteButton,
  addSkipBackwardButton,
  addSkipForwardButton,
  addTheatherButton,
} from "./videoPlayer/customButtons";
import {
  resumeVideoWhereLeftOff,
  setNewMetadataIfNecessary,
  updateLocalMetadataEveryFiveSeconds,
} from "./videoPlayer/userProgress";
import { defaultVideoPreferences } from "../pages/userSettings/settingsComponents/VideoSettings";
import { playbackRates } from "./generateVideoJsOptions";
import Snackbar from "./snackbar/snackbar";
import { NoteContext } from "../App";
import Button from "./buttons/Button";
import { MdSettings } from "react-icons/md";
import VideoInterstitial from "./VideoInterstitial";
import LocalUserSettings from "../helpers/LocalUserSettings";

export default function VideoPlayer({
  containerClassName,
  options,
  resourceId,
  lessonName,
  noteable,
  trackProgress,
  metadata,
  setMetadata,
  passPlayerRef,
}) {
  const { courseId, lessonPlanId, lessonId } = useParams();

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [videoPreferences, setVideoPreferences] = useState(
    defaultVideoPreferences
  );
  const [init, setInit] = useState(false);
  const [theaterMode, setTheaterMode] = useState(false);

  const { setNotes } = useContext(NoteContext);
  const [showNoteResp, setShowNoteResp] = useState(null);
  const [currentMetadata, setCurrentMetadata] = useState(metadata);
  const [showInterstitial, setShowInterstitial] = useState(false);
  const [autoPauses, setAutoPauses] = useState([]);

  const updateNotes = async () => {
    const list = await api.getNotesForResource(resourceId);
    setNotes(list);
  };
  useEffect(() => {
    (async () => {
      if (trackProgress) {
        // only need to get notes/preferences if it is a trackable view
        await updateNotes();
        const profile = await api.getUserProfile();
        setVideoPreferences(
          profile?.video_preferences || defaultVideoPreferences
        );
      }
      setInit(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await api.getResourcesConsumedByResource(resourceId);

      response.map((resourceConsumer) => {
        if (resourceConsumer.resources.metadata?.type === "auto_pause") {
          setAutoPauses(
            resourceConsumer.resources.metadata?.data?.pauses || []
          );
        }
      });
    })();
  }, []);

  // record new timestamp on click
  // temp / default - save the last 10 seconds (in the future this will be customizable)
  async function note(e) {
    const user = await api.getUser();
    const timestamp = playerRef.current.currentTime();

    if (!user?.id) return;
    playerRef.current.pause();

    const resp = await api.createNote(
      resourceId,
      timestamp,
      courseId,
      lessonPlanId,
      lessonId
    );
    setShowNoteResp(resp);

    await updateNotes();
  }

  // if only one source is included in options prop, qualitySelector automatically hides, and likewise,
  //   if no chapters, playback rates, or captions are included in options, their corresponding icon is auto hidden
  const controlBarIconOrder = [
    "playToggle",
    "volumePanel",
    "progressControl",
    "currentTimeDisplay",
    "timeDivider",
    "durationDisplay",
    "chaptersButton",
    "playbackRateMenuButton",
    "subsCapsButton",
    "qualitySelector",
    "fullscreenToggle",
  ];

  const checkIfInterstitialShouldBeShown = useCallback(
    (lastSeenSecond = 0) => {
      if (!playerRef.current) {
        return;
      }

      if (LocalUserSettings.get("videoInterstitial") === "false") {
        return;
      }

      const timeout = setTimeout(() => {
        if (!playerRef.current) {
          return;
        }
        if (LocalUserSettings.get("autoPause") === false) {
          return;
        }

        const time = playerRef.current.currentTime();

        autoPauses.forEach((t) => {
          // this prevents the interstitial from showing multiple times on the same second (i.e., after a user unpauses)
          if (
            Math.floor(time) === t.starts_at &&
            lastSeenSecond === t.starts_at - 1 &&
            !playerRef.current.isFullscreen()
          ) {
            setShowInterstitial(true);
            playerRef.current.pause();
          }
        });

        checkIfInterstitialShouldBeShown(
          Math.floor(playerRef.current.currentTime())
        );
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    },
    [autoPauses]
  );

  // initialize video after loading user preferences
  useEffect(() => {
    let updateMetadataInterval;
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      if (passPlayerRef) {
        // for thumbnail videos
        // (bc they do not use user preferences)
        const player = (playerRef.current = videojs(videoElement, {
          ...options,
          controlBar: { children: controlBarIconOrder },
        }));
        passPlayerRef(player);
        player.currentTime(60);
      } else if (init) {
        // for all other videos
        const { quality, speed, captions } = videoPreferences;

        const root = document.documentElement;
        root.style.setProperty(
          "--color-videojs-captions-background",
          captions.background
        );
        root.style.setProperty("--color-videojs-captions-color", captions.text);
        root.style.setProperty("--color-videojs-captions-size", captions.size);
        root.style.setProperty(
          "--color-videojs-captions-family",
          captions.font
        );

        let sources = options.sources;
        if (quality) {
          const selected = options.sources.find((s) => s.label === quality);
          if (selected) {
            sources = [
              ...options.sources.filter((s) => s.label != quality),
              { ...selected, selected: true },
            ];
          }
        }

        // temp - todo: this assumes there is one captions track and that it's english
        let tracks = options.tracks;
        if (captions?.on) {
          const selected = options.tracks.find((t) => t.kind === "captions");
          if (selected) {
            tracks = [
              ...options.tracks.filter((t) => t.src != selected.src),
              { ...selected, default: true },
            ];
          }
        }

        // required for captions to work in safari
        const html5 = {
          nativeTextTracks: false,
          vhs: { overrideNative: true },
        };

        const player = (playerRef.current = videojs(videoElement, {
          ...options,
          sources,
          tracks,
          controlBar: { children: controlBarIconOrder },
          html5,
        }));

        if (playbackRates.some((r) => r === speed)) {
          player.playbackRate(speed);
        }

        if (options?.watermark) {
          if (!player?.watermark)
            videojs.registerPlugin("watermark", watermark);
          addPlayerWatermark(player);
        }

        addSkipForwardButton(player);
        addSkipBackwardButton(player);
        if (options?.theaterModeButton) {
          addTheatherButton(player, setTheaterMode);
        }
        if (noteable) {
          addNoteButton(player, note);
        }

        if (trackProgress) {
          resumeVideoWhereLeftOff(player, metadata);
          updateMetadataInterval = updateLocalMetadataEveryFiveSeconds(
            player,
            setCurrentMetadata,
            currentMetadata
          );
        }
      }

      checkIfInterstitialShouldBeShown();

      return () => {
        updateMetadataInterval && clearInterval(updateMetadataInterval);
      };
    }
  }, [init, options, videoRef, videoPreferences]);

  useEffect(() => {
    (async () => {
      if (!trackProgress) return;
      if (!playerRef?.current || playerRef?.current?.paused()) return;
      const speed = playerRef.current?.playbackRate();
      const quality = playerRef.current
        ?.options()
        ?.sources?.find((s) => s.selected)?.label;

      const on = Boolean(document.querySelector(".vjs-text-track-cue"));

      // update users progress
      setNewMetadataIfNecessary(
        playerRef.current,
        currentMetadata,
        metadata,
        setMetadata
      );

      try {
        // update user's preferences
        await api.updateProfileVideoPreferences({
          quality,
          speed,
          captions: { ...videoPreferences?.captions, on },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [currentMetadata, JSON.stringify(metadata)]);

  // dispose on unmount
  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  if (!options?.sources) {
    return (
      <div className={`${containerClassName} w-full lg:w-1/2`}>
        <div
          className="
            border border-solid border-gray-300 rounded
            bg-gray-200 p-2 flex justify-center items-center
            uppercase text-xs font-semibold text-gray-600"
        >
          No video source
        </div>
      </div>
    );
  }

  return (
    <>
      <Snackbar
        message={showNoteResp}
        open={showNoteResp}
        onClose={() => setShowNoteResp(null)}
      />
      <div className={`relative flex w-full ${containerClassName}`}>
        <VideoInterstitial
          showInterstitial={showInterstitial}
          setShowInterstitial={setShowInterstitial}
          player={playerRef.current}
        />

        <video
          id={`video_${resourceId}`}
          className="video-js vjs-big-play-centered"
          ref={videoRef}
          muted={options?.loadMuted}
          data-testid="video-play-btn"
        />
      </div>
    </>
  );
}

VideoPlayer.propTypes = {
  containerClassName: PropTypes.string,
  options: PropTypes.any,
  resourceId: PropTypes.string,
  lessonName: PropTypes.string,
  noteable: PropTypes.bool,
  trackProgress: PropTypes.bool,
  metadata: PropTypes.object,
  setMetadata: PropTypes.func,
  passPlayerRef: PropTypes.any,
};
