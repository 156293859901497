import React, { useEffect } from "react";
import { useLocation, useNavigation } from "react-router-dom";
import replaceShortCodes, { productLinkFetcher } from "./replaceShortCodes";

const ShortCodeReplacer = () => {
  useEffect(() => {
    const replaceLinks = setInterval(() => {
      productLinkFetcher.replaceProductShortCodes();
    }, 500);

    return () => {
      clearInterval(replaceLinks);
    };
  }, [location]);

  return null;
};

export default ShortCodeReplacer;
