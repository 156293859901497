import React, { useEffect, useMemo, useState } from "react";
import { default as Player } from "@vimeo/player";
import { VideoProps } from "./vimeo_player_types";

let vimeoNumber = 0;
export function VimeoPlayer({
  className,
  src,
  title,
  metadata = {},
  setMetadata = () => null,
  passPlayerRef,
  background,
  trackProgress = false,
}: VideoProps) {
  const [currentMetadata, setCurrentMetadata] = useState(metadata);
  const [player, setPlayer] = useState<Player | undefined>(undefined);
  const [id, setId] = useState<string | undefined>(undefined);

  // setup for the player
  let params = new URLSearchParams(src?.split("?")[1]);
  if (background) {
    params.set("background", "1");
  }
  params.set("autoplay", "0");
  const url = src + "?" + params.toString();

  useMemo(() => {
    // keep each player in its own namespace
    vimeoNumber++;
    let id = `vimeo-${vimeoNumber}`;
    setId(id);
  }, []);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }

      var iframe = document.getElementById(id);
      if (!iframe) {
        return;
      }

      var player = new Player(iframe);
      setPlayer(player);

      if (passPlayerRef) {
        passPlayerRef(player);
        player.setVolume(0);
        player.pause();
      }

      // only track video progress if in lesson page
      // (not when video is loaded as thumbnail)
      if (trackProgress) {
        if (metadata.playbackOffsetInSeconds) {
          const duration = await player.getDuration();

          if (duration < metadata.playbackOffsetInSeconds + 5) {
            // nop, within 5 seconds of end, start from beginning
          } else {
            player.setCurrentTime(metadata.playbackOffsetInSeconds);
          }
        }

        setInterval(async () => {
          const time = await player.getCurrentTime();
          const duration = await player.getDuration();

          if (duration < time + 30) {
            // within 30 seconds of end, mark as finished
            //   but never mark as unfinished (because if a user re-watches part of it later,
            //   remember that they had once watched it all the way through)
            setCurrentMetadata({
              ...currentMetadata,
              playbackOffsetInSeconds: time,
              finished: true,
            });
          } else {
            setCurrentMetadata({
              ...currentMetadata,
              playbackOffsetInSeconds: time,
            });
          }
        }, 5000);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!player) {
        return;
      }
      if (JSON.stringify(currentMetadata) === JSON.stringify(metadata)) {
        return;
      } // bail early
      setMetadata(currentMetadata);
    })();
  }, [currentMetadata, metadata]);

  return (
    <iframe
      allowFullScreen={true}
      id={id}
      className={`border-0 ${className} ${
        background ? "pointer-events-none" : ""
      }`}
      title={title}
      src={url}
    />
  );
}
