import { api } from "../../../api";
import {
  LessonPlan,
  ResourceConsumerWithResource,
} from "../../learningLibrary/learningLibraryTypes";
import { Modal } from "../../../components/modal/modal";
import PreviewModalRow from "./PreviewModalRow";
import PreviewHeader from "./PreviewHeader";
import PreviewCTA from "./PreviewCTA";
import React, { useEffect, useMemo, useState } from "react";
import ReplaceWhileLoading from "../../../components/loadingIndicator/ReplaceWhileLoading";
import PreviewResourceTab from "./PreviewResourceTab";
import { useLocation, useParams } from "react-router-dom";
import useTabs from "../../learningLibrary/modal/useTabs";
import { definitions } from "../../../../types/supabase";
import CreditCheckoutModal from "./CreditCheckoutModal";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const PreviewModal = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState<any>();
  const [productResources, setProductResources] = useState<
    Array<ResourceConsumerWithResource>
  >([]);
  const [lessonPlans, setLessonPlans] = useState<Array<LessonPlan>>([]);
  let query = useQuery();
  const offerId = query.get("offerId") || "";
  const [open, setOpen] = useState<boolean>(false);
  const [selectedOffer, setSelectedOffer] = useState<definitions["offers"]>();

  useEffect(() => {
    if (!courseId) {
      return;
    }

    (async () => {
      let lessonPlans, productResources, product;
      try {
        [lessonPlans, productResources, product] = await Promise.all([
          api.getLessonPlansForProduct(courseId),
          api.getProductResourcesForProduct(courseId),
          api.getProduct(courseId),
        ]);
      } catch (e) {
        console.log("ERROR: ", e);
      }
      setProductResources(productResources as any);
      setLessonPlans(lessonPlans as any);
      setCourse(product);
    })();
  }, [courseId]);

  const renderResources = () => {
    return <PreviewResourceTab resources={productResources} />;
  };

  const renderLessonPlan = () => (
    <section className="h-full overflow-y-scroll overflow-x-hidden md:h-fit md:overflow-hidden">
      {lessonPlans.map((lessonPlan: any) => (
        <PreviewModalRow
          key={`lesson plan row ${lessonPlan.id}`}
          lessonPlan={lessonPlan}
          purchased={false}
          thumbnail={lessonPlan?.thumbnail || course?.thumbnail}
        />
      ))}
    </section>
  );

  const [tabList, content] = useTabs([
    { tabName: "Lesson Plans", content: renderLessonPlan() },
    { tabName: "Resources", content: renderResources() },
  ]);

  if (!courseId) return <div />;

  if (open) {
    return (
      <CreditCheckoutModal
        open={open}
        setOpen={setOpen}
        courseId={courseId}
        offer={selectedOffer!}
      />
    );
  }

  return (
    <Modal
      setOpen={() => true}
      contentClassName="md:p-8 md:overflow-y-scroll"
      navOnClose="/discover"
    >
      <ReplaceWhileLoading loading={!course}>
        <>
          <PreviewHeader
            description={course?.description || ""}
            title={course?.name || ""}
            thumbnailResource={course?.thumbnailVideo || course?.thumbnail}
          />
          <span className="hidden md:flex">
            <PreviewCTA
              offerId={offerId}
              setSelectedOffer={setSelectedOffer}
              setOpen={setOpen}
            />
          </span>
          {tabList}
          <div
            className="gray-scrollbar"
            style={{ height: "calc(100vh - 16.4rem)" }}
          >
            {content}
          </div>
          <span className="flex md:hidden w-full">
            <PreviewCTA
              offerId={offerId}
              setSelectedOffer={setSelectedOffer}
              setOpen={setOpen}
            />
          </span>
        </>
      </ReplaceWhileLoading>
    </Modal>
  );
};

export default PreviewModal;
