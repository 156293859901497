import React from "react";

const ModalTab = ({ title, isCurrentTab, setCurrentTab }: any) => {
  const style =
    "font-bold text-center p-2 h-10 w-1/2 md:h-fit md:w-fit md:mx-0 md:mt-8 md:mb-4 md:p-2 md:rounded";
  const activeStyle = `${style} text-white bg-brand-primary md:text-black md:bg-white md:shadow-md`;
  const inactiveStyle = `${style} bg-white bg-opacity-0 ${
    setCurrentTab && "hover:bg-opacity-50 hover:shadow-lg cursor-pointer"
  }`;

  return (
    <div
      className={isCurrentTab && setCurrentTab ? activeStyle : inactiveStyle}
      onClick={() => setCurrentTab(title)}
    >
      {title}
    </div>
  );
};

export default ModalTab;
