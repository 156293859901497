import { VideoJsPlayer } from "video.js";

export const resumeVideoWhereLeftOff = (
  player: VideoJsPlayer,
  metadata: any
) => {
  if ((metadata || {}).playbackOffsetInSeconds) {
    const duration = player.duration();

    if (duration < metadata.playbackOffsetInSeconds + 5) {
      // nop, within 5 seconds of end, start from beginning
    } else {
      player.currentTime(metadata.playbackOffsetInSeconds);
    }
  }
};

export const updateLocalMetadataEveryFiveSeconds = (
  player: VideoJsPlayer,
  setCurrentMetadata: (metadata: any) => void,
  currentMetadata: any
) => {
  const updateInterval = setInterval(async () => {
    if (player.isDisposed()) {
      clearInterval(updateInterval);
      return;
    }

    const time = player.currentTime();
    const duration = player.duration();

    if (duration < time + 15) {
      // within 30 seconds of end, mark as finished
      //   but never mark as unfinished (because if a user re-watches part of it later,
      //   remember that they had once watched it all the way through)
      setCurrentMetadata({
        ...currentMetadata,
        playbackOffsetInSeconds: time,
        finished: true,
      });
    } else {
      setCurrentMetadata({
        ...currentMetadata,
        playbackOffsetInSeconds: time,
      });
    }
  }, 5000);

  updateInterval;
};

export const setNewMetadataIfNecessary = (
  player: VideoJsPlayer,
  currentMetadata: any,
  metadata: any,
  setMetadata: (metadata: any) => void
) => {
  if (!player) {
    return;
  }
  if (JSON.stringify(currentMetadata) === JSON.stringify(metadata)) {
    return;
  } // bail early
  setMetadata(currentMetadata);
};
