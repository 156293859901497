import { api, supabase } from "../../../api";
import Button from "../../../components/buttons/Button";
import PasswordField from "../../../components/PasswordField";
import { ProfileContext } from "../../../App";
import { UserContext } from "../../../contexts/UserContext";
import React, { useContext, useEffect, useState } from "react";
import Snackbar from "../../../components/snackbar/snackbar";
import { UserInfo } from "../userSettingsTypes";

const PersonalInfo = ({
  successMessage,
  setSuccessMessage,
  setUserInfo,
  userInfo,
  afterSave,
  fieldsOnly,
}: {
  successMessage: string;
  setSuccessMessage: any;
  setUserInfo: any;
  userInfo: UserInfo;
  afterSave?: () => void;
  fieldsOnly?: boolean;
}) => {
  const [editing, setEditing] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordTip, setShowPasswordTip] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { setUser } = useContext(UserContext);
  const { setProfile } = useContext(ProfileContext);

  let emailMessage = "";
  let passwordMessage = "";
  let message = "";
  const resultStyle = "m-0 pt-2 text-xs text-right font-semibold";

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.auth.getUser();
      const profile = await api.getUserProfile();
      setUserInfo({ ...data.user, name: profile?.name });
      setEmail(data.user?.email || "");
      setName(profile?.name);
    })();
  }, []);

  useEffect(() => {
    const editedName = name !== userInfo.name;
    const editedEmail = email !== userInfo.email;
    setEditing(editedName || editedEmail || password.length > 0);
  }, [name, email, password, userInfo]);

  const clearMessages = () => {
    setSuccessMessage("");
    setErrorMessage("");
    setShowPasswordTip(false);
  };

  const updateName = async () => {
    if (!name) {
      setErrorMessage("Name cannot be null");
      return;
    }
    const response = await api.updateProfileName(name);
    if (response.error) {
      setErrorMessage(errorMessage + "Error updating the name");
    } else {
      setUserInfo({ ...userInfo, name });
      message = "Name";
    }
  };

  const updateEmail = async () => {
    if (!email) {
      setErrorMessage("Email cannot be null");
      return;
    }
    const response = await supabase.auth.updateUser({ email });
    if (response.error) {
      setErrorMessage(
        errorMessage ? `${errorMessage}, email` : "Error updating the email"
      );
    } else {
      const emailResponse: any = await api.sendUpdateProfileEmail(email);
      if (emailResponse.ok) {
        setUserInfo({ ...userInfo, email });
        emailMessage =
          "Submission Successful! Check your email inbox to confirm your email change.";
      } else {
        setErrorMessage(
          errorMessage ? `${errorMessage}, email` : "Error updating the email"
        );
      }
    }
  };

  const updatePassword = async () => {
    if (!password) return;
    if (password !== confirmPassword) {
      setErrorMessage("Passwords must match");
      return;
    }
    const response = await supabase.auth.updateUser({ password });
    if (response.error) {
      setErrorMessage(
        errorMessage
          ? `${errorMessage}, password`
          : "Error updating the password"
      );
    } else {
      message = message ? `${message}, password` : "Password";
    }
    setPassword("");
    setConfirmPassword("");
  };

  const saveChanges = async () => {
    try {
      setLoading(true);
      if (name !== userInfo.name) await updateName();
      if (email !== userInfo.email) await updateEmail();
      if (password.length) await updatePassword();
      setLoading(false);
      if (message) setSuccessMessage(`${message} updated successfully`);
      if (emailMessage) setSuccessMessage(`${emailMessage}`);
      if (passwordMessage) setSuccessMessage(`${passwordMessage}`);
      const { data, error } = await supabase.auth.getUser();
      if (!data.user) {
        throw new Error("unauthorized");
      }
      const profile = await api.getUserProfile();
      setUser(data.user);
      setProfile(profile);
      if (afterSave) afterSave();
    } catch (e) {
      console.error(e);
    }
  };

  const validForm = () => {
    return password ? password.length >= 8 : true;
  };

  return (
    <>
      <h3 className={fieldsOnly ? "hidden" : ""}>User Information</h3>
      <Snackbar
        message={successMessage}
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage("")}
      />
      <Snackbar
        message={errorMessage}
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
      />
      <section className="flex flex-row lg:gap-x-6 w-full pb-4">
        <div
          className={`invisible lg:visible h-28 w-0 lg:w-28 flex my-auto rounded-full bg-brand-primary ${
            fieldsOnly ? "hidden" : ""
          }`}
        >
          <h1 className="md:m-auto text-6xl text-white font-bold">
            {(userInfo?.name || userInfo?.email)?.charAt(0).toUpperCase() ||
              "-"}
          </h1>
        </div>
        <div
          className={`flex flex-col justify-center ${
            fieldsOnly ? "w-full" : "w-full md:w-3/4 lg:w-1/2 "
          }`}
        >
          <label className="text-xs" htmlFor="name">
            Name
          </label>
          <input
            id="name"
            placeholder="Name"
            className="p-2 mt-2"
            onChange={(e) => setName(e.target.value)}
            value={name}
            onKeyDown={clearMessages}
          />
          <label className="text-xs pt-4" htmlFor="name">
            Email
          </label>
          <input
            id="email"
            type="email"
            className="p-2 mt-2"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value.trim())}
            value={email}
            onKeyDown={clearMessages}
          />
          <label className="text-xs pt-4" htmlFor="name">
            Password
          </label>
          <PasswordField
            focus={setShowPasswordTip}
            handleInfoChange={(e: any) => setPassword(e.target.value.trim())}
            keyDown={clearMessages}
            placeholder="Update password"
            value={password}
          />
          <label className="text-xs pt-4" htmlFor="name">
            Confirm Password
          </label>
          <PasswordField
            focus={setShowPasswordTip}
            handleInfoChange={(e: any) =>
              setConfirmPassword(e.target.value.trim())
            }
            keyDown={clearMessages}
            placeholder="Confirm password"
            value={confirmPassword}
          />
          <p className={`text-xs pt-1 ${showPasswordTip ? "" : "invisible"}`}>
            At least 6 alphanumeric characters
          </p>
          <section className="mt-4 flex justify-center md:justify-start">
            {!editing && !errorMessage && !successMessage && (
              <p className="text-gray-500 italic text-sm">No Changes to Save</p>
            )}
            {!editing ? (
              ""
            ) : (
              <Button
                type="submit"
                style="primary"
                onClick={saveChanges}
                startDisabled={!validForm()}
                additionalStyling={fieldsOnly ? "w-full py-2" : ""}
              >
                <span>{loading ? "Saving Changes" : "Save Changes"}</span>
              </Button>
            )}
          </section>
        </div>
      </section>
    </>
  );
};

export default PersonalInfo;
