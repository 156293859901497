import { AiOutlineArrowLeft } from "react-icons/ai";
import React from "react";

const NavBackButton = ({
  backText,
  navigateBackFn,
}: {
  backText?: string;
  navigateBackFn?: () => void;
}) => {
  if (!navigateBackFn) return null;
  return (
    <nav
      className="-mt-4 -ml-4 text-sm common-text-button"
      onClick={navigateBackFn}
    >
      <AiOutlineArrowLeft className="-mb-0.5 mr-1" />
      {backText}
    </nav>
  );
};

export default NavBackButton;
