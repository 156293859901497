import { brandConfig } from "../helpers/brandConfig";
import React, { useEffect, useState } from "react";
import StyledLink from "./StyledLink";
import getPageIcon from "../helpers/getPageIcon";

const ToggleContentLink = ({
  label,
  route,
}: {
  label: string;
  route: string;
}) => {
  const [icon, setIcon] = useState<any>(null);
  const active = location.pathname === route;
  const sideNavIcon = "text-2xl mr-4";

  useEffect(() => {
    setIcon(getPageIcon(label, sideNavIcon));
  }, []);

  return (
    <StyledLink
      route={route}
      styling={`
        ${active ? "text-white bg-brand-primary" : "text-brand-primary"} 
        flex flex-nowrap p-4 font-bold hover:text-brand-tertiary no-underline
        mobile-bottom-border-light`}
    >
      {icon}
      {label}
    </StyledLink>
  );
};

export default ToggleContentLink;
