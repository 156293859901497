import { api } from "../../api";
import Button from "../../components/buttons/Button";
import { brandConfig } from "../../helpers/brandConfig";
import ContactSupport from "../../components/ContactSupport";
import Dashboard from "../dashboard";
import { definitions } from "../../../types";
import { JoyRideConfig } from "../../components/joyrideSteps";
import { LessonWithCourseId, Product } from "./learningLibraryTypes";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { ProductCard } from "../../components/productCard/productCard";
import PurchasedOfferCard from "./PurchasedOfferCard";
import React, { useState, useEffect } from "react";
import ReplaceWhileLoading from "../../components/loadingIndicator/ReplaceWhileLoading";
import { Step } from "react-joyride";
import { useNestedNavigation } from "../../contexts/NestedNavigation";

// learningLibrary = user's next up, history, etc
// holds links to courses
export default function LearningLibrary({ children }: { children?: any }) {
  let { courseId, lessonPlanId, lessonId } = useParams();
  const currentPage = window.location.pathname;
  const navigate = useNavigate();
  const [cart, setCart] = useState<Array<string>>([]);
  const [lastPage, setLastPage] = useState("");
  const [latestLesson, setLatestLesson] = React.useState<
    LessonWithCourseId | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = React.useState<Array<Product>>([]);
  const [purchasedIds, setPurchasedIds] = useState([]);
  const [purchasedOffers, setPurchasedOffers] = useState<
    Array<definitions["offers"]>
  >([]);
  const { nestedNavigation } = useNestedNavigation();

  useEffect(() => {
    (async () => {
      const products = await api.getProducts();
      // setProducts(products); // temporary comment - see below

      nestedNavigation.setOffer(null);

      const latest = await api.findLatestInProgressLesson();
      setLatestLesson(latest);

      const purchases = await api.getPurchasedProducts();
      const purchasedOffers = await api.getPurchasedOffers();
      setPurchasedOffers(purchasedOffers);

      if (purchases) setPurchasedIds(purchases.map((p: any) => p.product_id));

      const currentUser = await api.getUser();

      if (currentUser?.xmsRole == "admin") {
        setProducts(products);
      } else {
        let purchasedProducts = products.filter((p) =>
          purchases.map((p: any) => p.product_id).some((i: any) => i === p.id)
        );
        setProducts(
          purchasedProducts.filter((p) => p.product_sub_type === "course")
        );
      }

      setOpen(Boolean(courseId));
      setLoading(false);

      if (!JoyRideConfig.getLearningLibraryCompleted()) {
        // const profile = await api.getUserProfile();
        // setJoyRideSteps(getLearningLibraryStepsForUserProfile(profile))
        // temp - turned off until overhaul
        // setRunWelcomeTour(true);
      }
    })();
  }, []);

  useEffect(() => {
    // check for user using browser backwards button or clicking off modal
    // ex: /piaf/learning-library/course/1 -> /piaf/learning-library
    if (
      lastPage.split("/").length > currentPage.split("/").length &&
      currentPage.split("/").length === 2
    ) {
      setOpen(false);
    }
    setLastPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (loading || open) return;
    setOpen(Boolean(courseId));
  }, [courseId, lessonPlanId, lessonId]);

  if (!brandConfig.brandAbbreviation) return <Dashboard />;

  const goToLatestLesson = () => {
    if (!latestLesson) return;

    const courseId = latestLesson?.lessonPlan?.product?.id;
    const lessonPlanId = latestLesson?.lessonPlan?.id;
    const lessonId = latestLesson.id;
    navigate(
      `course/${courseId}/lesson-plan/${lessonPlanId}/lesson/${lessonId}`
    );
  };

  const latestLessonTitle = () => {
    const product = latestLesson?.lessonPlan?.product?.name;
    const lessonPlan = latestLesson?.lessonPlan?.name;
    const lesson = latestLesson?.name;

    return (
      <div className="text-xs leading-tight">
        <span>
          {product} &gt; {lessonPlan} &gt;
        </span>
        <h3 className="pt-1 mb-0">{lesson}</h3>
      </div>
    );
  };

  return (
    <>
      <PageHeader>
        <h3 className="learning-step">Learning Library</h3>
      </PageHeader>

      {/* display courseModal and lessonModal when course thumbnail is clicked */}
      {children}

      <ReplaceWhileLoading loading={loading}>
        <div
          className={
            courseId && "overflow-hidden h-0 md:overflow-y-scroll md:h-full"
          }
        >
          {latestLesson && (
            <header>
              <h4 className="brand-subheader left-off-step">
                Where you left off...
              </h4>
              <ProductCard
                title={latestLessonTitle()}
                description={latestLesson?.description}
                img={
                  latestLesson.thumbnails &&
                  latestLesson.thumbnails.find((x) => x)?.url
                }
                onClick={goToLatestLesson}
                purchased
              />
            </header>
          )}
          {!products.length && (
            <section className="text-center bg-white w-full h-4/5 p-8">
              {brandConfig.brandAbbreviation === "nma" ? (
                <>
                  <h3 className="brand-subheader">
                    You came to the right place!
                  </h3>
                  <p>
                    This is where you&apos;ll spend time on your learning
                    journey.
                    <br />
                    Before you get started, though, you&apos;ll need to{" "}
                    <Link to="/discover">
                      pick out a course from our catalog.
                    </Link>
                  </p>
                  <Link to="/discover">
                    <Button
                      additionalStyling="self-center py-3 mt-4"
                      style="red"
                    >
                      Discover your next learning adventure
                    </Button>
                  </Link>
                </>
              ) : (
                <>
                  <h3 className="brand-subheader">
                    It looks like you do not have access to any products.
                  </h3>
                  <p className="text-brand-primary font-semibold">
                    <ContactSupport
                      additionalStyling="hover:bg-brand-secondary"
                      style="text"
                    />
                    for assistance.
                  </p>
                </>
              )}
              <img
                alt="Image by pch.vector on Freepik"
                className="mx-auto flex justify-center"
                src="https://img.freepik.com/free-vector/tiny-artists-creating-artwork-together-flat-illustration-cartoon-illustration_74855-14537.jpg?w=1000&t=st=1668448028~exp=1668448628~hmac=11f2b5b1686f7daf7eb62703617656352b81f6e47a1d39b9ac377e916a31591d"
              />
            </section>
          )}

          {
            // TODO: This is a temporary fix to hide bundles from the learning library until we decide on
            // how we want to display them, grab images, etc.
            false && (
              <>
                <div className="text-xl pt-8">Bundles</div>
                <article className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full pt-4">
                  {/* We may want to add a userViewableName to the offer itself and read that? */}
                  {purchasedOffers.map((offer, index) => (
                    <PurchasedOfferCard
                      offer={offer}
                      key={`offer-${offer.id}`}
                    />
                  ))}
                </article>
              </>
            )
          }

          <article className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full pt-4">
            {products.map((p, index) => (
              <ProductCard
                key={`courseSnippet_${p.id}`}
                containerClassName={`product-${index} product-0 relative overflow-hidden rounded-2xl h-56 text-white ${
                  p.product_sub_type === "credit" ? "hidden" : ""
                }`}
                product={p}
                onClick={() => navigate(`course/${p.id}`)}
                img={p.thumbnail?.url}
                inCart={cart.some((c) => c === p.id.toString())}
                purchased={purchasedIds.some((c) => c === p.id)}
              />
            ))}
          </article>
        </div>
      </ReplaceWhileLoading>
    </>
  );
}
