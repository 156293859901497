import React, { useState, createContext, useEffect } from "react";
import { User } from "../../types/";
import { supabase } from "../api";

type UserContextProps = {
  user: User | undefined;
  loading: boolean;
  setUser: (_: User | undefined) => void;
};

export const UserContext = createContext<UserContextProps>({
  user: undefined,
  loading: true,
  setUser: (_: User | undefined) => {},
});

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [user, setUser] = useState<User | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const {
        data: { user },
        error,
      } = await supabase.auth.getUser();
      if (user) {
        setUser(user);
      }
      setLoading(false);
    })();
  }, []);

  const value = {
    user,
    loading,
    setUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
