import React from "react";

const Card = ({
  children,
  onClick,
  useCursor = false,
  addedStyling,
}: {
  children: React.ReactElement;
  onClick?: () => void;
  useCursor: Boolean;
  addedStyling?: string;
}) => {
  return (
    <div
      className={`bg-white md:p-4 rounded shadow-md mb-4 border-b-8 ${
        useCursor ? "cursor-pointer hover:shadow-lg" : ""
      } ${addedStyling}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export { Card };
