// format total seconds into readable timestamp
export default function formatTimestamp(totalSeconds = 0) {
  const mins = Math.floor(totalSeconds / 60);
  const secs = (totalSeconds % 60).toFixed(0);
  if (mins < 1) return `${secs} seconds`;
  return `${mins}:${parseInt(secs) < 10 ? "0" : ""}${secs} minutes`;
}

export function formatHHMMSS(totalSeconds = 0) {
  const date = new Date(0);
  date.setSeconds(totalSeconds);
  return date.toISOString().substring(11, 19);
}
